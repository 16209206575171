import { db } from '../../firebase/firebase';
<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <div class="px-4 pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">
            Verwijderde spots
          </h1>
          <p class="mt-2 text-sm text-gray-700">
            Een overzicht van alle verwijderde spots
          </p>
        </div>
      </div>
      <div class="flex flex-col mt-8">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <TanstackTable
                :columns="[
                  { accessorKey: 'id', header: 'ID', show: false },
                  {
                    accessorKey: 'statusIndex',
                    header: 'Status',
                    cell: (cellProps) =>
                      h(
                        Status,

                        { statusId: cellProps.getValue() }
                      ),
                  },
                  {
                    accessorKey: 'qrCode',
                    header: 'QR',
                  },
                  {
                    accessorKey: 'projectName',
                    header: 'Project naam',
                    sortingFn: 'alphanumeric',
                  },
                  {
                    accessorKey: 'timeStampDeleted',
                    header: 'Datum',
                    cell: (info) =>
                      info.getValue()
                        ? moment(info.getValue().toDate()).format(
                            'DD-MM-YYYY HH:mm'
                          )
                        : undefined,
                    sortingFn: 'datetime',
                    sortUndefined: -1,
                  },
                  {
                    accessorKey: 'deletedByName',
                    header: 'Verwijderd door',
                  },
                  {
                    accessorKey: 'createdDate',
                    header: 'Datum',
                  },
                  {
                    accessorKey: 'actions',
                    header: 'Acties',
                    enableSorting: false,
                    cell: () => 'actions',
                  },
                ]"
                :initial-sort="[{ id: 'timeStampDeleted', desc: true }]"
                :data="softDeletedMarkers"
                :actions="actions"
                :default-page-size="20"
                @remove="deleteMarker"
                @restore="restoreMarker"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :open="confirmModalData.open"
      :title="confirmModalData.title"
      :body="confirmModalData.body"
      :loading="confirmModalData.loading"
      :cancelButtonText="confirmModalData.cancelButtonText"
      :confirmButtonText="confirmModalData.confirmButtonText"
      @cancel="onCancelDelete"
      @confirm="onConfirmDelete"
    />
  </div>
</template>

<script setup>
  import { ref, onUnmounted, h } from 'vue';
  import { db } from '@/firebase/firebase.js';
  import {
    doc,
    onSnapshot,
    collection,
    deleteDoc,
    orderBy,
    query,
    where,
    collectionGroup,
    getDocs,
    updateDoc,
    increment,
  } from 'firebase/firestore';
  import TanstackTable from '../../components/TanstackTable.vue';
  import ConfirmationModal from '../../components/ConfirmationModal.vue';
  import moment from 'moment';
  import Status from '../..//components/Status.vue';

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: 'Spot verwijderen',
    body: 'Weet je zeker dat je deze spot wilt verwijderen? Alle gegevens van deze spot zullen permanent van onze servers worden verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
    loading: false,
  });

  const actions = ref([
    { id: 'restore', name: 'Terugzetten' },
    { id: 'remove', name: 'Verwijderen' },
  ]);

  const promises = [getProjects()];
  const softDeletedMarkers = ref([]);
  const activeDeleteMarker = ref();
  const projects = ref([]);

  getReactiveSoftDeletedMarkers();

  async function getProjects() {
    const projectsCollection = collection(db, 'projects');
    var result = await getDocs(projectsCollection);

    projects.value = result.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return true;
  }

  function getprojectName(id) {
    var foundName = '';

    if (projects.value.length) {
      var foundProject = projects.value.find((el) => el.id === id);
      if (foundProject) {
        foundName = foundProject.projectName;
      }
    }

    return foundName;
  }

  async function getReactiveSoftDeletedMarkers() {
    await Promise.allSettled(promises);
    const q = query(
      collectionGroup(db, 'markers'),
      where('isSoftDeleted', '==', true),
      orderBy('isSoftDeleted')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      softDeletedMarkers.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        projectName: getprojectName(doc.data().projectID),
      }));
    });
    onUnmounted(unsubscribe);
  }

  async function restoreMarker(id) {
    var markerToRestore = softDeletedMarkers.value.find((el) => el.id === id);
    //Removes the soft delete flag
    const markerRef = doc(
      db,
      'projects',
      markerToRestore.projectID,
      'floors',
      markerToRestore.floorID,
      'markers',
      markerToRestore.id
    );

    await updateDoc(markerRef, {
      isSoftDeleted: false,
      deletedByName: null,
      deletedByUid: null,
      deletedDate: null,
      timeStampDeleted: null,
    });

    //Increments the amount of markers on the floor by 1
    const floorsRef = doc(
      db,
      'projects',
      markerToRestore.projectID,
      'floors',
      markerToRestore.floorID
    );

    const floor = {
      amountOfMarkers: increment(1),
    };

    //Increments the amount of markers on the project by 1
    await updateDoc(floorsRef, floor);

    const projectsRef = doc(db, 'projects', markerToRestore.projectID);

    const project = {
      amountOfMarkers: increment(1),
    };

    await updateDoc(projectsRef, project);
  }

  function deleteMarker(id) {
    confirmModalData.value.loading = false;
    activeDeleteMarker.value = softDeletedMarkers.value.find(
      (el) => el.id === id
    );
    confirmModalData.value.open = true;
  }

  function onCancelDelete() {
    confirmModalData.value.open = false;
  }

  async function onConfirmDelete() {
    confirmModalData.value.loading = true;
    //First clear out all nested data (components, materials, images, history & legacy_history)
    //clear components

    const componentsCollection = collection(
      db,
      'projects',
      activeDeleteMarker.value.projectID,
      'floors',
      activeDeleteMarker.value.floorID,
      'markers',
      activeDeleteMarker.value.id,
      'components'
    );

    const querySnapshotComponents = await getDocs(componentsCollection);
    querySnapshotComponents.forEach((docData) => {
      const componentRef = doc(
        db,
        'projects',
        activeDeleteMarker.value.projectID,
        'floors',
        activeDeleteMarker.value.floorID,
        'markers',
        activeDeleteMarker.value.id,
        'components',
        docData.id
      );

      deleteDoc(componentRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });

    //clear materials
    const materialsCollection = collection(
      db,
      'projects',
      activeDeleteMarker.value.projectID,
      'floors',
      activeDeleteMarker.value.floorID,
      'markers',
      activeDeleteMarker.value.id,
      'materials'
    );

    const querySnapshotMaterials = await getDocs(materialsCollection);
    querySnapshotMaterials.forEach((docData) => {
      const materialsRef = doc(
        db,
        'projects',
        activeDeleteMarker.value.projectID,
        'floors',
        activeDeleteMarker.value.floorID,
        'markers',
        activeDeleteMarker.value.id,
        'materials',
        docData.id
      );

      deleteDoc(materialsRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });

    //clear history
    const historyCollection = collection(
      db,
      'projects',
      activeDeleteMarker.value.projectID,
      'floors',
      activeDeleteMarker.value.floorID,
      'markers',
      activeDeleteMarker.value.id,
      'history'
    );

    const querySnapshotHistory = await getDocs(historyCollection);
    querySnapshotHistory.forEach((docData) => {
      const historyRef = doc(
        db,
        'projects',
        activeDeleteMarker.value.projectID,
        'floors',
        activeDeleteMarker.value.floorID,
        'markers',
        activeDeleteMarker.value.id,
        'history',
        docData.id
      );
      deleteDoc(historyRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });

    //clear legacy_history
    const legacyHistoryCollection = collection(
      db,
      'projects',
      activeDeleteMarker.value.projectID,
      'floors',
      activeDeleteMarker.value.floorID,
      'markers',
      activeDeleteMarker.value.id,
      'legacy_history'
    );

    const querySnapshotLegacyHistory = await getDocs(legacyHistoryCollection);
    querySnapshotLegacyHistory.forEach((docData) => {
      const legacyHistoryRef = doc(
        db,
        'projects',
        activeDeleteMarker.value.projectID,
        'floors',
        activeDeleteMarker.value.floorID,
        'markers',
        activeDeleteMarker.value.id,
        'legacy_history',
        docData.id
      );
      deleteDoc(legacyHistoryRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });

    //clear images
    const imagesCollection = collection(
      db,
      'projects',
      activeDeleteMarker.value.projectID,
      'floors',
      activeDeleteMarker.value.floorID,
      'markers',
      activeDeleteMarker.value.id,
      'images'
    );

    const querySnapshotImages = await getDocs(imagesCollection);
    let images = [];
    querySnapshotImages.forEach((docData) => {
      images.push(docData);
    });
    

    for (const docData of images) {
      const imagesRef = doc(
        db,
        'projects',
        activeDeleteMarker.value.projectID,
        'floors',
        activeDeleteMarker.value.floorID,
        'markers',
        activeDeleteMarker.value.id,
        'images',
        docData.id
      );

      deleteDoc(imagesRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }

    //Finally delete the marker
    const markerRef = doc(
      db,
      'projects',
      activeDeleteMarker.value.projectID,
      'floors',
      activeDeleteMarker.value.floorID,
      'markers',
      activeDeleteMarker.value.id
    );

    deleteDoc(markerRef)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });

    confirmModalData.value.open = false;
  }
</script>
