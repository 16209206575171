<template>
  <router-view v-slot="{ Component, route }">
    <app-layout>
      <component :is="Component" />
    </app-layout>
  </router-view>
  <div
    v-if="env === 'test'"
    class="fixed z-50 px-4 py-1 text-xs text-white bg-red-600 rounded-full right-1 bottom-1"
  >
    Test omgeving
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const env = ref();
  env.value = process.env.VUE_APP_ENV;
</script>
