<template>
  <div class="flex-grow w-full">
    <div class="flex flex-col h-full bg-white">
      <div class="flex items-center p-3 space-x-2 bg-white" id="actions">
        <div class="mr-auto space-x-2">
          <select
            @change="table.setPageSize($event.target.value)"
            name="pageSize"
            id="pageSize"
            class="text-sm border-gray-300 rounded-md"
          >
            <option
              v-for="(pageSize, index) of pageSizes"
              :value="pageSize"
              :key="index"
              :selected="pageSize === defaultPageSize"
            >
              {{ pageSize }}
            </option>
          </select>
          <span> rijen </span>
        </div>
        <input
          type="text"
          class="px-3 py-2 text-sm border border-gray-300 rounded-md"
          v-model="filter"
          placeholder="Zoeken..."
        />
        <button @click="printTable()">
          <PrinterIcon class="flex-shrink-0 w-6 h-6 text-gray-500" />
        </button>
        <!-- <Menu as="div" class="relative">
        <div>
          <MenuButton
            class="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:rounded-md lg:hover:bg-gray-50"
          >
            <DocumentDownloadIcon class="flex-shrink-0 w-6 h-6 text-gray-500" />
          </MenuButton>
        </div>
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <MenuItem v-slot="{ active }">
              <button
                @click="exportAsExcel"
                :class="[
                  active ? 'bg-gray-100' : '',
                  'block text-left w-full px-4 py-2 text-sm text-gray-700',
                ]"
              >
                Excel (.xlsx)
              </button>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <button
                @click="exportAsCSV"
                :class="[
                  active ? 'bg-gray-100' : '',
                  'block text-left w-full px-4 py-2 text-sm text-gray-700',
                ]"
              >
                CSV (.csv)
              </button>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu> -->
      </div>

      <div class="w-auto overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-300" id="dataTable">
          <thead>
            <tr
              v-for="headerGroup in table.getHeaderGroups()"
              :key="headerGroup.id"
              class="border-gray-300 border-y"
            >
              <th
                @click="header.column.getToggleSortingHandler()?.($event)"
                v-for="header in headerGroup.headers"
                :key="header.id"
                class="p-3 text-sm font-semibold text-left text-gray-900"
                :class="{
                  'cursor-pointer select-none': header.column.getCanSort(),
                }"
              >
                <FlexRender
                  :render="header.column.columnDef.header"
                  :props="header.getContext"
                />
                {{ { asc: ' ↑', desc: ' ↓' }[header.column.getIsSorted()] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in table.getRowModel().rows" :key="row.id">
              <td
                v-for="cell in row.getVisibleCells()"
                :key="cell.id"
                class="px-3 py-4 text-sm text-gray-500 whitespace-nowrap"
              >
                <div
                  v-if="cell.column.columnDef.accessorKey === 'actions'"
                  class="space-x-2"
                >
                  <template v-for="(action, index) of actions" :key="index">
                    <button
                      v-if="action.id === 'downloadProject'"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                      class="relative disabled:text-red-500 disabled:cursor-not-allowed"
                      :disabled="!connectionStatusStore.online"
                    >
                      <div
                        v-if="!connectionStatusStore.online"
                        class="w-7 h-0.5 bg-red-500 transform rotate-45 origin-center top-1/2 -translate-y-1/2 absolute -left-px z-10"
                      ></div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <title>Downloaden</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'view'"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <title>Bekijken</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'export'"
                      class="relative disabled:text-red-500 disabled:cursor-not-allowed"
                      :disabled="!connectionStatusStore.online"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                    >
                      <div
                        v-if="!connectionStatusStore.online"
                        class="w-7 h-0.5 bg-red-500 transform rotate-45 origin-center top-1/2 -translate-y-1/2 absolute -left-px z-10"
                      ></div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <title>Exporteer componentenlijst</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'download'"
                      class="relative disabled:text-red-500 disabled:cursor-not-allowed"
                      :disabled="!connectionStatusStore.online"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                    >
                      <div
                        v-if="!connectionStatusStore.online"
                        class="w-7 h-0.5 bg-red-500 transform rotate-45 origin-center top-1/2 -translate-y-1/2 absolute -left-px z-10"
                      ></div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <title>Download logboek</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'edit'"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <title>Bewerken</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'exportMaterials'"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                    >
                      
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                      <title>Exporteer Materialen</title>
                        <line
                          x1="19"
                          y1="13"
                          x2="19"
                          y2="8.75458"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <rect
                          x="17"
                          y="13"
                          width="4"
                          height="9"
                          rx="2"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          fill="none"
                        />
                        <path
                          d="M18.04047,3.27628,16.85573,5.64409a1,1,0,0,0,.1872,1.15459l1.9559,1.9559,1.95824-1.95825a1,1,0,0,0,.1872-1.15459L19.9607,3.27628A.5.5,0,0,0,19.51355,3H18.48763A.5.5,0,0,0,18.04047,3.27628Z"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          d="M5,3,2,6V8H12a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1Z"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                        <path
                          d="M6,8h4a0,0,0,0,1,0,0V20a2,2,0,0,1-2,2H8a2,2,0,0,1-2-2V8A0,0,0,0,1,6,8Z"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'restore'"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <title>Herstellen</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'remove'"
                      @click="clickAction(action.id, row.original.id)"
                      :title="action.name"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <title>Verwijderen</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                    <button
                      v-if="action.id === 'showMap'"
                      @click="clickAction('view', row.original.id)"
                      :title="action.name"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <title>Bekijken</title>
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                        />
                      </svg>
                    </button>
                  </template>
                </div>
                <FlexRender
                  v-else
                  :render="cell.column.columnDef.cell"
                  :props="cell.getContext()"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="p-3 border-t border-gray-300 sticky mt-auto bg-white min-h-[64px] space-y-4 md:space-y-0 flex-row md:flex items-center md:justify-between"
      >
        <ul v-if="table.getPageCount() > 1" class="inline-flex md:order-last">
          <button
            class="p-2 border rounded-l disabled:opacity-50 disabled:cursor-not-allowed"
            @click="table.previousPage()"
            :disabled="!table.getCanPreviousPage()"
          >
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <template v-for="n in table.getPageCount()" :key="n">
            <button
              class="px-4 py-2 text-sm font-medium border"
              :class="[
                n === table.getState().pagination.pageIndex + 1 &&
                  'bg-yellow-300/25 border-yellow-300',
              ]"
              v-if="
                inRange(
                  n,
                  table.getState().pagination.pageIndex - 3,
                  table.getState().pagination.pageIndex + 3
                )
              "
              @click="table.setPageIndex(n - 1)"
            >
              {{ n }}
            </button>
          </template>
          <!-- <button
          class="px-4 py-2 text-sm font-medium border"
          :class="[
            table.getPageCount() ===
              table.getState().pagination.pageIndex + 1 && 'bg-yellow-300',
          ]"
          v-if="table.getPageCount() > 3"
          @click="table.setPageIndex(table.getPageCount() - 1)"
        >
          {{ table.getPageCount() }}
        </button>
        <button
          class="px-4 py-2 text-sm font-medium border"
          :class="[
            table.getPageCount() ===
              table.getState().pagination.pageIndex + 1 && 'bg-yellow-300',
          ]"
          v-if="table.getPageCount() > 3"
          @click="table.setPageIndex(table.getPageCount() - 1)"
        >
          {{ table.getPageCount() }}
        </button> -->

          <button
            class="p-2 text-sm border rounded-r font disabled:opacity-50 disabled:cursor-not-allowed"
            @click="table.nextPage()"
            :disabled="!table.getCanNextPage()"
          >
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </ul>
        <div v-if="table.getFilteredRowModel().rows.length" class="text-sm">
          <span class="font-bold">
            {{
              table.getState().pagination.pageIndex === 0
                ? 1
                : table.getState().pagination.pageIndex *
                    table.getState().pagination.pageSize +
                  1
            }}
          </span>
          tot
          <span class="font-bold">
            {{
              table.getState().pagination.pageIndex *
                table.getState().pagination.pageSize +
              table.getRowModel().rows.length
            }}
          </span>
          van
          <span class="font-bold"
            >{{ table.getFilteredRowModel().rows.length }}
          </span>
          resultaten
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { onMounted, ref, reactive } from 'vue';
  import { PrinterIcon } from '@heroicons/vue/outline';
  import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
  import {
    useVueTable,
    FlexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
  } from '@tanstack/vue-table';

  const connectionStatusStore = useConnectionStatusStore();
  const filter = ref('');
  const pageSizes = ref([5, 10, 20, 30, 40, 50]);
  const sorting = ref();

  const props = defineProps({
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      required: false,
    },
    initialSort: {
      type: Array,
      required: false,
    },
    defaultPageSize: {
      type: Number,
      required: false,
      default: () => 10,
    },
  });

  const tableOptions = reactive({
    get data() {
      return props.data;
    },
    getCoreRowModel: getCoreRowModel(),
    get columns() {
      return props.columns;
    },
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      get globalFilter() {
        return filter.value;
      },
      get sorting() {
        return sorting.value;
      },
    },
    sortDescFirst: true,
    getPaginationRowModel: getPaginationRowModel(),
    enableHiding: true,
    autoResetPageIndex: false,
    initialState: {
      columnVisibility: {
        id: false,
        lastWorkedOnDate: false,
        createdDate: false,
        editedDate: false,
      },
      pageSize: props.defaultPageSize,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: (updaterOrValue) => {
      sorting.value =
        typeof updaterOrValue === 'function'
          ? updaterOrValue(sorting.value)
          : updaterOrValue;
    },
  });

  const table = useVueTable(tableOptions);

  const emit = defineEmits([
    'edit',
    'export',
    'download',
    'view',
    'remove',
    'downloadProject',
    'restore',
  ]);

  onMounted(() => {
    //By default sorts on the provided props
    sorting.value = props.initialSort;
  });

  function printTable() {
    var printWindow = window.open(
      '',
      'newWin',
      `width=${screen.availWidth}height=${screen.availHeight}`
    );
    printWindow.document.write('<html><head><title>Print Data</title>');

    printWindow.document.write('<style type = "text/css">');
    printWindow.document.write(`
        body {
          font-family: Arial;
          font-size: 14px;
        }
        table {
          border: 1px solid rgb(209 213 219);
          border-collapse: collapse;
          width: 100%;
        }
        table th, table td {
          border: 1px solid rgb(209 213 219);
          padding: 8px 12px;
        }
        .dataTable-top, .dataTable-bottom {
          display: none;
        }
      `);
    printWindow.document.write('</style>');
    printWindow.document.write('</head>');

    printWindow.document.write('<body>');
    printWindow.document.write(
      document.getElementById('tableContents').innerHTML
    );
    printWindow.document.write('</body>');

    printWindow.document.write('</html>');
    printWindow.document.close();
    printWindow.print();
  }

  function exportAsExcel() {
    table.value.export({
      type: 'csv',
      download: true,
      filename: 'Excel Data',
      lineDelimiter: '\n\n',
      columnDelimiter: ';',
    });
  }

  function exportAsCSV() {
    table.value.export({
      type: 'csv',
      download: true,
      filename: 'CSV Data',
    });
  }

  function inRange(x, min, max) {
    return (x - min) * (x - max) <= 0;
  }

  function clickAction(event, id) {
    emit(event, id);
  }
</script>
<style>
  #dataTable tr:nth-child(2n) {
    background-color: rgb(249 250 251);
  }

  #dataTable thead {
    background-color: rgb(249 250 251);
  }
</style>
