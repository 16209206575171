import { createApp } from 'vue';
import Toaster from '@meforma/vue-toaster';
import { createPinia } from 'pinia';
import App from './App.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import router from './router/routes';
import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
import { useFloorsStore } from '@/stores/floors-store';
import { useMarkersStore } from '@/stores/markers-store';

import './css/index.scss';
import './registerServiceWorker';

createApp(App)
  .use(Toaster)
  .use(createPinia())
  .use(router)
  .component('AppLayout', AppLayout)
  .mount('#app');
  
//make sure all stores are initialized before using them
const connectionStatusStore = useConnectionStatusStore();

//add event listener for online/offline event (if this happens in the store itself it will be rendered before Pinia is installed and it will break to application)
window.addEventListener('online', connectionStatusStore.updateOnlineStatus);
window.addEventListener('offline', connectionStatusStore.updateOnlineStatus);