<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 z-20 overflow-y-auto"
      @close="closeModal"
    >
      <div class="flex justify-center min-h-screen text-center sm:block">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-sm sm:p-6 md:max-w-4xl"
          >
            <div class="mt-5 md:mt-0 md:col-span-2">
              <img :src="imageSrc" alt="" class="mx-auto aspect-[16/12]" />
            </div>
            <div class="mt-5 sm:mt-6">
              <button class="w-full btn btn--primary" @click="closeModal">
                Sluiten
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { ref } from 'vue';
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';

  const loading = ref(false);

  const props = defineProps({
    open: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    imageSrc: {
      type: String,
      required: true,
      default: '',
    },
  });

  const emit = defineEmits(['close-image-modal']);

  function closeModal() {
    emit('close-image-modal');
  }
</script>

<style scoped></style>
