<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 z-20 overflow-y-auto" @close="cancel">
      <div class="flex justify-center min-h-screen text-center sm:block">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block w-full px-4 pt-5 pb-4 mb-5 text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          >
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Exporteer materialen
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Klik op 'Exporteren' om een lijst van alle gebruikte
                  materialen in dit project te downloaden.
                </p>
              </div>
            </div>

            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                class="w-full btn btn--primary sm:ml-3 sm:w-auto flex items-center justify-center"
                @click="exportMaterials"
                :disabled="loading"
              >
                <template v-if="loading">
                  <svg
                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Bezig met exporteren...
                </template>
                <template v-else> Exporteren </template>
              </button>
              <button
                type="button"
                class="mt-3 w-full btn btn--secondary sm:mt-0 sm:w-auto"
                @click="cancel"
                :disabled="loading"
              >
                Annuleren
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
    default: false,
  },
  projectId: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const emit = defineEmits(['close', 'confirm']);

function cancel() {
  if (!props.loading) {
    emit('close');
  }
}

function exportMaterials() {
  emit('confirm', props.projectId);
}
</script>
