<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Gebruikers',
          link: route.path,
        },
      ]"
    />
    <div class="px-4 pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Gebruikers</h1>
          <p class="mt-2 text-sm text-gray-700">
            Een overzicht van alle gebruikers
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            @click="router.push({ name: 'userCreate' })"
            class="btn btn--primary"
          >
            Nieuwe gebruiker
          </button>
        </div>
      </div>
      <div
        v-for="item in dataSources"
        :key="item.name"
        class="flex flex-col mt-8"
      >
        <div class="mb-2">
          <h2 class="mb-0">
            {{ item.name }}
          </h2>
          <p>{{ item.description }}</p>
        </div>
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Naam
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      E-mailadres
                    </th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span class="sr-only">Bewerken</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr
                    v-for="user in item.dataSource.value"
                    :key="user.id"
                    class="odd:bg-white even:bg-gray-50"
                  >
                    <td
                      class="px-3 py-4 text-sm text-gray-500 whitespace-nowrap"
                    >
                      <div class="text-gray-900">
                        {{ user.userFullName }}
                      </div>
                    </td>
                    <td
                      class="px-3 py-4 text-sm text-gray-500 whitespace-nowrap"
                    >
                      <div class="text-gray-900">
                        {{ user.userEmail }}
                      </div>
                    </td>
                    <td
                      class="relative flex items-center justify-end py-4 pl-3 pr-4 space-x-3 text-sm font-medium text-right whitespace-nowrap"
                    >
                      <template v-if="item.role === 'OWNER'">
                        <router-link
                          :to="{
                            name: 'userEdit',
                            params: {
                              id: user.id,
                            },
                          }"
                          class="text-primary hover:text-primary-darker"
                          v-if="user.id === owner.uid"
                        >
                          <PencilIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </router-link>
                        <router-link
                          :to="{
                            name: 'userChangePassword',
                            params: {
                              id: user.id,
                            },
                          }"
                          class="text-primary hover:text-primary-darker"
                          v-if="user.id === owner.uid"
                        >
                          <LockClosedIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </router-link>
                      </template>
                      <template v-if="item.role === 'CUSTOMER'">
                        <router-link
                          :to="{
                            name: 'userEdit',
                            params: {
                              id: user.id,
                            },
                          }"
                          class="text-primary hover:text-primary-darker"
                        >
                          <PencilIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </router-link>
                        <router-link
                          :to="{
                            name: 'userChangePassword',
                            params: {
                              id: user.id,
                            },
                          }"
                          class="text-primary hover:text-primary-darker"
                        >
                          <LockClosedIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </router-link>
                        <button @click="deleteUser(user)">
                          <TrashIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </button>
                      </template>
                      <template v-if="item.role === 'TECHNICIAN'">
                        <router-link
                          :to="{
                            name: 'userEdit',
                            params: {
                              id: user.id,
                            },
                          }"
                          class="text-primary hover:text-primary-darker"
                        >
                          <PencilIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </router-link>
                        <router-link
                          :to="{
                            name: 'userChangePassword',
                            params: {
                              id: user.id,
                            },
                          }"
                          class="text-primary hover:text-primary-darker"
                        >
                          <LockClosedIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </router-link>
                        <button @click="deleteUser(user)">
                          <TrashIcon
                            class="flex-shrink-0 w-6 h-6 text-gray-500"
                          />
                        </button>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :open="confirmModalData.open"
      :title="confirmModalData.title"
      :body="confirmModalData.body"
      :errorMessage="confirmModalData.errorMessageText"
      :loading="confirmModalData.loading"
      :cancelButtonText="confirmModalData.cancelButtonText"
      :confirmButtonText="confirmModalData.confirmButtonText"
      @cancel="onCancelDelete"
      @confirm="onConfirmDelete"
    />
  </div>
</template>

<script setup>
  import {
    PencilIcon,
    LockClosedIcon,
    TrashIcon,
  } from '@heroicons/vue/outline';
  import { ref, onUnmounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { db, functions } from '@/firebase/firebase.js';
  import { onSnapshot, collection, query, where } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import ConfirmationModal from '../../components/ConfirmationModal.vue';
  import { httpsCallable } from 'firebase/functions';

  const router = useRouter();
  const route = useRoute();

  const owner = ref();
  const activeDeleteUuid = ref();
  const activeDeleteCustomerUuid = ref();

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: `Gebruiker verwijderen`,
    body: 'Weet je zeker dat je deze gebruiker wilt verwijderen? Alle gegevens van de gebruiker en de onderliggende gegevens worden voor altijd permanent van onze servers verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
    errorMessageText: null,
    loading: false,
  });

  const dataSources = [
    {
      name: 'Eigenaar',
      description: 'Eigenaars hebben volledige toegang.',
      dataSource: getReactiveData('OWNER'),
      role: 'OWNER',
    },
    {
      name: 'Klant',
      description: 'Klanten hebben een eigen omgeving met beperkte toegang.',
      dataSource: getReactiveData('CUSTOMER'),
      role: 'CUSTOMER',
    },
    {
      name: 'Monteur',
      description: 'Monteurs hebben alleen toegang tot de app.',
      dataSource: getReactiveData('TECHNICIAN'),
      role: 'TECHNICIAN',
    },
  ];

  function getReactiveData(role) {
    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('userRole', '==', role));
    const users = ref([]);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      users.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });
    onUnmounted(unsubscribe);
    return users;
  }

  getAuth().onAuthStateChanged((u) => {
    owner.value = u;
  });

  function onCancelDelete() {
    confirmModalData.value.open = false;
    activeDeleteUuid.value = null;
    activeDeleteCustomerUuid.value = null;
  }

  async function onConfirmDelete() {
    try {
      confirmModalData.value.loading = true;
      var addMessage = httpsCallable(functions, 'deleteUser');

      var data = {
        userID: activeDeleteUuid.value,
        customerID: activeDeleteCustomerUuid.value,
      };

      const result = await addMessage(data);

      if (result.data.success) {
        confirmModalData.value.open = false;
      }
    } catch (error) {
      console.log(error);
      switch (error.message) {
        case 'permission-denied':
          confirmModalData.value.errorMessageText =
            'U bent niet gemachtigd om de opgegeven bewerking uit te voeren.';
          break;
        case 'auth/insufficient-permission':
          confirmModalData.value.errorMessageText =
            'U bent niet gemachtigd om de opgegeven bewerking uit te voeren.';
          break;
        default:
          confirmModalData.value.errorMessageText = `Er is een onbekende fout opgetreden tijdens het verwijderen van ${user.userFullName} `;
      }
    } finally {
      activeDeleteUuid.value = null;
      activeDeleteCustomerUuid.value = null;
      confirmModalData.value.loading = false;
    }
  }

  function deleteUser(user) {
    confirmModalData.value.loading = false;
    confirmModalData.value.title = `${user.userFullName} verwijderen`;
    confirmModalData.value.body = `Weet je zeker dat je ${user.userFullName} wilt verwijderen? Alle gegevens van ${user.userFullName} en de onderliggende gegevens worden voor altijd permanent van onze servers verwijderd. Deze actie kan niet ongedaan worden gemaakt.`;
    activeDeleteUuid.value = user.id;
    activeDeleteCustomerUuid.value = user.customerID;
    confirmModalData.value.open = true;
  }
</script>
