<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 z-20 overflow-y-auto" @close="cancel">
      <div class="flex justify-center min-h-screen text-center sm:block">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block w-full px-4 pt-5 pb-4 mb-5 text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          >
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ title }}
              </DialogTitle>
              <div class="mt-2">
                <div>
                  <h4 class="sr-only">Status</h4>
                  <p class="text-sm font-medium text-gray-900">
                    {{ status }}
                  </p>
                  <div class="mt-6" aria-hidden="true">
                    <div class="overflow-hidden bg-gray-200 rounded-full">
                      <div
                        class="h-2 transition-all rounded-full bg-primary"
                        :style="`width:${progress}%`"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4"></div>

            <div
              class="sm:pl-4 sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
            >
              <div
                class="block text-sm font-medium text-gray-700 text-center sm:text-left"
              >
                Filteren op status spot
              </div>
              <div class="relative mt-1">
                <!-- Dropdown button showing the dropdown icon or summary -->
                <div
                  @click="isOpen = !isOpen"
                  class="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                >
                  <span v-if="selectedFilters.length">{{
                    selectedFilters
                      .map((selectedFilters) => selectedFilters.name)
                      .join(', ')
                  }}</span>
                  <span v-else>{{ allValue.name }}</span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <!-- Dropdown Options -->
                <div
                  v-if="isOpen"
                  class=" z-10 w-full py-1 mt-1 text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm overflow-y-auto"
                >
                  <template v-for="status in filteredMarkerStatuses" :key="status.id">
                    <div
                      @click="toggleSelection(status)"
                      class="cursor-pointer select-none relative py-2 pl-3 pr-9 flex justify-between items-center"
                      :class="{
                        'bg-primary-200': isSelected(status),
                        'hover:bg-gray-100': !isSelected(status),
                      }"
                    >
                      <span class="flex items-center">
                        <span
                          :class="[
                            status.color,
                            'flex-shrink-0 inline-block h-3 w-3 rounded-full',
                          ]"
                          aria-hidden="true"
                        ></span>
                        <span class="ml-3 block truncate">{{
                          status.name
                        }}</span>
                      </span>
                      <span v-if="isSelected(status)">
                        <CheckIcon class="w-5 h-5" aria-hidden="true" />
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div
              class="mt-5 space-y-4 sm:mt-4 sm:flex sm:flex-row-reverse gap-x-4 sm:space-y-0"
              v-if="!isDownloading"
            >
              <template v-if="isDoneDownloading">
                <button
                  type="button"
                  class="w-full btn btn--primary sm:w-auto"
                  @click="cancel()"
                >
                  Sluiten
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="w-full btn btn--primary sm:w-auto"
                  @click="confirm()"
                >
                  {{ confirmButtonText }}
                </button>
                <button
                  type="button"
                  class="w-full btn btn--secondary sm:w-auto"
                  @click="cancel()"
                >
                  {{ cancelButtonText }}
                </button>
              </template>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import { CheckIcon, SelectorIcon } from '@heroicons/vue/outline';
  import { MarkerStatuses } from '@/components/Map/MarkerStatus';
  import { defineProps, defineEmits, ref, watch } from 'vue';
  import { computed } from 'vue';

  const props = defineProps({
    open: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    status: {
      type: String,
      required: true,
      default: () => '',
    },
    cancelButtonText: {
      type: String,
      required: true,
      default: () => '',
    },
    confirmButtonText: {
      type: String,
      required: true,
      default: () => '',
    },
    progress: {
      type: Number,
      required: true,
      default: () => '',
    },
    isDownloading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    isDoneDownloading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    resetTrigger: {
      type: Number,
      required: true,
      default: () => 0,
    },
  });

  const allValue = ref({
    id: 999,
    name: 'Alles',
    color: 'border-gray-200 border',
    markerIcon: 'marker-icon.png',
  });

  const selected = ref({
    id: 999,
    name: 'Alles',
    color: 'border-gray-200 border',
    markerIcon: 'marker-icon.png',
  });

  const disabled = ref(false);
  const isOpen = ref(false);
  const selectedFilters = ref([allValue.value]);
  watch(
    () => props.resetTrigger,
    () => {
      selected.value = allValue.value;
      disabled.value = false;
    }
  );

  const filteredMarkerStatuses = computed(() => {
    return MarkerStatuses.filter(status => status.id !== 5 && status.id !== 6)
  })

  const emit = defineEmits(['cancel', 'confirm']);

  function toggleSelection(option) {
    // Check if the selected option is not the 'all' option and if the 'all' option is currently in the selected filters
    if (
      option.id !== allValue.value.id &&
      selectedFilters.value.some((f) => f.id === allValue.value.id)
    ) {
      // Remove the 'all' option from the selected filters as another specific filter is being selected
      selectedFilters.value = selectedFilters.value.filter(
        (f) => f.id !== allValue.value.id
      );
    }

    // Find the index of the option in the selected filters (if it exists)
    const index = selectedFilters.value.findIndex(
      (selectedOption) => selectedOption.id === option.id
    );

    // Check if the option is already in the selected filters
    if (index !== -1) {
      // Option found, remove it from the selected filters
      selectedFilters.value.splice(index, 1);

      // If removing this option results in no selected filters, revert to the 'all' option
      if (selectedFilters.value.length === 0) {
        selectedFilters.value.push(allValue.value);
      }
    } else {
      // Option not found in the selected filters, prepare to add it

      // Ensure that 'all' option is removed if another specific option is being selected
      if (
        option.id !== allValue.value.id &&
        selectedFilters.value[0]?.id === allValue.value.id
      ) {
        selectedFilters.value = [];
      }

      // Add the new option to the selected filters
      selectedFilters.value.push(option);

      // If the 'all' option is selected, clear any other selections and only keep the 'all' option
      if (option.id === allValue.value.id) {
        selectedFilters.value = [allValue.value];
      }
    }

    // After updating the selection, close the dropdown
    isOpen.value = false;
  }

  function isSelected(option) {
    // Check if the given option is currently selected
    return selectedFilters.value.some(
      // Iterate through the selected filters to see if any match the given option's ID
      (selectedOption) => selectedOption.id === option.id
    );
  }

  function cancel() {
    if (!props.isDownloading) {
      //make sure to set open prop to false in parent component
      emit('cancel');
    }
  }

  function confirm() {
    disabled.value = true;
    //make sure to set open prop to false in parent component
    var filters = ref([]);
    for (var filter of selectedFilters.value) {
      filters.value.push(filter.id);
    }
    emit('confirm', filters.value);
  }
</script>
