<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Klanten',
          link: '/customers',
        },
        {
          text: 'Klant bewerken',
          link: route.path,
        },
      ]"
    />

    <div class="pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="mx-auto md:grid xl:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h1>Klant bewerken</h1>
            <p class="mt-1">Hier kun je een klant bewerken</p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form
            action="#"
            @submit.prevent="submit"
            :class="{ 'pointer-events-none': loading }"
          >
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                <div>
                  <h3>Basisinformatie</h3>
                </div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                    <label
                      for="adress"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Bedrijfsnaam
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="customerName"
                        id="customerName"
                        v-model="formData.customerName"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="customerName-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="formError" class="p-4 rounded-md bg-red-50">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="mb-0 text-sm font-medium text-red-800">
                        {{ formError }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                <div>
                  <h3>Adresgegevens</h3>
                </div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                    <label
                      for="adress"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Adres
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="customerAddress"
                        id="customerAddress"
                        v-model="formData.customerAddress"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="customerAddress-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                    <label
                      for="zipCode"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Postcode
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="customerZipCode"
                        id="customerZipCode"
                        v-model="formData.customerZipCode"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="customerZipCode-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="sm:col-span-6">
                    <label
                      for="place"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Plaats
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="customerPlace"
                        id="customerPlace"
                        v-model="formData.customerPlace"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="customerPlace-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="formError" class="p-4 rounded-md bg-red-50">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="mb-0 text-sm font-medium text-red-800">
                        {{ formError }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                <div>
                  <h3>Contactgegevens</h3>
                </div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                    <label
                      for="customerContactName"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Naam
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="customerContactName"
                        id="customerContactName"
                        v-model="formData.customerContactName"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="customerContactName-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                    <label
                      for="customerContactPhoneNumber"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Telefoonnummer
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="customerContactPhoneNumber"
                        id="customerContactPhoneNumber"
                        v-model="formData.customerContactPhoneNumber"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="customerContactPhoneNumber-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="sm:col-span-6">
                    <label
                      for="customerContactEmailAddress"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Emailadres
                    </label>
                    <div class="mt-1">
                      <input
                        type="email"
                        name="customerContactEmailAddress"
                        id="customerContactEmailAddress"
                        v-model="formData.customerContactEmailAddress"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="customerContactEmailAddress-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="formError" class="p-4 rounded-md bg-red-50">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="mb-0 text-sm font-medium text-red-800">
                        {{ formError }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
                <div class="flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="router.back()"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Annuleren
                  </button>
                  <Button type="submit" :loading="loading">
                    <template #text>Opslaan</template>
                    <template #loading-text>Verwerken...</template>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { db } from '@/firebase/firebase.js';
  import {
    updateDoc,
    getDoc,
    doc,
    query,
    getDocs,
    where,
    collection,
  } from 'firebase/firestore';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import Button from '../../components/Button.vue';

  const router = useRouter();
  const route = useRoute();
  const loading = ref(false);
  const customerRef = doc(db, 'customers', getCustomerID());
  const formError = ref('');

  const formData = ref({
    customerName: '',
    customerAddress: '',
    customerZipCode: '',
    customerPlace: '',
    customerContactName: '',
    customerContactPhoneNumber: '',
    customerContactEmailAddress: '',
  });

  getCustomer();

  async function getCustomer() {
    const customerSnap = await getDoc(customerRef);
    if (customerSnap.exists) {
      formData.value = customerSnap.data();
    } else {
      formError.value =
        'Er is een onbekende fout opgetreden tijdens het ophalen van de gegevens van deze klant';
    }
  }

  function getCustomerID() {
    return route.params.id;
  }

  async function submit() {
    loading.value = true;

    const customer = {
      customerName: formData.value.customerName,
      customerAddress: formData.value.customerAddress,
      customerZipCode: formData.value.customerZipCode,
      customerPlace: formData.value.customerPlace,
      customerContactName: formData.value.customerContactName,
      customerContactPhoneNumber: formData.value.customerContactPhoneNumber,
      customerContactEmailAddress: formData.value.customerContactEmailAddress,
    };

    updateDoc(customerRef, customer)
      .then((result) => {})
      .catch((error) => {
        switch (error.code) {
          case 'unauthenticated': {
            formError.value =
              'U bent niet gemachtigd om de opgegeven bewerking uit te voeren';
            break;
          }
          case 'permission-denied': {
            formError.value =
              'U bent niet gemachtigd om de opgegeven bewerking uit te voeren';
            break;
          }
        }
      });
    const projectsCollection = collection(db, 'projects');

    const q = query(
      projectsCollection,
      where('customerID', '==', getCustomerID())
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((project) => {
      const projectRef = doc(db, 'projects', project.id);

      updateDoc(projectRef, {
        customerContactName: formData.value.customerContactName,
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });

    loading.value = false;
    router.back();
  }
</script>
