<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Projecten',
          link: '/projects',
        },
        {
          text: `Etages van ${
            project != undefined
              ? project.projectName + ' ' + project.projectNumber
              : '...'
          }`,
          link: route.path,
        },
      ]"
    />
    <div class="px-4 pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Etages</h1>
          <p class="mt-2 text-sm text-gray-700">
            Een overzicht van alle etages van dit project
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            v-if="role === 'TECHNICIAN' || role === 'OWNER'"
            type="button"
            @click="router.push({ name: 'floorCreate' })"
            class="btn btn--primary"
          >
            Nieuwe etage
          </button>
        </div>
      </div>
      <div class="flex flex-col mt-8">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <TanstackTable
                :columns="[
                  { accessorKey: 'id', header: 'ID', show: false },
                  {
                    accessorKey: 'floorNumber',
                    header: 'Etage',
                    sortingFn: (rowA, rowB, columnId) => {
                      var numA = isNumber(rowA.getValue(columnId))
                        ? rowA.getValue(columnId)
                        : undefined;
                      var numB = isNumber(rowB.getValue(columnId))
                        ? rowB.getValue(columnId)
                        : undefined;

                      if (
                        typeof numA === 'undefined' &&
                        typeof numB === 'undefined'
                      )
                        return 0;
                      // if a is undefined and b isn't a should have a lower index in the array
                      else if (typeof numA === 'undefined') return 1;
                      // if b is undefined and a isn't a should have a higher index in the array
                      else if (typeof numB === 'undefined') return -1;
                      // if both numbers are defined compare as normal
                      else return numA - numB;
                    },
                  },
                  {
                    accessorKey: 'floorName',
                    header: 'Naam etage',
                  },
                  {
                    accessorKey: 'amountOfMarkers',
                    header: 'Spots',
                    sortingFn: (rowA, rowB, columnId) => {
                      var numA = isNumber(rowA.getValue(columnId))
                        ? rowA.getValue(columnId)
                        : undefined;
                      var numB = isNumber(rowB.getValue(columnId))
                        ? rowB.getValue(columnId)
                        : undefined;

                      if (
                        typeof numA === 'undefined' &&
                        typeof numB === 'undefined'
                      )
                        return 0;
                      // if a is undefined and b isn't a should have a lower index in the array
                      else if (typeof numA === 'undefined') return 1;
                      // if b is undefined and a isn't a should have a higher index in the array
                      else if (typeof numB === 'undefined') return -1;
                      // if both numbers are defined compare as normal
                      else return numA - numB;
                    },
                  },
                  {
                    accessorKey: 'dateCreated',
                    header: 'Aangemaakt op',
                    enableSorting: false,
                  },
                  {
                    accessorKey: 'dateUpdated',
                    header: 'Bijgewerkt op',
                    enableSorting: false,
                  },
                  {
                    accessorKey: 'actions',
                    header: 'Acties',
                    enableSorting: false,
                    cell: () => 'actions',
                  },
                ]"
                :data="floors"
                :actions="actions"
                :initialSort="[{ id: 'floorNumber', desc: false }]"
                @view="viewFloor"
                @edit="editFloor"
                @remove="deleteFloor"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :open="confirmModalData.open"
      :title="confirmModalData.title"
      :body="confirmModalData.body"
      :loading="confirmModalData.loading"
      :cancelButtonText="confirmModalData.cancelButtonText"
      :confirmButtonText="confirmModalData.confirmButtonText"
      @cancel="onCancelDelete"
      @confirm="onConfirmDelete"
    />
  </div>
</template>

<script setup>
  import { ref, onUnmounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { db, storage } from '@/firebase/firebase.js';
  import { getAuth } from 'firebase/auth';
  import {
    onSnapshot,
    collection,
    doc,
    getDoc,
    getDocs,
    deleteDoc,
    increment,
    updateDoc,
  } from 'firebase/firestore';
  import { ref as firebaseRef, deleteObject } from 'firebase/storage';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import ConfirmationModal from '../../components/ConfirmationModal.vue';
  import { useFloorsStore } from '@/stores/floors-store';
  import { useMarkersStore } from '@/stores/markers-store';
  import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
  import TanstackTable from '../../components/TanstackTable.vue';
  import moment from 'moment';

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: 'Etage verwijderen',
    body: 'Weet je zeker dat je deze etage wilt verwijderen? Alle gegevens van deze etage en de onderliggende gegevens worden voor altijd permanent van onze servers verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
    loading: false,
  });

  const activeDeleteUuid = ref();
  const connectionStatusStore = useConnectionStatusStore();

  const router = useRouter();
  const route = useRoute();

  const floors = getReactiveFloors();
  const project = ref(null);

  const user = ref();
  const actions = ref([]);
  const role = ref();

  getAuth().onAuthStateChanged((u) => {
    user.value = u;

    if (user.value) {
      u.getIdTokenResult().then(function ({ claims }) {
        if (claims.role === 'OWNER') {
          role.value = 'OWNER';
          actions.value = [
            { id: 'showMap', name: 'Bekijken' },
            { id: 'edit', name: 'Bewerken' },
            { id: 'remove', name: 'Verwijderen' },
          ];
        } else if (claims.role === 'CUSTOMER') {
          role.value = 'CUSTOMER';
          actions.value = [{ id: 'showMap', name: 'Bekijken' }];
        } else if (claims.role === 'TECHNICIAN') {
          role.value = 'TECHNICIAN';
          actions.value = [{ id: 'showMap', name: 'Bekijken' }];
        }
      });
    }
  });

  getProject();

  async function getProject() {
    const projectRef = doc(db, 'projects', route.params.id);
    const projectSnap = await getDoc(projectRef);

    projectSnap.exists ? (project.value = projectSnap.data()) : null;
  }

  function getReactiveFloors() {
    const floorsCollection = collection(
      db,
      'projects',
      route.params.id,
      'floors'
    );
    const floors = ref([]);
    const unsubscribe = onSnapshot(floorsCollection, (querySnapshot) => {
      floors.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });
    onUnmounted(unsubscribe);
    return floors;
  }

  function viewFloor(uuid) {
    const floor = floors.value.find((floor) => floor.id === uuid);

    if (floor !== undefined) {
      if (!isNullOrWhitespace(floor.imageStorageID)) {
        router.push({
          name: 'markers',
          params: {
            projectid: route.params.id,
            floorid: uuid,
          },
        });
      } else {
        editFloor(uuid);
      }
    }
  }

  function editFloor(uuid) {
    router.push({
      name: 'floorEdit',
      params: {
        projectid: route.params.id,
        floorid: uuid,
      },
    });
  }

  function deleteFloor(uuid) {
    confirmModalData.value.loading = false;
    activeDeleteUuid.value = uuid;
    confirmModalData.value.open = true;
  }

  function onCancelDelete() {
    confirmModalData.value.open = false;
  }

  async function onConfirmDelete() {
    confirmModalData.value.loading = true;
    //Find floor in floor array so we can use its values
    const floorToDelete = floors.value.find(
      (floor) => floor.id === activeDeleteUuid.value
    );

    if (floorToDelete !== undefined) {
      //Clear out all nested data (markers, components, materials, images, history & legacy_history)

      //Get all markers and delete the nested data accordingly
      const markersCollection = collection(
        db,
        'projects',
        route.params.id,
        'floors',
        activeDeleteUuid.value,
        'markers'
      );

      const querySnapshotMarkers = await getDocs(markersCollection);
      const markersArray = [];
      querySnapshotMarkers.forEach((markerData) => {
        markersArray.push(markerData);
      });

      for (const markerData of markersArray) {
        //clear components
        const componentsCollection = collection(
          db,
          'projects',
          route.params.id,
          'floors',
          activeDeleteUuid.value,
          'markers',
          markerData.id,
          'components'
        );

        const querySnapshotComponents = await getDocs(componentsCollection);
        querySnapshotComponents.forEach((componentData) => {
          const componentRef = doc(
            db,
            'projects',
            route.params.id,
            'floors',
            activeDeleteUuid.value,
            'markers',
            markerData.id,
            'components',
            componentData.id
          );

          deleteDoc(componentRef)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        });

        //clear materials
        const materialsCollection = collection(
          db,
          'projects',
          route.params.id,
          'floors',
          activeDeleteUuid.value,
          'markers',
          markerData.id,
          'materials'
        );

        const querySnapshotMaterials = await getDocs(materialsCollection);
        querySnapshotMaterials.forEach((materialData) => {
          const materialsRef = doc(
            db,
            'projects',
            route.params.id,
            'floors',
            activeDeleteUuid.value,
            'markers',
            markerData.id,
            'materials',
            materialData.id
          );

          deleteDoc(materialsRef)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        });

        //clear history
        const historyCollection = collection(
          db,
          'projects',
          route.params.id,
          'floors',
          activeDeleteUuid.value,
          'markers',
          markerData.id,
          'history'
        );

        const querySnapshotHistory = await getDocs(historyCollection);
        querySnapshotHistory.forEach((historyData) => {
          const historyRef = doc(
            db,
            'projects',
            route.params.id,
            'floors',
            activeDeleteUuid.value,
            'markers',
            markerData.id,
            'history',
            historyData.id
          );
          deleteDoc(historyRef)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        });

        //clear legacy_history
        const legacyHistoryCollection = collection(
          db,
          'projects',
          route.params.id,
          'floors',
          activeDeleteUuid.value,
          'markers',
          markerData.id,
          'legacy_history'
        );

        const querySnapshotLegacyHistory = await getDocs(
          legacyHistoryCollection
        );
        querySnapshotLegacyHistory.forEach((historyData) => {
          const legacyHistoryRef = doc(
            db,
            'projects',
            route.params.id,
            'floors',
            activeDeleteUuid.value,
            'markers',
            markerData.id,
            'legacy_history',
            historyData.id
          );
          deleteDoc(legacyHistoryRef)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        });

        //clear images
        const imagesCollection = collection(
          db,
          'projects',
          route.params.id,
          'floors',
          activeDeleteUuid.value,
          'markers',
          markerData.id,
          'images'
        );

        const querySnapshotImages = await getDocs(imagesCollection);
        let images = [];
        querySnapshotImages.forEach((docData) => {
          images.push(docData);
        });

        for (const imageData of images) {
          const imagesRef = doc(
            db,
            'projects',
            route.params.id,
            'floors',
            activeDeleteUuid.value,
            'markers',
            markerData.id,
            'images',
            imageData.id
          );
          if (connectionStatusStore.online) {
            // Create a reference to the image to delete
            const fullPath = 'markers/' + imageData.data().imageStorageID;
            const imageRef = firebaseRef(storage, fullPath);

            // Delete the file
            deleteObject(imageRef)
              .then(() => {})
              .catch((error) => {});
          } else {
            await imageDeleteOffline(
              imageData,
              markerData.id,
              activeDeleteUuid.value
            );
          }

          deleteDoc(imagesRef)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        }

        const markerRef = doc(
          db,
          'projects',
          route.params.id,
          'floors',
          activeDeleteUuid.value,
          'markers',
          markerData.id
        );
        deleteDoc(markerRef)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }

      if (querySnapshotMarkers.size !== 0) {
        const projectsRef = doc(db, 'projects', route.params.id);

        const project = {
          amountOfMarkers: increment(-Math.abs(querySnapshotMarkers.size)),
        };
        updateDoc(projectsRef, project)
          .then(() => {})
          .catch((error) => {
            formError.value = 'Error';
          });
      }

      //finally delete the floor and its image

      if (connectionStatusStore.online) {
        // Create a reference to the image to delete
        const fullPath = 'floors/' + floorToDelete.imageStorageID;
        const imageRefFloor = firebaseRef(storage, fullPath);

        //Delete the file
        deleteObject(imageRefFloor)
          .then(() => {})
          .catch((error) => {});
      } else {
        await imageFloorDeleteOffline(floorToDelete);
      }

      const floorRef = doc(
        db,
        'projects',
        route.params.id,
        'floors',
        activeDeleteUuid.value
      );

      deleteDoc(floorRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      //Floor was undefined something went wrong
    }

    confirmModalData.value.open = false;
  }

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  async function imageFloorDeleteOffline(image) {
    await useFloorsStore().updateImageDeletePending(image, route.params.id);
  }

  async function imageDeleteOffline(image, markerID, floorID) {
    await useMarkersStore().updateImageDeletePending(
      image,
      markerID,
      floorID,
      route.params.id,
      project.value.projectName
    );
  }
  function isNumber(value) {
    return typeof value === 'number';
  }
</script>
