<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Componenten',
          link: route.path,
        },
      ]"
    />
    <div class="px-4 pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Componenten</h1>
          <p class="mt-2 text-sm text-gray-700">
            Een overzicht van alle componenten
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            @click="router.push({ name: 'componentCreate' })"
            class="btn btn--primary"
          >
            Nieuw component
          </button>
        </div>
      </div>
      <div class="flex flex-col mt-8">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <TanstackTable
                :columns="[
                  { accessorKey: 'id', header: 'ID', show: false },
                  {
                    accessorKey: 'componentNumber',
                    header: 'Nummer',
                    sortingFn: (rowA, rowB, columnId) => {
                      var numA = isNumber(rowA.getValue(columnId))
                        ? rowA.getValue(columnId)
                        : undefined;
                      var numB = isNumber(rowB.getValue(columnId))
                        ? rowB.getValue(columnId)
                        : undefined;

                      if (
                        typeof numA === 'undefined' &&
                        typeof numB === 'undefined'
                      )
                        return 0;
                      // if a is undefined and b isn't a should have a lower index in the array
                      else if (typeof numA === 'undefined') return 1;
                      // if b is undefined and a isn't a should have a higher index in the array
                      else if (typeof numB === 'undefined') return -1;
                      // if both numbers are defined compare as normal
                      else return numA - numB;
                    },
                  },
                  {
                    accessorKey: 'componentName',
                    header: 'Naam',
                  },
                  {
                    accessorKey: 'amountOfTypes',
                    header: 'Types',
                    sortingFn: 'alphanumeric',
                  },
                  {
                    accessorKey: 'componentStatus',
                    header: 'Status',
                  },
                  {
                    accessorKey: 'actions',
                    header: 'Acties',
                    enableSorting: false,
                    cell: () => 'actions',
                  },
                ]"
                :initial-sort="[{ id: 'componentNumber', desc: false }]"
                :data="components"
                :actions="[
                  { id: 'view', name: 'Bekijken' },
                  { id: 'edit', name: 'Bewerken' },
                  { id: 'remove', name: 'Verwijderen' },
                ]"
                @view="viewComponent"
                @edit="editComponent"
                @remove="deleteComponent"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :open="confirmModalData.open"
      :title="confirmModalData.title"
      :body="confirmModalData.body"
      :loading="confirmModalData.loading"
      :cancelButtonText="confirmModalData.cancelButtonText"
      :confirmButtonText="confirmModalData.confirmButtonText"
      @cancel="onCancelDelete"
      @confirm="onConfirmDelete"
    />
  </div>
</template>

<script setup>
  import { ref, onUnmounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { db } from '@/firebase/firebase.js';
  import {
    doc,
    onSnapshot,
    collection,
    deleteDoc,
    getDocs,
    query,
    orderBy,
  } from 'firebase/firestore';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import TanstackTable from '../../components/TanstackTable.vue';
  import ConfirmationModal from '../../components/ConfirmationModal.vue';

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: 'Component verwijderen',
    body: 'Weet je zeker dat je dit component wilt verwijderen? Alle gegevens van dit component en de onderliggende types worden voor altijd permanent van onze servers verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
    loading: false,
  });

  const components = getReactiveComponents();
  const router = useRouter();
  const route = useRoute();
  const activeDeleteUuid = ref();

  function getReactiveComponents() {
    const componentsCollection = collection(db, 'components');
    const q = query(componentsCollection, orderBy('componentName'));

    const components = ref([]);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      components.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });
    onUnmounted(unsubscribe);
    return components;
  }

  function viewComponent(uuid) {
    router.push({
      name: 'types',
      params: {
        id: uuid,
      },
    });
  }

  function editComponent(uuid) {
    router.push({
      name: 'componentEdit',
      params: {
        id: uuid,
      },
    });
  }

  function deleteComponent(uuid) {
    confirmModalData.value.loading = false;
    activeDeleteUuid.value = uuid;
    confirmModalData.value.open = true;
  }

  function onCancelDelete() {
    confirmModalData.value.open = false;
  }

  async function onConfirmDelete() {
    confirmModalData.value.loading = true;
    //First clear out all types nested in the component
    const typesCollection = collection(
      db,
      'components',
      activeDeleteUuid.value,
      'types'
    );

    const typesArray = [];
    const querySnapshot = await getDocs(typesCollection);
    querySnapshot.forEach((doc) => {
      typesArray.push(doc);
    });

    for (const type of typesArray) {
      const typeRef = doc(
        db,
        'components',
        activeDeleteUuid.value,
        'types',
        type.id
      );

      deleteDoc(typeRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    }

    //delete the component
    const componentRef = doc(db, 'components', activeDeleteUuid.value);

    deleteDoc(componentRef)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
    confirmModalData.value.open = false;
  }

  function isNumber(value) {
    return typeof value === 'number';
  }
</script>
