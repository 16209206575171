// @ts-check
import { defineStore } from 'pinia';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { ref } from 'vue';
import { db } from '../firebase/firebase';



export const useDynamicDataStore = defineStore({
  id: 'DynamicData',
  state: () => {
    return {
      allComponents: [],
      activeComponents: [],
      allMaterials: [],
      isLoadingDyanmicData: false,
    };
  },
  getters: {
    async getAllComponents(state) {
      return state.allComponents;
    },
    async getActiveComponents(state) {
      return state.activeComponents;
    },
  },
  actions: {
    async fetchDynamicData() {
      this.isLoadingDyanmicData = true;
      const compomentsCollection = ref(collection(db, 'components'));
      const q = query(compomentsCollection.value, orderBy('componentName'));
      const materialsCollection = ref(collection(db, 'materials'));
      const queryMaterials = query(
        materialsCollection.value,
        orderBy('materialName')
      );

      var localActiveComponents = [];
      var localAllComponents = [];
      var typesCollection = null;
      var that = this;

      const querySnapshotComponents = await getDocs(q);

      querySnapshotComponents.forEach((component) => {
        const newComponentEntryAll = {
          componentName: component.data().componentName,
          componentID: component.id,
          componentStatus: component.data().componentStatus,
          componentNumber:
            component.data().componentNumber !== null
              ? component.data().componentNumber
              : null,
          types: [],
        };

        //put all components in the list even not active to match later on for added components so we can check if the component was deleted or inactive
        localAllComponents.push(newComponentEntryAll);
      });

      const promises = [];

      const availableMaterials = [];

      const querySnapshot = getDocs(queryMaterials).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const newMaterialEntry = {
            selected: false,
            data: doc,
            materialNumber:
              doc.data().materialNumber !== null
                ? doc.data().materialNumber
                : null,
          };
          availableMaterials.push(newMaterialEntry);
        });
      });

      promises.push(querySnapshot);

      for (const component of localAllComponents) {
        typesCollection = collection(
          db,
          'components',
          component.componentID,
          'types'
        );

        const typeQuery = query(typesCollection, orderBy('typeName'));
        const querySnapshotTypes = getDocs(typeQuery).then((result) => {
          result.forEach((type) => {
            const newTypeEntry = {
              typeName: type.data().typeName,
              typeID: type.id,
              typeStatus: type.data().typeStatus,
              typeNumber:
                type.data().typeNumber !== null ? type.data().typeNumber : null,
            };
            component.types.push(newTypeEntry);
          });
        });

        promises.push(querySnapshotTypes);
      }

      await Promise.allSettled(promises);

      //copy over all components without reference
      localActiveComponents = localAllComponents.map((object) => ({
        ...object,
      }));

      //filter not active components out
      var componentsFilteredArray = localActiveComponents.filter(function (el) {
        return el.componentStatus === 'Actief';
      });
      localActiveComponents = componentsFilteredArray;

      //filter not active types out
      for (const c of localActiveComponents) {
        var newNewArray = c.types.filter(function (el) {
          return el.typeStatus === 'Actief';
        });

        newNewArray.sort(function (a, b) {
          if (a.typeNumber === null) {
            return 1;
          }
          if (b.typeNumber === null) {
            return -1;
          }

          return a.typeNumber - b.typeNumber;
        });
        c.types = newNewArray;
      }

      //Sorting by 'Number' field
      localActiveComponents.sort(function (a, b) {
        if (that.isNullOrWhitespace(a.componentNumber)) {
          return 1;
        }
        if (that.isNullOrWhitespace(b.componentNumber)) {
          return -1;
        }
        return a.componentNumber - b.componentNumber;
      });

      

      localAllComponents.sort(function (a, b) {
        if (that.isNullOrWhitespace(a.componentNumber)) {
          return 1;
        }
        if (that.isNullOrWhitespace(b.componentNumber)) {
          return -1;
        }
        return a.componentNumber - b.componentNumber;
      });

      availableMaterials.sort(function (a, b) {
        if (that.isNullOrWhitespace(a.materialNumber)) {
          return 1;
        }
        if (that.isNullOrWhitespace(b.materialNumber)) {
          return -1;
        }
        return a.materialNumber - b.materialNumber;
      });

      //Setting components arrays
      this.activeComponents = localActiveComponents;
      this.allComponents = localAllComponents;
      this.allMaterials = availableMaterials;

      this.isLoadingDyanmicData = false;
    },
    isNullOrWhitespace(input) {
      if (typeof input !== 'number') {
        return !input || !input.trim();
      }
      return false;
    },
  },
});