<template>
  <div>
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton
        :class="open ? '' : 'text-opacity-90'"
        class="flex items-center w-full btn btn--secondary disabled:opacity-50 sm:w-auto"
        :disabled="!marker || markerHistory.length === 0"
      >
        <ArchiveIcon
          class="w-6 h-6 mr-2 transition-all text-secondary"
          aria-hidden="true"
        />
        <span>Spot statuslog</span>
        <ChevronDownIcon
          :class="open ? 'rotate-180' : ''"
          class="w-5 h-5 ml-1 transition-transform text-secondary"
          aria-hidden="true"
        />
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="absolute right-0 z-50 w-full mt-3 sm:w-screen sm:max-w-md"
          v-slot="{ close }"
        >
          <div
            class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="relative bg-white">
              <div class="flex justify-between">
                <h3 class="p-3 mb-0">
                  <span class="text-sm font-normal">{{ marker.qrCode }}</span>
                </h3>
                <button
                  class="self-start p-2"
                  @click="close"
                  title="Sluiten"
                  aria-label="Sluiten"
                >
                  <XIcon class="w-5 h-5 text-gray-500" />
                </button>
              </div>

              <div class="overflow-y-auto divide-y divide-gray-300 max-h-52">
                <div
                  v-for="(history, index) in markerHistory"
                  class="grid grid-cols-12 gap-1 pr-3 text-xs sm:text-sm odd:bg-white even:bg-gray-50"
                >
                  <div class="flex items-center col-span-4 gap-2 md:gap-3">
                    <div
                      class="w-2 min-w-2 h-full"
                      :class="MarkerStatuses[history.statusIndex].color"
                    ></div>
                    <div class="py-2">
                      {{ history.status }}
                    </div>
                  </div>
                  <div class="self-center col-span-4">
                    <span class="py-2">
                      {{ history.editedDate }}
                    </span>
                  </div>
                  <div class="self-center col-span-4 truncate">
                    <span class="py-2">
                      {{ history.editedBy }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script setup>
  import { ref, watch, onUnmounted } from 'vue';
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
  import { ArchiveIcon, XIcon } from '@heroicons/vue/outline';
  import { ChevronDownIcon } from '@heroicons/vue/solid';
  import { MarkerStatuses } from '@/components/Map/MarkerStatus';
  import { db } from '@/firebase/firebase.js';
  import { onSnapshot, collection, orderBy, query } from 'firebase/firestore';

  const markerHistory = ref([]);
  let unsubscribe = ref(null);

  const props = defineProps({
    marker: {
      type: Object,
      required: false,
      default: () => {},
    },
  });

  // watch for prop marker changes and load history
  watch(
    () => props.marker,
    () => {
      // unsub before clicking a new marker to prevent too much simultaneous connections
      if (unsubscribe.value !== null) {
        unsubscribe();
      }

      getReactiveMarkersHistory();
    }
  );

  function getReactiveMarkersHistory() {
    const markersHistoryCollection = collection(
      db,
      'projects',
      props.marker.projectID,
      'floors',
      props.marker.floorID,
      'markers',
      props.marker.id,
      'history'
    );

    const q = query(
      markersHistoryCollection,
      orderBy('timeStampAdded', 'desc')
    );

    unsubscribe = onSnapshot(q, (querySnapshot) => {
      markerHistory.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });
  }

  onUnmounted(() => {
    if (unsubscribe.value !== null) {
      unsubscribe();
    }
  });
</script>
