import { createRouter, createWebHashHistory } from 'vue-router';
import ProjectEdit from '../views/Project/ProjectEdit.vue';
import Projects from '../views/Project/Projects.vue';
import ProjectCreate from '../views/Project/ProjectCreate.vue';
import Floors from '../views/Floor/Floors.vue';
import FloorCreate from '../views/Floor/FloorCreate.vue';
import FloorEdit from '../views/Floor/FloorEdit.vue';
import Login from '../views/Login.vue';
import Markers from '../views/Marker/Markers.vue';
import Users from '../views/User/Users.vue';
import Error404 from '../views/404.vue';
import UserCreate from '../views/User/UserCreate.vue';
import UserEdit from '../views/User/UserEdit.vue';
import UserChangePassword from '../views/User/UserChangePassword.vue';
import { getCurrentUser } from '../firebase/firebase.js';
import Components from '../views/Components/Components.vue';
import ComponentCreate from '../views/Components/ComponentCreate.vue';
import ComponentEdit from '../views/Components/ComponentEdit.vue';
import Types from '../views/Components/Types.vue';
import TypeCreate from '../views/Components/TypeCreate.vue';
import TypeEdit from '../views/Components/TypeEdit.vue';
import Materials from '../views/Materials/Materials.vue';
import MaterialCreate from '../views/Materials/MaterialCreate.vue';
import MaterialEdit from '../views/Materials/MaterialEdit.vue';
import Customers from '../views/Customers/Customers.vue';
import CustomerCreate from '../views/Customers/CustomerCreate.vue';
import CustomerEdit from '../views/Customers/CustomerEdit.vue';
import SoftDeletedMarkers from '../views/MarkersCms/SoftDeletedMarkers.vue';

const routes = [
  {
    // /search/screens -> /search?q=screens
    path: '/',
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/projects' };
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/create',
    name: 'projectCreate',
    component: ProjectCreate,
    meta: { requiresAuth: true, roles: ['OWNER', 'TECHNICIAN'] },
  },
  {
    path: '/project/:id/edit',
    name: 'projectEdit',
    component: ProjectEdit,
    meta: { requiresAuth: true, roles: ['OWNER', 'TECHNICIAN'] },
  },

  {
    path: '/project/:id/floors',
    name: 'floors',
    component: Floors,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/floorCreate/:id',
    name: 'floorCreate',
    component: FloorCreate,
    meta: { requiresAuth: true, roles: ['OWNER', 'TECHNICIAN'] },
  },
  {
    path: '/floorEdit/:projectid:floorid',
    name: 'floorEdit',
    component: FloorEdit,
    meta: { requiresAuth: true, roles: ['OWNER', 'TECHNICIAN'] },
  },
  {
    path: '/project/:projectid/floors/:floorid/markers',
    name: 'markers',
    component: Markers,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/materials',
    name: 'materials',
    component: Materials,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/materials/create',
    name: 'materialCreate',
    component: MaterialCreate,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/materials/:id/edit',
    name: 'materialEdit',
    component: MaterialEdit,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/components',
    name: 'components',
    component: Components,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/components/create',
    name: 'componentCreate',
    component: ComponentCreate,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/components/:id/edit',
    name: 'componentEdit',
    component: ComponentEdit,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/components/:id/types',
    name: 'types',
    component: Types,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/typeCreate/:id',
    name: 'typeCreate',
    component: TypeCreate,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/components/:id/types/:typeID/edit',
    name: 'typeEdit',
    component: TypeEdit,
    meta: { requiresAuth: true, roles: ['OWNER', 'CUSTOMER'] },
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },

  {
    path: '/deleted-markers',
    name: 'softDeletedMarkers',
    component: SoftDeletedMarkers,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },
  {
    path: '/customers/create',
    name: 'customerCreate',
    component: CustomerCreate,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },
  {
    path: '/customers/:id/edit',
    name: 'customerEdit',
    component: CustomerEdit,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },
  {
    path: '/userCreate',
    name: 'userCreate',
    component: UserCreate,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },
  {
    path: '/userEdit/:id',
    name: 'userEdit',
    component: UserEdit,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },
  {
    path: '/userChangePassword/:id',
    name: 'userChangePassword',
    component: UserChangePassword,
    meta: { requiresAuth: true, roles: ['OWNER'] },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'AppLayoutLogin',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'error404',
    component: Error404,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresRole = to.matched.some((record) => record.meta.roles);
  const user = await getCurrentUser();

  if (requiresAuth && !user) {
    return { name: 'login' };
  } else {
    if (requiresRole) {
      const rolesRequired = to.meta.roles;
      const userRole = await user.getIdTokenResult();

      var permissionToProceed = false;

      rolesRequired.forEach((roleRequired) => {
        if (userRole.claims.role === roleRequired) {
          permissionToProceed = true;
        }
      });

      if (!permissionToProceed) {
        //Maybe direct to a access denied page?
        return false;
      }
    }
  }
});

export default router;
