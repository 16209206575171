// @ts-check
import { defineStore } from 'pinia'
import idb from '@/api/idb'

export const useFloorsStore = defineStore({
  id: 'Floors',
  state: () => 
    {
      return{
        localImages: []
      }
    },
  getters: {
      async getImages(state) {
        return state.localImages
      },
  },
  actions: {
    async fetchImages() {
      this.localImages = await idb.getFloorImages();
    },
    async fetchPendingImagesByProjectID(projectID) {
      return await idb.getPendingFloorImagesByProjectID(projectID);
    },
    async saveImage(image) 
    {
      await idb.saveFloorImage(image)
       return true;
    },
    async deleteImage(id, dataLocation) 
    {
       return await idb.deleteImage(id, dataLocation)
    },
    async updateImageDeletePending(image, projectID) 
    {
       await idb.updateFloorDeletePending(image, projectID )
       return true;
    },
    async updateImageUploaded(id, dataLocation) 
    {
       await idb.updateUploaded(id, dataLocation)
       return true;
    }
  },
})