// @ts-check
import { defineStore } from 'pinia';
import idb from '@/api/idb';

export const useProjectsStore = defineStore({
  id: 'Projects',
  state: () => {
    return {
      projectsPendingUpload: [],
      totalAmountOfImages: 0
    };
  },

  getters: {
    async getProjectsPendingUpload(state) {
      return state.projectsPendingUpload;
    },
    async getTotalAmountOfImages(state) {
      return state.totalAmountOfImages;
    },
  },
  actions: {
    async fetchPendingUploadProjects() {
      this.projectsPendingUpload = await idb.getPendingUploadProjects();
      this.setTotalAmountOfImages()
    },
     setTotalAmountOfImages() {
      var count = 0;
      for(var project of this.projectsPendingUpload)
      {
        count += project.imagesCount;
      }
      this.totalAmountOfImages = count
    },
  },
});
