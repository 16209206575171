<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Materialen',
          link: '/materials',
        },
        {
          text: 'Materiaal bewerken',
          link: route.path,
        },
      ]"
    />

    <div class="pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="mx-auto md:grid xl:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h1>Materiaal bewerken</h1>
            <p class="mt-1">Hier kun je dit materiaal bewerken</p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form
            action="#"
            @submit.prevent="submit"
            :class="{ 'pointer-events-none': loading }"
          >
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                <div>
                  <h3>Basisinformatie</h3>
                </div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label
                      for="adress"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Naam
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="materialName"
                        id="materialName"
                        v-model="formData.materialName"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="materialName-error"
                        required
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="adress"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Nummer (sortering)
                    </label>
                    <div class="mt-1">
                      <input
                        type="number"
                        name="materialNumber"
                        id="materialNumber"
                        v-model="formData.materialNumber"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="materialNumber-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="sm:col-span-6">
                    <div class="mt-1">
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input
                            v-model="formData.materialStatus"
                            id="materialStatus"
                            name="materialStatus"
                            type="checkbox"
                            class="w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label
                            for="materialStatus"
                            class="font-medium text-gray-700"
                            >Actief</label
                          >
                          <p class="text-gray-500">
                            Past de zichtbaarheid van dit component aan voor
                            monteurs
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="formError" class="p-4 rounded-md bg-red-50">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="mb-0 text-sm font-medium text-red-800">
                        {{ formError }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
                <div class="flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="router.back()"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Annuleren
                  </button>
                  <Button type="submit" :loading="loading">
                    <template #text>Opslaan</template>
                    <template #loading-text>Verwerken...</template>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { db } from '@/firebase/firebase.js';
  import { updateDoc, getDoc, doc } from 'firebase/firestore';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import Button from '../../components/Button.vue';

  const router = useRouter();
  const route = useRoute();
  const loading = ref(false);
  const materialRef = doc(db, 'materials', getMaterialID());
  const formError = ref('');

  const formData = ref({
    materialName: '',
    materialStatus: true,
    materialNumber: '',
  });

  getComponent();

  async function getComponent() {
    const componentSnap = await getDoc(materialRef);
    if (componentSnap.exists) {
      switch (componentSnap.data().materialStatus) {
        case 'Actief': {
          formData.value.materialStatus = true;
          break;
        }
        case 'Inactief': {
          formData.value.materialStatus = false;
          break;
        }
      }
      formData.value.materialName = componentSnap.data().materialName;

      if (componentSnap.data().materialNumber !== null) {
        formData.value.materialNumber = componentSnap.data().materialNumber;
      }
    } else {
      formError.value =
        'Er is een onbekende fout opgetreden tijdens het ophalen van de gegevens van dit component';
    }
  }

  function getMaterialID() {
    return route.params.id;
  }

  function submit() {
    loading.value = true;

    const component = {
      materialName: formData.value.materialName,
      materialStatus: formData.value.materialStatus,
      materialNumber: formData.value.materialNumber,
    };

    switch (component.materialStatus) {
      case true: {
        component.materialStatus = 'Actief';
        break;
      }
      case false: {
        component.materialStatus = 'Inactief';
        break;
      }
    }

    updateDoc(materialRef, component)
      .then((result) => {
        router.back();
      })
      .catch((error) => {
        switch (error.code) {
          case 'unauthenticated': {
            formError.value =
              'U bent niet gemachtigd om de opgegeven bewerking uit te voeren';
            break;
          }
          case 'permission-denied': {
            formError.value =
              'U bent niet gemachtigd om de opgegeven bewerking uit te voeren';
            break;
          }
        }
      })
      .finally(() => {
        loading.value = false;
      });
  }
</script>
