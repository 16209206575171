<template>
  <div class="px-4 py-6 sm:px-6 sm:py-6 lg:px-16 lg:py-16">
    <h1>Pagina niet gevonden</h1>
    <p class="mt-2 text-sm text-gray-700">
      De door u opgevraagde informatie kon niet gevonden worden. Mogelijk is de
      bijbehorende pagina verplaatst, verwijderd, of bestaat deze niet (meer).
    </p>
    <p>
      Ga terug naar de
      <router-link to="/projects">projecten pagina</router-link>
    </p>
  </div>
</template>
