const DB_NAME = 'linisolLocalStorageDb';
const DB_VERSION = 1;
let DB;

export default {
  async getDb() {
    return new Promise((resolve, reject) => {
      if (DB) {
        return resolve(DB);
      }
      let request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (e) => {
        console.log('Error opening db', e);
        reject('Error');
      };

      request.onsuccess = (e) => {
        DB = e.target.result;
        resolve(DB);
      };

      request.onupgradeneeded = (e) => {
        console.log('Updating local idb');
        let db = e.target.result;
        db.createObjectStore('markers', { keyPath: 'id' });
        db.createObjectStore('floors', { keyPath: 'id' });
      };
    });
  },

  //#region Marker

  async getMarkerImages() {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['markers'], 'readonly');
      trans.oncomplete = () => {
        resolve(images);
      };

      let store = trans.objectStore('markers');
      let images = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          images.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
  async getPendingUploadProjects() {
    let db = await this.getDb();

    const promise1 = new Promise((resolve) => {
      let trans = db.transaction(['markers'], 'readonly');
      trans.oncomplete = () => {
        resolve(markers);
      };

      let store = trans.objectStore('markers');
      let markers = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (
            cursor.value.uploaded === false ||
            cursor.value.deletePending === true
          ) {
            const foundMarker = markers.find(
              (marker) => marker.projectID === cursor.value.projectID
            );
            if (foundMarker) {
              foundMarker.imagesCount++;
            } else {
              let newEntry = {
                projectID: cursor.value.projectID,
                imagesCount: 1,
              };
              markers.push(newEntry);
            }
          }
          cursor.continue();
        }
      };
    });

    const promise2 = new Promise((resolve) => {
      let trans = db.transaction(['floors'], 'readonly');
      trans.oncomplete = () => {
        resolve(projects);
      };

      let store = trans.objectStore('floors');
      let projects = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (
            cursor.value.uploaded === false ||
            cursor.value.deletePending === true
          ) {
            const foundProject = projects.find(
              (project) => project.projectID === cursor.value.projectID
            );
            if (foundProject) {
              foundProject.imagesCount++;
            } else {
              let newEntry = {
                projectID: cursor.value.projectID,
                imagesCount: 1,
              };
              projects.push(newEntry);
            }
          }
          cursor.continue();
        }
      };
    });

    return Promise.all([promise1, promise2]).then((values) => {
      let emptyResponse = [];
      if (values[0].length === 0 && values[1].length === 0) {
        return emptyResponse;
      } else if (values[0].length === 0) {
        return values[1];
      } else if (values[1].length === 0) {
        return values[0];
      } else {
        for (const project of values[1]) {
          let foundIndex = values[0].findIndex(
            (eachArr1Elem) => eachArr1Elem.projectID === project.projectID
          );
          if (foundIndex === -1) {
            values[0].push(project);
          } else {
            values[0][foundIndex].imagesCount =
              values[0][foundIndex].imagesCount + project.imagesCount;
          }
        }
        return values[0];
      }
    });
  },
  async getMarkerImagesByProjectID(projectID) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['markers'], 'readonly');
      trans.oncomplete = () => {
        resolve(images);
      };

      let store = trans.objectStore('markers');
      let images = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.projectID === projectID) {
            images.push(cursor.value);
          }
          cursor.continue();
        }
      };
    });
  },
  async getPendingMarkerImagesByProjectID(projectID) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['markers'], 'readonly');
      trans.oncomplete = () => {
        resolve(images);
      };

      let store = trans.objectStore('markers');
      let images = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.projectID === projectID) {
            if (
              cursor.value.uploaded === false ||
              cursor.value.deletePending === true
            ) {
              images.push(cursor.value);
            }
          }
          cursor.continue();
        }
      };
    });
  },
  async getPendingMarkerImages() {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['markers'], 'readonly');
      trans.oncomplete = () => {
        resolve(images);
      };

      let store = trans.objectStore('markers');
      let images = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (
            cursor.value.uploaded === false ||
            cursor.value.deletePending === true
          ) {
            images.push(cursor.value);
          }
          cursor.continue();
        }
      };
    });
  },
  async saveMarkerImage(image) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['markers'], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };
      //Convert from Proxy to data
      const unwrapped = JSON.parse(JSON.stringify(image));
      //Add the item
      let store = trans.objectStore('markers');
      store.put(unwrapped);
    });
  },
  async updateMarkerDeletePending(image, markerID, floorID, projectID) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(['markers'], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore('markers');

      // Make a request to get a record by key from the object store
      var objectStoreRequest = store.get(image.id);

      objectStoreRequest.onsuccess = function (event) {
        if (objectStoreRequest.result !== undefined) {
          //Found a match
          var foundRecord = objectStoreRequest.result;
          if (foundRecord.uploaded === false) {
            //Not uploaded yet so deleting permanently
            store.delete(image.id);
          } else {
            //Image was uploaded by someone and found locally so adding delete pending
            foundRecord.deletePending = true;
            store.put(foundRecord);
          }
        } else {
          //Image was uploaded by someone and not found locally so adding it as entry to allow the deletion of the image when online again
          const newImage = {
            id: image.id,
            imageID: image.id,
            projectID: projectID,
            floorID: floorID,
            imageMarkerID: markerID,
            imageStorageID: image.data().imageStorageID,
            uploaded: true,
            deletePending: true,
            storageLocation: 'markers/',
          };
          store.put(newImage);
        }
      };
    });
  },
  //#endregion

  //#region Floor
  async getFloorImages() {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['floors'], 'readonly');
      trans.oncomplete = () => {
        resolve(images);
      };

      let store = trans.objectStore('floors');
      let images = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          images.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },

  async saveFloorImage(image) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['floors'], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };

      //Convert from Proxy to data
      const unwrapped = JSON.parse(JSON.stringify(image));
      //Add the item
      let store = trans.objectStore('floors');
      store.put(unwrapped);
    });
  },

  async updateFloorDeletePending(floor, projectID) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(['floors'], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore('floors');

      // Make a request to get a record by key from the object store
      var objectStoreRequest = store.get(floor.id);

      objectStoreRequest.onsuccess = function (event) {
        if (objectStoreRequest.result !== undefined) {
          //Found a match
          var foundRecord = objectStoreRequest.result;
          if (foundRecord.uploaded === false) {
            //Not uploaded yet so deleting permanently
            store.delete(floor.id);
          } else {
            //Image was uploaded by someone and found locally so adding delete pending
            foundRecord.deletePending = true;
            store.put(foundRecord);
          }
        } else {
          //Image was uploaded by someone and not found locally so adding it as entry to allow the deletion of the image when online again
          const newImage = {
            id: floor.id,
            projectID: projectID,
            floorID: floor.id,
            imageStorageID: floor.imageStorageID,
            uploaded: true,
            deletePending: true,
            storageLocation: 'floors/',
          };
          store.put(newImage);
        }
      };
    });
  },

  async getPendingFloorImagesByProjectID(projectID) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['floors'], 'readonly');
      trans.oncomplete = () => {
        resolve(images);
      };

      let store = trans.objectStore('floors');
      let images = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.projectID === projectID) {
            if (
              cursor.value.uploaded === false ||
              cursor.value.deletePending === true
            ) {
              images.push(cursor.value);
            }
          }
          cursor.continue();
        }
      };
    });
  },
  async deleteImages(idArray) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(['images'], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore('images');
      for (const id of idArray) {
        store.openCursor().onsuccess = (e) => {
          let cursor = e.target.result;
          if (cursor) {
            if (cursor.value.id === id) {
              const request = cursor.delete();
              request.onsuccess = function () {};
            }
            cursor.continue();
          }
        };
      }
    });
  },
  //#endregion

  async deleteImage(id, dataLocations) {
    let db = await this.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction([dataLocations], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore(dataLocations);

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.id === id) {
            const request = cursor.delete();
            request.onsuccess = function () {};
          }
          cursor.continue();
        }
      };
    });
  },
  async updateUploaded(id, dataLocation) {
    let db = await this.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction([dataLocation], 'readwrite');
      trans.oncomplete = () => {
        resolve();
      };

      let store = trans.objectStore(dataLocation);

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.id === id) {
            const image = cursor.value;
            image.uploaded = true;
            cursor.update(image);
          }
          cursor.continue();
        }
      };
    });
  },
};
