<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Projecten',
          link: route.path,
        },
      ]"
    />
    <div class="px-4 pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Projecten</h1>
          <p class="mt-2 text-sm text-gray-700">
            Een overzicht van alle projecten
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            v-if="role === 'TECHNICIAN' || role === 'OWNER'"
            type="button"
            @click="router.push({ name: 'projectCreate' })"
            class="btn btn--primary"
          >
            Nieuw project
          </button>
        </div>
      </div>
      <div class="flex flex-col mt-8">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <TanstackTable
                :columns="getColumns()"
                :initial-sort="[{ id: 'timeStampLastWorkedOn', desc: true }]"
                :data="projects"
                :actions="actions"
                @view="viewFloors"
                @edit="editProject"
                @remove="deleteProject"
                @download="downloadLogbook"
                @export="exportComponentsUsed"
                @exportMaterials="exportMaterialsUsed"
                @downloadProject="downloadProject"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadLogbookModal
      :open="downloadLogbookModalData.open"
      :project="downloadLogbookModalData.project"
      :loading="downloadLogbookModalData.loading"
      :generatedLogbookLink="downloadLogbookModalData.generatedLogbookLink"
      @cancel="onCancelDownloadLogbook"
      @confirm="onConfirmDownloadLogbook"
    />
    <ExportComponentsModal
      :open="exportComponentsModalData.open"
      :project="exportComponentsModalData.project"
      :title="exportComponentsModalData.title"
      :status="exportComponentsModalData.status"
      :cancelButtonText="exportComponentsModalData.cancelButtonText"
      :confirmButtonText="exportComponentsModalData.confirmButtonText"
      :progress="exportComponentsModalData.progress"
      :isDownloading="exportComponentsModalData.isDownloading"
      :isDoneDownloading="exportComponentsModalData.isDoneDownloading"
      :reset-trigger="exportComponentsModalData.isTriggered"
      @cancel="onCancelExportComponents"
      @confirm="onConfirmExportComponents"
    />
    <ExportMaterialsModal
      :open="exportMaterialsModalData.open"
      :projectId="exportMaterialsModalData.projectId || ''"
      :loading="exportMaterialsModalData.loading"
      @close="onCancelExportMaterials"
      @confirm="onConfirmExportMaterials"
    />
    <ConfirmationModal
      :open="confirmModalData.open"
      :title="confirmModalData.title"
      :body="confirmModalData.body"
      :loading="confirmModalData.loading"
      :cancelButtonText="confirmModalData.cancelButtonText"
      :confirmButtonText="confirmModalData.confirmButtonText"
      @cancel="onCancelDelete"
      @confirm="onConfirmDelete"
    />
    <DownloadModal
      :open="downloadModalData.open"
      :title="downloadModalData.title"
      :status="downloadModalData.status"
      :cancelButtonText="downloadModalData.cancelButtonText"
      :confirmButtonText="downloadModalData.confirmButtonText"
      :progress="downloadModalData.progress"
      :isDownloading="downloadModalData.isDownloading"
      :isDoneDownloading="downloadModalData.isDoneDownloading"
      :stepsActive="downloadModalData.stepsActive"
      :firstStepBody="downloadModalData.firstStepBody"
      :secondStepBody="downloadModalData.secondStepBody"
      @cancel="onCancelDownloadUpload"
      @confirm="downloadProjectConfirm"
    />
  </div>
</template>

<script setup>
  import { onUnmounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { db, functions, storage } from '@/firebase/firebase.js';
  import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    onSnapshot,
    query,
    updateDoc,
    where,
  } from 'firebase/firestore';
  import { deleteObject, ref as firebaseRef } from 'firebase/storage';
  import { getAuth } from 'firebase/auth';
  import { httpsCallable } from 'firebase/functions';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import ConfirmationModal from '../../components/ConfirmationModal.vue';
  import DownloadModal from '../../components/DownloadModal.vue';
  import DownloadLogbookModal from '../../components/DownloadLogbookModal.vue';
  import ExportMaterialsModal from '../../components/ExportMaterialsModal.vue';
  import ExportComponentsModal from '../../components/ExportComponentsModal.vue';
  import { useMarkersStore } from '@/stores/markers-store';
  import { useFloorsStore } from '@/stores/floors-store';
  import { useProjectsStore } from '@/stores/projects-store';
  import { storeToRefs } from 'pinia';
  import TanstackTable from '../../components/TanstackTable.vue';
  import moment from 'moment';

  let xlsx = require('json-as-xlsx');

  //Logbook Download Modal
  const downloadLogbookModalData = ref({
    open: false,
    project: null,
    generatedLogbookLink: null,
    loading: false,
  });

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: 'Project verwijderen',
    body: 'Weet je zeker dat je dit project wilt verwijderen? Alle gegevens van dit project en de onderliggende gegevens worden voor altijd permanent van onze servers verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
    loading: false,
  });

  // exportComponentsModalData
  const exportComponentsModalData = ref({
    open: false,
    project: null,
    title: 'Exporteer componenten',
    status: 'Wachten op bevestiging....',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Exporteer',
    progress: 0,
    isDownloading: false,
    isDoneDownloading: false,
    isTriggered: 0,
  });

  const exportMaterialsModalData = ref({
  open: false,
  projectId: null,
  loading: false,
});

  //download Modal data
  const downloadModalData = ref({
    open: false,
    title: 'Project downloaden',
    status: 'Wachten op bevestiging....',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Download',
    progress: 'width: 0%',
    isDownloading: false,
    isDoneDownloading: false,
    stepsActive: [],
    firstStepBody: '',
    secondStepBody: '',
  });

  const activeDeleteUuid = ref();
  const activeDownloadUuid = ref();

  let projects = ref([]);
  const router = useRouter();
  const route = useRoute();
  const floorsStore = useFloorsStore();
  const markersStore = useMarkersStore();
  const projectsStore = useProjectsStore();
  const { fetchPendingImagesByProjectID, updateImageUploaded, deleteImage } =
    useMarkersStore();

  //import method for refreshing the pending projects
  const { fetchPendingUploadProjects } = useProjectsStore();

  //Create Ref to the projectsPendingUpload array in the store
  const { projectsPendingUpload } = storeToRefs(useProjectsStore());

  //Fetch the newest data from the idb and the Ref will automatically update
  fetchPendingUploadProjects();

  const user = ref();
  const actions = ref([]);
  const role = ref();

  getAuth().onAuthStateChanged((u) => {
    user.value = u;
    if (user.value) {
      u.getIdTokenResult().then(function ({ claims }) {
        if (claims.role === 'OWNER') {
          role.value = 'OWNER';
          actions.value = [
            { id: 'view', name: 'Bekijken' },
            { id: 'downloadProject', name: 'Download project' },
            { id: 'download', name: 'Download logboek' },
            {
              id: 'export',
              name: 'Exporteer componenten',
            },
            { id: 'edit', name: 'Bewerken' },
            { id: 'remove', name: 'Verwijderen' },
            { id: 'exportMaterials', name: 'Exporteer materialen' },
          ];
        } else if (claims.role === 'CUSTOMER') {
          role.value = 'CUSTOMER';
          actions.value = [
            { id: 'view', name: 'Bekijken' },
            { id: 'download', name: 'Download logboek' },
          ];
        } else if (claims.role === 'TECHNICIAN') {
          role.value = 'TECHNICIAN';
          actions.value = [
            { id: 'view', name: 'Bekijken' },
            { id: 'downloadProject', name: 'Download project' },
            { id: 'download', name: 'Download logboek' },
            {
              id: 'export',
              name: 'Exporteer componenten',
            },
          ];
        }
      });
    }
  });

  watch(
    () => role.value,
    (newValue) => {
      getReactiveProjects();
      if (newValue === 'OWNER') {
        actions.value = [
          { id: 'view', name: 'Bekijken' },
          { id: 'downloadProject', name: 'Download project' },
          { id: 'download', name: 'Download logboek' },
          { id: 'export', name: 'Exporteer componenten' },
          { id: 'exportMaterials', name: 'Exporteer materialen' },
          { id: 'edit', name: 'Bewerken' },
          { id: 'remove', name: 'Verwijderen' },
        ];
      } else if (newValue === 'CUSTOMER') {
        actions.value = [
          { id: 'view', name: 'Bekijken' },
          { id: 'download', name: 'Download logboek' },
        ];
      } else if (newValue === 'TECHNICIAN') {
        actions.value = [
          { id: 'view', name: 'Bekijken' },
          { id: 'downloadProject', name: 'Download project' },
          { id: 'download', name: 'Download logboek' },
          { id: 'export', name: 'Exporteer componenten' },
          { id: 'exportMaterials', name: 'Exporteer materialen' },
        ];
      }
    }
  );

  async function getReactiveProjects() {
    const userRef = doc(db, 'users', user.value.uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const projectsCollection = collection(db, 'projects');
      const customerQuery = query(
        projectsCollection,
        where('customerID', '==', userSnap.data().customerID)
      );

      const unsubscribe = onSnapshot(
        role.value === 'CUSTOMER' ? customerQuery : projectsCollection,
        (querySnapshot) => {
          projects.value = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        }
      );
      onUnmounted(unsubscribe);
    } else {
      console.log('No such document!');
    }
  }

  function viewFloors(uuid) {
    router.push({
      name: 'floors',
      params: {
        id: uuid,
      },
    });
  }

  function editProject(uuid) {
    router.push({
      name: 'projectEdit',
      params: {
        id: uuid,
      },
    });
  }

  function downloadLogbook(uuid) {
    //Find project in projects
    const project = projects.value.find((project) => project.id === uuid);

    if (project !== undefined) {
      downloadLogbookModalData.value.project = project;
    }

    downloadLogbookModalData.value.open = true;
  }

  function onCancelDownloadLogbook() {
    downloadLogbookModalData.value.open = false;
    downloadLogbookModalData.value.loading = false;

    // after dialog closes set `generatedLogbookLink` to null
    setTimeout(() => {
      downloadLogbookModalData.value.generatedLogbookLink = null;
    }, 250);
  }

  function onConfirmDownloadLogbook(data) {
    // if (data.sendEmail === false) {
    //   downloadLogbookModalData.value.loading = true;
    // } else {
    //   downloadLogbookModalData.value.open = false;

    //   // show toast when user has want a mail with logbook link
    //   const toaster = createToaster({ duration: 7200, type: 'success' });
    //   toaster.show('Het logboek wordt in de achtergrond gegenereerd');
    // }

    downloadLogbookModalData.value.loading = true;

    var addMessage = httpsCallable(functions, 'generatePDFLogbook', {
      timeout: 540000,
    });

    var data = {
      uuid: data.uuid,
      theme: data.theme,
      sort: data.sort,
    };

    addMessage(data)
      .then((result) => {
        downloadLogbookModalData.value.generatedLogbookLink = result.data.url;
        // if (data.sendEmail === false) {
        //   downloadLogbookModalData.value.generatedLogbookLink = result.data.url;
        // }
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        downloadLogbookModalData.value.loading = false;
      });
  }

  async function onConfirmExportComponents(filter) {
    // local count
    let componentsCount = 0;

    // find project
    const project = projects.value.find(
      (project) => project.id === activeDownloadUuid.value
    );

    // set total markers
    exportComponentsModalData.value.total = project.amountOfMarkers;

    // Excel File data
    let settings = {
      fileName: `componentenlijst-${
        project != undefined ? project.projectNumber : uuid
      }`,
    };

    // Excel Column data
    const unFilteredComponentsList = ref([]);
    const componentsListData = ref([
      {
        columns: [
          { label: 'Etage', value: 'floorName' },
          { label: 'Component', value: 'componentName' },
          { label: 'Type', value: 'componentTypeName' },
          { label: 'Isolatie', value: 'componentInsulation' },
          { label: 'Positie', value: 'markerPlacement' },
          { label: 'Aantal', value: 'amount' },
          {
            label: 'Aantal componenten bij doorbroken spots',
            value: 'amountStatusPostControl',
          },
        ],
        content: [],
      },
    ]);

    const floorsCollection = collection(
      db,
      'projects',
      activeDownloadUuid.value,
      'floors'
    );
    const querySnapshotFloors = await getDocs(floorsCollection);
    const floorsArray = [];
    querySnapshotFloors.forEach((floorData) => {
      floorsArray.push(floorData);
    });
    for (const floorData of floorsArray) {
      // Get all the markers
      const markersCollection = collection(
        db,
        'projects',
        activeDownloadUuid.value,
        'floors',
        floorData.id,
        'markers'
      );
      const selectedQuery = query(
        markersCollection,
        where('statusIndex', 'in', filter)
      );
      const querySnapshotMarkers = filter.find((f) => f === 999)
        ? await getDocs(markersCollection)
        : await getDocs(selectedQuery);

      const markersArray = [];
      querySnapshotMarkers.forEach((markerData) => {
        if (!markerData.data().isSoftDeleted) {
          markersArray.push(markerData);
        }
      });

      for (const markerData of markersArray) {
        // Get all the components
        const componentsCollection = collection(
          db,
          'projects',
          activeDownloadUuid.value,
          'floors',
          floorData.id,
          'markers',
          markerData.id,
          'components'
        );

        // set status
        exportComponentsModalData.value.status = `Bezig met het ophalen van componenten van ${componentsCount++} / ${
          project.amountOfMarkers
        } spots ...`;

        // set progress
        exportComponentsModalData.value.progress =
          (100 * componentsCount) / project.amountOfMarkers;

        const querySnapshotComponents = await getDocs(componentsCollection);

        for (const comp of querySnapshotComponents.docs) {
          const compRef = doc(db, 'components', comp.data().componentID);
          const retrievedComp = await getDoc(compRef);
          if (!retrievedComp.exists) {
          } else {
            if (retrievedComp.data().componentStatus === 'Actief') {
              const typeRef = doc(
                db,
                `components/${retrievedComp.id}/types`,
                comp.data().typeID
              );
              const retrievedType = await getDoc(typeRef);
              if (!retrievedType.exists) {
              } else {
                if (retrievedType.data().typeStatus === 'Actief') {
                  const newComponent = {
                    floorName: floorData.data().floorName,
                    componentName: retrievedComp.data().componentName,
                    componentTypeID: comp.data().typeID,
                    componentTypeName: retrievedType.data().typeName,
                    componentInsulation: comp.data().insulation,
                    markerPlacement: markerData.data().placement,
                    markerStatus: markerData.data().status,
                    amount: comp.data().amount,
                    amountStatusPostControl: 0,
                  };
                  unFilteredComponentsList.value.push(newComponent);
                }
              }
            }
          }
        }
      }
    }

    //filter out duplicates before generating Excel file
    const filteredComponentsListData = unFilteredComponentsList.value
      .reduce((acc, curr) => {
        const index = acc.findIndex(
          (item) =>
            item.componentTypeID === curr.componentTypeID &&
            item.markerPlacement === curr.markerPlacement &&
            item.componentInsulation === curr.componentInsulation &&
            item.markerStatus === curr.markerStatus
        );
        if (index !== -1) {
          acc[index].amount += curr.amount;
          return acc;
        }
        return [...acc, curr];
      }, [])
      .reduce((acc, curr) => {
        const index = acc.findIndex(
          (item) =>
            item.componentTypeID === curr.componentTypeID &&
            item.markerPlacement === curr.markerPlacement &&
            item.componentInsulation === curr.componentInsulation
        );

        if (index !== -1) {
          acc[index].amount += curr.amount;
          if (curr.markerStatus === 'Open') {
            acc[index].amountStatusPostControl += curr.amount;
          }
          delete curr.markerStatus;
          return acc;
        }

        if (curr.markerStatus === 'Open') {
          curr.amountStatusPostControl = curr.amount;
        }

        delete curr.markerStatus;
        return [...acc, curr];
      }, []);

    componentsListData.value[0].content = filteredComponentsListData;

    // status update
    exportComponentsModalData.value.status = 'Export van componenten compleet';

    //Selected value in ExportcomponentsModal resetten
    exportComponentsModalData.value.isTriggered++;

    // render xlsx
    xlsx(componentsListData.value, settings, onCancelExportComponents());
  }

  function onCancelExportComponents() {
    exportComponentsModalData.value.open = false;

    // setTimeout to prevent 'jumping data', callback would be the ideal option but the component does not support this
    setTimeout(() => {
      exportComponentsModalData.value.progress = 0;
      exportComponentsModalData.value.status = 'Wachten op bevestiging....';
      exportComponentsModalData.value.isDownloading = false;
    }, 250);
  }

  // open Export components list
  function exportComponentsUsed(uuid) {
    exportComponentsModalData.value.open = true;
    activeDownloadUuid.value = uuid;
  }

  function deleteProject(uuid) {
    confirmModalData.value.loading = false;
    activeDeleteUuid.value = uuid;
    confirmModalData.value.open = true;
  }

  function onCancelDelete() {
    confirmModalData.value.open = false;
  }

  async function onConfirmDelete() {
    confirmModalData.value.loading = true;
    //Find project in projects
    const projectToDelete = projects.value.find(
      (project) => project.id === activeDeleteUuid.value
    );

    if (projectToDelete !== undefined) {
      //Clear out all nested data (floors, markers, components, materials, images, history & legacy_history)

      //get all floors and delete the nested data accordingly
      const floorsCollection = collection(
        db,
        'projects',
        activeDeleteUuid.value,
        'floors'
      );

      const querySnapshotFloors = await getDocs(floorsCollection);
      const floorsArray = [];
      querySnapshotFloors.forEach((floorData) => {
        floorsArray.push(floorData);
      });

      for (const floorData of floorsArray) {
        //Get all markers and delete the nested data accordingly
        const markersCollection = collection(
          db,
          'projects',
          activeDeleteUuid.value,
          'floors',
          floorData.id,
          'markers'
        );

        const querySnapshotMarkers = await getDocs(markersCollection);
        const markersArray = [];
        querySnapshotMarkers.forEach((markerData) => {
          markersArray.push(markerData);
        });

        for (const markerData of markersArray) {
          //clear components
          const componentsCollection = collection(
            db,
            'projects',
            activeDeleteUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'components'
          );

          const querySnapshotComponents = await getDocs(componentsCollection);
          querySnapshotComponents.forEach((componentData) => {
            const componentRef = doc(
              db,
              'projects',
              activeDeleteUuid.value,
              'floors',
              floorData.id,
              'markers',
              markerData.id,
              'components',
              componentData.id
            );

            deleteDoc(componentRef)
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          });

          //clear materials
          const materialsCollection = collection(
            db,
            'projects',
            activeDeleteUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'materials'
          );

          const querySnapshotMaterials = await getDocs(materialsCollection);
          querySnapshotMaterials.forEach((materialData) => {
            const materialsRef = doc(
              db,
              'projects',
              activeDeleteUuid.value,
              'floors',
              floorData.id,
              'markers',
              markerData.id,
              'materials',
              materialData.id
            );

            deleteDoc(materialsRef)
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          });

          //clear history
          const historyCollection = collection(
            db,
            'projects',
            activeDeleteUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'history'
          );

          const querySnapshotHistory = await getDocs(historyCollection);
          querySnapshotHistory.forEach((historyData) => {
            const historyRef = doc(
              db,
              'projects',
              activeDeleteUuid.value,
              'floors',
              floorData.id,
              'markers',
              markerData.id,
              'history',
              historyData.id
            );
            deleteDoc(historyRef)
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          });

          //clear legacy_history
          const legacyHistoryCollection = collection(
            db,
            'projects',
            activeDeleteUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'legacy_history'
          );

          const querySnapshotLegacyHistory = await getDocs(
            legacyHistoryCollection
          );
          querySnapshotLegacyHistory.forEach((historyData) => {
            const legacyHistoryRef = doc(
              db,
              'projects',
              activeDeleteUuid.value,
              'floors',
              floorData.id,
              'markers',
              markerData.id,
              'legacy_history',
              historyData.id
            );
            deleteDoc(legacyHistoryRef)
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          });

          //clear images
          const imagesCollection = collection(
            db,
            'projects',
            activeDeleteUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'images'
          );

          const querySnapshotImages = await getDocs(imagesCollection);
          querySnapshotImages.forEach((imageData) => {
            const imagesRef = doc(
              db,
              'projects',
              activeDeleteUuid.value,
              'floors',
              floorData.id,
              'markers',
              markerData.id,
              'images',
              imageData.id
            );

            // Create a reference to the image to delete
            const fullPath = 'markers/' + imageData.data().imageStorageID;
            const imageRef = firebaseRef(storage, fullPath);

            // Delete the file
            deleteObject(imageRef)
              .then(() => {})
              .catch((error) => {});

            deleteDoc(imagesRef)
              .then(() => {})
              .catch((error) => {
                console.log(error);
              });
          });

          const markerRef = doc(
            db,
            'projects',
            activeDeleteUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id
          );
          deleteDoc(markerRef)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        }

        if (querySnapshotMarkers.size !== 0) {
          const projectsRef = doc(db, 'projects', activeDeleteUuid.value);

          const project = {
            amountOfMarkers: increment(-Math.abs(querySnapshotMarkers.size)),
          };
          updateDoc(projectsRef, project)
            .then(() => {})
            .catch((error) => {
              formError.value = 'Error';
            });
        }
        //Delete the floor and its image

        // Create a reference to the image to delete
        const fullPath = 'floors/' + floorData.data().imageStorageID;
        const imageRefFloor = firebaseRef(storage, fullPath);

        //Delete the file
        deleteObject(imageRefFloor)
          .then(() => {})
          .catch((error) => {});

        const floorRef = doc(
          db,
          'projects',
          activeDeleteUuid.value,
          'floors',
          floorData.id
        );

        deleteDoc(floorRef)
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
      //Delete the project and its image

      // Create a reference to the image to delete
      const fullPath = 'projects/' + projectToDelete.imageStorageID;
      const imageRefProject = firebaseRef(storage, fullPath);

      //Delete the file
      deleteObject(imageRefProject)
        .then(() => {})
        .catch((error) => {});

      // Create a reference to the image to delete
      const fullPathPdf = 'logbooks/' + projectToDelete.pdfStorageID;
      const pdfRefProject = firebaseRef(storage, fullPathPdf);

      //Delete the file
      deleteObject(pdfRefProject)
        .then(() => {})
        .catch((error) => {});

      const projectRef = doc(db, 'projects', activeDeleteUuid.value);

      deleteDoc(projectRef)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      //project was undefined something went wrong
    }

    confirmModalData.value.open = false;
  }

  function downloadProject(uuid) {
    activeDownloadUuid.value = uuid;
    downloadModalData.value.open = true;
    setDownloadProgressUI(
      null,
      null,
      null,
      null,
      'Project data downloaden',
      'Afbeeldingen downloaden',
      false
    );
  }

  function onCancelDownloadUpload() {
    downloadModalData.value.open = false;
    downloadModalData.value.title = 'Project downloaden';
    downloadModalData.value.status = 'Wachten op bevestiging....';
    downloadModalData.value.cancelButtonText = 'Annuleren';
    downloadModalData.value.confirmButtonText = 'Download';
    downloadModalData.value.progress = 'width: 0%';
    downloadModalData.value.isDownloading = false;
    downloadModalData.value.stepsActive = [];
    downloadModalData.value.firstStepBody = '';
    downloadModalData.value.secondStepBody = '';
  }

  function exportMaterialsUsed(uuid) {
    exportMaterialsModalData.value.open = true;
    exportMaterialsModalData.value.projectId = uuid;
  }

  function onCancelExportMaterials() {
    exportMaterialsModalData.value.open = false;
  }

  async function onConfirmExportMaterials() {
    try {
      exportMaterialsModalData.value.loading = true; // Set loading state

      // Find project
      const project = projects.value.find(
        (project) => project.id === exportMaterialsModalData.value.projectId
      );

      if (!project) {
        throw new Error('Project not found');
      }

      // Set up Excel file data
      let settings = {
        fileName: `materialenlijst-${project.projectNumber || project.id}`,
      };

      // Set up materials list
      const materialsListData = ref([
        {
          columns: [{ label: 'Materiaal', value: 'materialName' }],
          content: [],
        },
      ]);

      const uniqueMaterials = new Set();

      // Fetch all markers in the project
      const markersSnapshot = await getDocs(
        collection(db, 'projects', project.id, 'floors')
      );

      for (const floorDoc of markersSnapshot.docs) {
        const markersCollection = collection(
          db,
          'projects',
          project.id,
          'floors',
          floorDoc.id,
          'markers'
        );
        const markersSnapshot = await getDocs(markersCollection);

        for (const markerDoc of markersSnapshot.docs) {
          const materialsCollection = collection(
            db,
            'projects',
            project.id,
            'floors',
            floorDoc.id,
            'markers',
            markerDoc.id,
            'materials'
          );
          const materialsSnapshot = await getDocs(materialsCollection);

          for (const materialDoc of materialsSnapshot.docs) {
            uniqueMaterials.add(materialDoc.id);
          }
        }
      }

      // Fetch material names for unique materials
      for (const materialId of uniqueMaterials) {
        const materialRef = doc(db, 'materials', materialId);
        const materialSnapshot = await getDoc(materialRef);

        if (materialSnapshot.exists()) {
          const materialData = materialSnapshot.data();
          materialsListData.value[0].content.push({
            materialName: materialData.materialName,
          });
        }
      }

      // Render Excel file
      xlsx(materialsListData.value, settings);

    } catch (error) {
      console.error('Error exporting materials:', error);
    } finally {
      exportMaterialsModalData.value.loading = false; // Reset loading state
      exportMaterialsModalData.value.open = false; // Close modal
    }
  }

  function onExportMaterialsComplete() {
    // Handle successful export (e.g., show a success message)
    console.log('Materials export completed successfully');
  }

  async function downloadProjectConfirm() {
    //Find project in projects
    const project = projects.value.find(
      (project) => project.id === activeDownloadUuid.value
    );

    if (project !== undefined) {
      setDownloadProgressUI(null, null, true, ['STEP1']);
      var currentProgress = 0;
      var markerDownloadedCount = 1;
      var imagesDownloadedCount = 1;
      var progressUpdate = '';
      var statusUpdate = '';
      var incrementProgressAmount = 50 / Number(project.amountOfMarkers);
      var imagesDownloadReferenceList = [];

      statusUpdate =
        'Beginnen met het downloaden van ' +
        project.amountOfMarkers +
        ' spots...';

      setDownloadProgressUI(statusUpdate, null, null);

      //retrieve floors
      const floorsCollection = collection(
        db,
        'projects',
        activeDownloadUuid.value,
        'floors'
      );

      const querySnapshotFloors = await getDocs(floorsCollection);
      const floorsArray = [];

      querySnapshotFloors.forEach((floorData) => {
        if (!isNullOrWhitespace(floorData.data().imageDownloadURL)) {
          const newImageEntry = {
            imageDownloadURL: floorData.data().imageDownloadURL,
            imageStorageID: floorData.data().imageStorageID,
            projectID: activeDownloadUuid.value,
            storageLocation: 'floors/',
            floorID: floorData.id,
            type: 'FLOOR',
          };
          imagesDownloadReferenceList.push(newImageEntry);
        }
        floorsArray.push(floorData);
      });

      for (const floorData of floorsArray) {
        //retrieve markers
        const markersCollection = collection(
          db,
          'projects',
          activeDownloadUuid.value,
          'floors',
          floorData.id,
          'markers'
        );

        const querySnapshotMarkers = await getDocs(markersCollection);
        const markersArray = [];

        querySnapshotMarkers.forEach((markerData) => {
          if (!markerData.data().isSoftDeleted) {
            markersArray.push(markerData);
          }
        });

        for (const markerData of markersArray) {
          statusUpdate =
            'Bezig met downloaden van ' +
            markerDownloadedCount +
            '/' +
            project.amountOfMarkers +
            ' spots...';
          setDownloadProgressUI(statusUpdate, null, null);

          //retrieve components
          const componentsCollection = collection(
            db,
            'projects',
            activeDownloadUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'components'
          );

          await getDocs(componentsCollection);

          //retrieve materials
          const materialsCollection = collection(
            db,
            'projects',
            activeDownloadUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'materials'
          );

          await getDocs(materialsCollection);

          //retrieve history
          const historyCollection = collection(
            db,
            'projects',
            activeDownloadUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'history'
          );

          await getDocs(historyCollection);

          //retrieve images
          const imagesCollection = collection(
            db,
            'projects',
            activeDownloadUuid.value,
            'floors',
            floorData.id,
            'markers',
            markerData.id,
            'images'
          );

          const querySnapshotImages = await getDocs(imagesCollection);

          querySnapshotImages.forEach((imageData) => {
            if (!isNullOrWhitespace(imageData.data().imageDownloadURL)) {
              const newImageEntry = {
                imageDownloadURL: imageData.data().imageDownloadURL,
                imageStorageID: imageData.data().imageStorageID,
                projectID: activeDownloadUuid.value,
                floorID: floorData.id,
                storageLocation: 'markers/',
                markerID: markerData.id,
                imageID: imageData.id,
                type: 'MARKER',
              };
              imagesDownloadReferenceList.push(newImageEntry);
            }
          });

          markerDownloadedCount++;
          currentProgress = currentProgress + incrementProgressAmount;
          progressUpdate = 'width: ' + currentProgress + '%';
          setDownloadProgressUI(null, progressUpdate, null);
        }
      }

      currentProgress = 50;
      progressUpdate = 'width: ' + currentProgress + '%';
      statusUpdate =
        'Beginnen met het downloaden van ' +
        imagesDownloadReferenceList.length +
        ' afbeeldingen...';
      setDownloadProgressUI(statusUpdate, progressUpdate, null, [
        'STEP1',
        'STEP2',
      ]);

      if (imagesDownloadReferenceList.length > 0) {
        incrementProgressAmount = 50 / imagesDownloadReferenceList.length;
        for (const image of imagesDownloadReferenceList) {
          statusUpdate =
            'Bezig met downloaden van ' +
            imagesDownloadedCount +
            '/' +
            imagesDownloadReferenceList.length +
            ' afbeeldingen...';
          setDownloadProgressUI(statusUpdate, null, null);

          await downloadImage(image);

          imagesDownloadedCount++;
          currentProgress = currentProgress + incrementProgressAmount;
          progressUpdate = 'width: ' + currentProgress + '%';
          setDownloadProgressUI(null, progressUpdate, null);
        }
      }

      //Download components and materials to local storage
      const componentsGlobalCollection = collection(db, 'components');

      //retrieve global components and types
      const querySnapshotGlobalComponents = await getDocs(
        componentsGlobalCollection
      );
      const globalComponentsArray = [];
      querySnapshotGlobalComponents.forEach((globalComponentData) => {
        globalComponentsArray.push(globalComponentData);
      });

      const promises = [];

      //retrieve global materials
      const materialsGlobalCollection = collection(db, 'materials');
      const querySnapshotGlobalMaterials = getDocs(materialsGlobalCollection);

      promises.push(querySnapshotGlobalMaterials);

      for (const globalComponentData of globalComponentsArray) {
        const typesCollection = collection(
          db,
          'components',
          globalComponentData.id,
          'types'
        );

        const querySnapshotGlobalTypes = getDocs(typesCollection);
        promises.push(querySnapshotGlobalTypes);
      }

      statusUpdate = 'Componenten en materialen downloaden.';
      setDownloadProgressUI(
        statusUpdate,
        progressUpdate,
        false,
        null,
        null,
        null,
        true
      );

      await Promise.allSettled(promises);

      statusUpdate =
        'Download compleet, je kunt nu offline aan dit project werken.';
      progressUpdate = 'width: ' + 100 + '%';
      setDownloadProgressUI(
        statusUpdate,
        progressUpdate,
        false,
        null,
        null,
        null,
        true
      );
    } else {
      console.log('Error : Could not find project.');
    }
  }

  function setDownloadProgressUI(
    status,
    progress,
    isDownloading,
    stepsActive,
    firstStepBody,
    secondStepBody,
    isDoneDownloading
  ) {
    if (!isNullOrWhitespace(status)) downloadModalData.value.status = status;
    if (!isNullOrWhitespace(progress))
      downloadModalData.value.progress = progress;
    if (!isNullOrWhitespace(firstStepBody))
      downloadModalData.value.firstStepBody = firstStepBody;
    if (!isNullOrWhitespace(secondStepBody))
      downloadModalData.value.secondStepBody = secondStepBody;
    if (isDownloading != null)
      downloadModalData.value.isDownloading = isDownloading;
    if (isDoneDownloading != null)
      downloadModalData.value.isDoneDownloading = isDoneDownloading;
    if (stepsActive != null) downloadModalData.value.stepsActive = stepsActive;
  }

  async function downloadImage(image) {
    return new Promise((resolve, reject) => {
      const xhrRequest = new XMLHttpRequest();
      xhrRequest.responseType = 'blob';
      xhrRequest.open('GET', image.imageDownloadURL);
      xhrRequest.send();
      xhrRequest.onload = async function (event) {
        const blob = xhrRequest.response;
        const base64String = await blobToBase64(blob);
        switch (image.type) {
          case 'MARKER':
            await markersStore.saveImage({
              id: image.imageID,
              markerID: image.markerID,
              floorID: image.floorID,
              projectID: image.projectID,
              imageStorageID: image.imageStorageID,
              imageID: image.imageID,
              uploaded: true,
              deletePending: false,
              base64: base64String,
              storageLocation: image.storageLocation,
            });
            break;
          case 'FLOOR':
            blobToBase64(blob).then(async function (base64String) {
              await floorsStore.saveImage({
                id: image.floorID,
                imageStorageID: image.imageStorageID,
                floorID: image.floorID,
                projectID: image.projectID,
                uploaded: true,
                deletePending: false,
                base64: base64String,
                storageLocation: image.storageLocation,
              });
            });
            break;
          default:
            console.log('NO MATCHING CASE FOUND');
        }
        resolve(true);
      };
    });
  }

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  // function to determine which columns to show based on the user's role
  function getColumns() {
    const commonColumns = [
      { accessorKey: 'id', header: 'ID', show: false },
      { accessorKey: 'projectNumber', header: 'Kenmerk' },
      { accessorKey: 'projectName', header: 'Naam' },
    ];

    const actionColumn = {
      accessorKey: 'actions',
      header: 'Acties',
      enableSorting: false,
      cell: () => 'actions',
    };

    if (role.value === 'TECHNICIAN') {
      return [...commonColumns, actionColumn];
    }

    return [
      ...commonColumns,
      {
        accessorKey: 'lastWorkedOnDate',
        header: 'Laatst aan gewerkt',
      },
      {
        accessorKey: 'timeStampLastWorkedOn',
        header: 'Laatst aan gewerkt',
        cell: (info) =>
          info.getValue()
            ? moment(info.getValue().toDate()).format('DD-MM-YYYY HH:mm')
            : undefined,
        sortingFn: 'datetime',
        sortUndefined: -1,
      },
      {
        accessorKey: 'amountOfMarkers',
        header: 'Spots',
        sortingFn: 'alphanumeric',
      },
      {
        accessorKey: 'customerName',
        header: 'Klant',
      },
      actionColumn, // This remains the same as before
    ];
  }
</script>
