<template>
  <div class="min-h-full">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 z-40 flex xl:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex flex-col flex-1 w-full max-w-xs bg-primary">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 pt-2 -mr-12">
                <button
                  type="button"
                  class="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="w-6 h-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex items-center flex-shrink-0">
              <img
                class="w-auto h-12 lg:h-20"
                src="../assets/images/linisol.svg"
                alt="Linisol"
              />
            </div>
            <nav
              class="flex-shrink-0 h-full pt-5 pb-4 mt-5 overflow-y-auto divide-y divide-primary-dark"
              aria-label="Sidebar"
            >
              <div class="px-2 space-y-1">
                <div v-for="item in navigation" :key="item.name">
                  <router-link
                    v-if="!item.children"
                    :to="item.href"
                    :key="item.name"
                    custom
                    v-slot="{ navigate, href, isActive }"
                  >
                    <a
                      :href="href"
                      :class="[
                        isActive
                          ? 'bg-primary-darker text-white'
                          : 'text-secondary hover:bg-primary-dark hover:text-white',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
                      ]"
                      @click="navigate"
                      :aria-current="isActive ? 'page' : undefined"
                    >
                      <component
                        :is="item.icon"
                        class="flex-shrink-0 w-6 h-6 mr-4"
                        :class="[
                          isActive
                            ? 'text-white'
                            : 'text-secondary group-hover:text-white',
                        ]"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </a>
                  </router-link>
                  <Disclosure as="div" v-else>
                    <DisclosureButton
                      :class="[
                        'group flex w-full items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-secondary',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        class="flex-shrink-0 w-6 h-6 mr-4"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </DisclosureButton>
                    <DisclosurePanel>
                      <router-link
                        v-for="subItem in item.children"
                        :to="subItem.href"
                        :key="subItem.name"
                        custom
                        v-slot="{ navigate, href, isActive }"
                      >
                        <a
                          :href="href"
                          :class="[
                            isActive
                              ? 'bg-primary-darker text-white'
                              : 'text-secondary hover:bg-primary-dark hover:text-white',
                            'group flex items-center  pl-12 px-2 py-2 text-sm leading-6 font-medium rounded-md',
                          ]"
                          @click="navigate"
                          :aria-current="isActive ? 'page' : undefined"
                        >
                          {{ subItem.name }}
                        </a>
                      </router-link>
                    </DisclosurePanel>
                  </Disclosure>
                </div>
              </div>
            </nav>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden xl:flex xl:w-64 xl:flex-col xl:fixed xl:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow overflow-y-auto bg-primary">
        <div class="flex items-center flex-shrink-0">
          <img
            class="w-auto h-12 px-2 lg:h-20"
            src="../assets/images/linisol.svg"
            alt="Linisol"
          />
        </div>
        <nav
          class="flex flex-col flex-1 pt-5 pb-4 mt-5 overflow-y-auto divide-y divide-primary-dark"
          aria-label="Sidebar"
        >
          <div class="px-2 space-y-1">
            <div v-for="item in navigation" :key="item.name">
              <router-link
                v-if="!item.children"
                :to="item.href"
                :key="item.name"
                custom
                v-slot="{ navigate, href, isActive }"
              >
                <a
                  :href="href"
                  :class="[
                    isActive
                      ? 'bg-primary-darker text-white'
                      : 'text-secondary hover:bg-primary-dark hover:text-white',
                    'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
                  ]"
                  @click="navigate"
                  :aria-current="isActive ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    class="flex-shrink-0 w-6 h-6 mr-4"
                    :class="[
                      isActive
                        ? 'text-white'
                        : 'text-secondary group-hover:text-white',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </router-link>
              <Disclosure as="div" v-else>
                <DisclosureButton
                  :class="[
                    'group flex w-full items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-secondary',
                  ]"
                >
                  <component
                    :is="item.icon"
                    class="flex-shrink-0 w-6 h-6 mr-4"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </DisclosureButton>
                <DisclosurePanel>
                  <router-link
                    v-for="subItem in item.children"
                    :to="subItem.href"
                    :key="subItem.name"
                    custom
                    v-slot="{ navigate, href, isActive }"
                  >
                    <a
                      :href="href"
                      :class="[
                        isActive
                          ? 'bg-primary-darker text-white'
                          : 'text-secondary hover:bg-primary-dark hover:text-white',
                        'group flex items-center  pl-12 px-2 py-2 text-sm leading-6 font-medium rounded-md',
                      ]"
                      @click="navigate"
                      :aria-current="isActive ? 'page' : undefined"
                    >
                      {{ subItem.name }}
                    </a>
                  </router-link>
                </DisclosurePanel>
              </Disclosure>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="min-h-full">
      <div class="flex flex-col flex-1 h-screen xl:pl-64">
        <div
          class="sticky top-0 z-20 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 lg:border-none"
        >
          <button
            type="button"
            class="px-4 text-gray-400 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-light xl:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuAlt1Icon class="w-6 h-6" aria-hidden="true" />
          </button>
          <!-- Header bar -->
          <div
            class="flex items-center flex-1 gap-4 px-4 mx-auto md:justify-end sm:px-6 lg:px-8 max-w-screen-2xl md:gap-6"
          >
            <div class="w-full max-w-lg lg:max-w-xs">
              <SearchWidget />
            </div>
            <div
              class="flex items-center gap-2"
              :class="{ 'hidden sm:flex': globalState.searchBarExpanded }"
            >
              <template v-if="connectionStatusStore.online">
                <StatusOnlineIcon
                  class="w-6 h-6 text-green-500"
                  aria-hidden="true"
                />
                <span class="hidden text-sm text-gray-400 sm:flex">Online</span>
              </template>
              <template v-else>
                <StatusOfflineIcon
                  class="w-6 h-6 text-red-500"
                  aria-hidden="true"
                />
                <span class="hidden text-sm text-gray-400 sm:flex"
                  >Offline</span
                >
              </template>
              <ProjectsUpload
                :title="'Openstaande afbeelding(en) per project'"
                @projectUpload="uploadProject"
              />

              <!-- Profile dropdown -->
              <Menu as="div" class="relative ml-3" v-if="user">
                <div>
                  <MenuButton
                    class="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:p-2 lg:rounded-md lg:hover:bg-gray-50"
                  >
                    <img
                      v-if="user.photoURL"
                      class="w-8 h-8 rounded-full"
                      :src="user.photoURL"
                      :alt="user.displayName"
                    />
                    <div
                      v-else
                      class="flex items-center justify-center w-8 h-8 text-gray-500 uppercase bg-gray-300 rounded-full"
                    >
                      {{ user.email.substring(0, 1) }}
                    </div>
                    <span
                      class="hidden ml-3 text-sm font-medium text-gray-700 lg:block"
                    >
                      <span class="sr-only">Open menu voor </span
                      >{{ user.displayName || user.email }}</span
                    >
                    <ChevronDownIcon
                      class="flex-shrink-0 hidden w-5 h-5 ml-1 text-gray-400 lg:block"
                      aria-hidden="true"
                    />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        @click="logOut"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        >Uitloggen</a
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <div class="flex flex-col h-full">
          <Transition
            enter-from-class="opacity-0"
            enter-active-class="transition-opacity duration-300 ease-in-out"
            enter-to-class="opacity-100"
            leave-from-class="opacity-100"
            leave-active-class="transition-opacity duration-300 ease-in-out"
            leave-to-class="opacity-0"
            mode="out-in"
          >
            <slot />
          </Transition>
        </div>
      </div>
    </div>
    <DownloadModal
      :open="uploadModalData.open"
      :title="uploadModalData.title"
      :status="uploadModalData.status"
      :cancelButtonText="uploadModalData.cancelButtonText"
      :confirmButtonText="uploadModalData.confirmButtonText"
      :progress="uploadModalData.progress"
      :isDownloading="uploadModalData.isDownloading"
      :isDoneDownloading="uploadModalData.isDoneDownloading"
      :stepsActive="uploadModalData.stepsActive"
      :firstStepBody="uploadModalData.firstStepBody"
      :secondStepBody="uploadModalData.secondStepBody"
      @cancel="onCancelUpload"
      @confirm="uploadProjectConfirm"
    />
  </div>
</template>

<script setup>
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  } from '@headlessui/vue';
  import {
    CogIcon,
    FolderIcon,
    UserGroupIcon,
    UsersIcon,
    XIcon,
    QuestionMarkCircleIcon,
    StatusOfflineIcon,
    StatusOnlineIcon,
    MenuAlt1Icon,
    TrashIcon,
  } from '@heroicons/vue/outline';
  import DownloadModal from '../components/DownloadModal.vue';

  import { ChevronDownIcon } from '@heroicons/vue/solid';
  import SearchWidget from '../components/SearchWidget.vue';
  import ProjectsUpload from '@/components/ProjectsUpload.vue';

  import { getAuth, signOut } from 'firebase/auth';
  import { db, storage } from '@/firebase/firebase.js';
  import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
  import {
    ref as firebaseRef,
    deleteObject,
    uploadBytesResumable,
    getDownloadURL,
  } from 'firebase/storage';
  import { useRouter, useRoute } from 'vue-router';
  import { onMounted, onBeforeUnmount, ref } from 'vue';

  import { useMarkersStore } from '@/stores/markers-store';
  import { useFloorsStore } from '@/stores/floors-store';
  import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
  import { useProjectsStore } from '@/stores/projects-store';
  import { storeToRefs } from 'pinia';

  import { useUiStateComposable } from '@/composables/uistate-composable';
  const { globalState } = useUiStateComposable();

  //import method for refreshing the pending projects
  const { fetchPendingUploadProjects } = useProjectsStore();

  //Create Ref to the projectsPendingUpload array in the store
  const { projectsPendingUpload } = storeToRefs(useProjectsStore());

  //Fetch the newest data from the idb and the Ref will automatically update
  fetchPendingUploadProjects();

  //confirmModal
  const uploadModalData = ref({
    open: false,
    title: 'Project downloaden',
    status: 'Wachten op bevestiging....',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Download',
    progress: 'width: 0%',
    isDownloading: false,
    isDoneDownloading: false,
    stepsActive: [],
    firstStepBody: '',
    secondStepBody: '',
  });

  const activeUploadUuid = ref();
  const floorsStore = useFloorsStore();
  const markersStore = useMarkersStore();
  const connectionStatusStore = useConnectionStatusStore();
  const router = useRouter();
  const route = useRoute();

  const loggedIn = ref(false);
  const user = ref();
  const role = ref('');

  const navigation = ref([
    { name: 'Projecten', href: '/projects', icon: FolderIcon },

    { name: 'Klanten', href: '/klanten', icon: UserGroupIcon },
    { name: 'Gebruikers', href: '/users', icon: UsersIcon },
    {
      name: 'Verwijderde spots',
      href: '/deleted-markers',
      icon: TrashIcon,
    },
    {
      name: 'Instellingen',
      href: '/instellingen',
      icon: CogIcon,
    },
  ]);

  const secondaryNavigation = [
    { name: 'Help', href: '/help', icon: QuestionMarkCircleIcon },
  ];

  const sidebarOpen = ref(false);

  onMounted(() => {
    setupFirebase();
  });

  onBeforeUnmount(() => {});

  function setupFirebase() {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        loggedIn.value = true;
      } else {
        // No user is signed in.
        loggedIn.value = false;
      }
    });
  }

  function logOut() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.s
        router.push('/login');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getAuth().onAuthStateChanged((u) => {
    user.value = u;

    if (user.value) {
      u.getIdTokenResult().then(function ({ claims }) {
        if (claims.role === 'OWNER') {
          navigation.value = [
            {
              name: 'Projecten',
              href: '/projects',
              icon: FolderIcon,
            },

            {
              name: 'Klanten',
              href: '/customers',
              icon: UserGroupIcon,
            },
            {
              name: 'Gebruikers',
              href: '/users',
              icon: UsersIcon,
            },
            {
              name: 'Verwijderde spots',
              href: '/deleted-markers',
              icon: TrashIcon,
            },
            {
              name: 'Instellingen',
              icon: CogIcon,
              children: [
                { name: 'Componenten', href: '/components' },
                { name: 'Materialen', href: '/materials' },
              ],
            },
          ];
        } else if (claims.role === 'CUSTOMER') {
          navigation.value = [
            { name: 'Projecten', href: '/projects', icon: FolderIcon },
          ];
        } else if (claims.role === 'TECHNICIAN') {
          navigation.value = [
            { name: 'Projecten', href: '/projects', icon: FolderIcon },
          ];
        }
      });
    }
  });

  function setUploadProgressUI(
    status,
    progress,
    isDownloading,
    stepsActive,
    firstStepBody,
    secondStepBody,
    isDoneDownloading
  ) {
    if (!isNullOrWhitespace(status)) uploadModalData.value.status = status;
    if (!isNullOrWhitespace(progress))
      uploadModalData.value.progress = progress;
    if (!isNullOrWhitespace(firstStepBody))
      uploadModalData.value.firstStepBody = firstStepBody;
    if (!isNullOrWhitespace(secondStepBody))
      uploadModalData.value.secondStepBody = secondStepBody;
    if (isDownloading != null)
      uploadModalData.value.isDownloading = isDownloading;
    if (isDoneDownloading != null)
      uploadModalData.value.isDoneDownloading = isDoneDownloading;
    if (stepsActive != null) uploadModalData.value.stepsActive = stepsActive;
  }

  function uploadProject(projectID) {
    activeUploadUuid.value = projectID;
    uploadModalData.value.confirmButtonText = 'Uploaden';
    uploadModalData.value.title = 'Lokale afbeeldingen uploaden';
    uploadModalData.value.open = true;
    setUploadProgressUI(
      null,
      null,
      null,
      null,
      'Afbeeldingen uploaden',
      'Verwijderde afbeeldingen online opruimen',
      false
    );
  }

  async function uploadProjectConfirm() {
    //Fetch images that need deletion or upload
    setUploadProgressUI('Lokale afbeeldingen ophalen...', null, true, [
      'STEP1',
    ]);
    const imagesMarkers = await markersStore.fetchPendingImagesByProjectID(
      activeUploadUuid.value
    );

    const imagesFloors = await floorsStore.fetchPendingImagesByProjectID(
      activeUploadUuid.value
    );

    const deleteImages = [];
    const uploadImages = [];

    //Separate that list into two Arrays one for uploading and one for deleting
    imagesMarkers.forEach((image) =>
      image.deletePending ? deleteImages.push(image) : uploadImages.push(image)
    );

    //Separate that list into two Arrays one for uploading and one for deleting
    imagesFloors.forEach((image) =>
      image.deletePending ? deleteImages.push(image) : uploadImages.push(image)
    );

    var currentProgress = 0;
    var imagesUploadedCount = 1;
    var imagesDeletedCount = 1;
    var incrementProgressAmount = 50 / Number(uploadImages.length);
    var statusUpdate = '';
    var progressUpdate = '';

    //Start the upload for images that need to be uploaded
    for (const image of uploadImages) {
      statusUpdate =
        'Bezig met uploaden van ' +
        imagesUploadedCount +
        '/' +
        uploadImages.length +
        ' afbeeldingen...';
      setUploadProgressUI(statusUpdate, null, null);

      const fullRef = image.storageLocation + image.imageStorageID;
      const photoRef = firebaseRef(storage, fullRef);

      const base64Response = await fetch(image.base64);
      const blob = await base64Response.blob();

      const uploadTask = uploadBytesResumable(photoRef, blob);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              //console.log('Upload is paused');
              break;
            case 'running':
              //console.log('Upload is running');
              break;
          }
        },
        (error) => {
          console.log(error);
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            var ref = '';
            image.storageLocation === 'markers/'
              ? (ref = doc(
                  db,
                  'projects',
                  image.projectID,
                  'floors',
                  image.floorID,
                  'markers',
                  image.markerID,
                  'images',
                  image.imageID
                ))
              : (ref = doc(
                  db,
                  'projects',
                  image.projectID,
                  'floors',
                  image.floorID
                ));

            updateDoc(ref, { imageDownloadURL: downloadURL })
              .then((result) => {})
              .catch((error) => {
                console.log(error);
              });
          });
        }
      );
      await uploadTask;
      image.storageLocation === 'markers/'
        ? await markersStore.updateImageUploaded(image.id, 'markers')
        : await floorsStore.updateImageUploaded(image.id, 'floors');
      imagesUploadedCount++;
      currentProgress = currentProgress + incrementProgressAmount;
      progressUpdate = 'width: ' + currentProgress + '%';
      setUploadProgressUI(null, progressUpdate, null);
    }
    currentProgress = 50;
    progressUpdate = 'width: ' + currentProgress + '%';
    statusUpdate =
      'Beginnen met het verwijderen van ' +
      deleteImages.length +
      ' afbeeldingen...';
    setUploadProgressUI(statusUpdate, progressUpdate, null, ['STEP1', 'STEP2']);

    incrementProgressAmount = 50 / Number(deleteImages.length);

    for (const image of deleteImages) {
      statusUpdate =
        'Bezig met het verwijderen van ' +
        imagesDeletedCount +
        '/' +
        deleteImages.length +
        ' afbeeldingen...';
      setUploadProgressUI(statusUpdate, null, null);

      const fullPath = image.storageLocation + image.imageStorageID;
      const deleteRef = firebaseRef(storage, fullPath);

      // Delete the file
      await deleteObject(deleteRef)
        .then((result) => {
          // File deleted successfully
        })
        .catch((error) => {
          console.log(error);
        });

      image.storageLocation === 'markers/'
        ? await markersStore.deleteImage(image.id, 'markers')
        : await floorsStore.deleteImage(image.id, 'floors');

      imagesDeletedCount++;
      currentProgress = currentProgress + incrementProgressAmount;
      progressUpdate = 'width: ' + currentProgress + '%';
      setUploadProgressUI(null, progressUpdate, null);
    }

    statusUpdate = 'Upload compleet';
    progressUpdate = 'width: ' + 100 + '%';
    setUploadProgressUI(
      statusUpdate,
      progressUpdate,
      false,
      null,
      null,
      null,
      true
    );
    fetchPendingUploadProjects();
  }

  function onCancelUpload() {
    uploadModalData.value.open = false;
    uploadModalData.value.title = 'Lokale afbeeldingen uploaden';
    uploadModalData.value.status = 'Wachten op bevestiging....';
    uploadModalData.value.cancelButtonText = 'Annuleren';
    uploadModalData.value.confirmButtonText = 'Uploaden';
    uploadModalData.value.progress = 'width: 0%';
    uploadModalData.value.isDownloading = false;
    uploadModalData.value.stepsActive = [];
    uploadModalData.value.firstStepBody = '';
    uploadModalData.value.secondStepBody = '';
  }

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }
</script>
