<template>
  <div
    v-if="open"
    class="fixed inset-0 z-20 overflow-y-auto bg-black overflow-hidden"
    @touchstart="onTouchStart"
    @touchmove.prevent="throttledOnTouchMove"
    @touchend="onTouchEnd"
  >
    <div class="relative w-full h-full transition flex justify-center">
      <div class="w-full md:w-3/4">
        <Camera
          :resolution="{ width: 1600, height: 1600 }"
          @started="onStarted"
          @error="onError"
          ref="camera"
          autoplay
        >
        </Camera>
      </div>

      <div>
        <div class="absolute z-20 right-5 top-5 flex-col">
          <div class="flex items-center justify-end space-x-8">
            <button
              :disabled="isLoading || !isReady"
              type="button"
              class="disabled:opacity-70"
              @click="closeModal"
            >
              <XIcon class="w-8 text-white"></XIcon>
            </button>
          </div>
        </div>
      </div>

      <button
        @click="toggleTorch()"
        :disabled="!torchSupported || isLoadingTorch"
        class="p-3 rounded-md bg-primary absolute top-8 -translate-y-1/3 left-8 disabled:opacity-70"
      >
        <div
          class="w-5 h-0.5 bg-red-500 transform rotate-45 origin-center absolute left-0 top-0 z-10 translate-y-[22px] translate-x-[12px]"
          v-if="!torchSupported"
        ></div>
        <LightningBoltIcon
          class="flex-shrink-0 w-5 h-5"
          :class="torchSupported ? 'text-black' : 'text-red-500'"
        />
      </button>

      <div
        class="absolute bottom-8 left-1/2 -translate-x-1/2 z-10 landscape:right-8 landscape:left-auto landscape:bottom-1/2 landscape:translate-y-1/2"
      >
        <button
          :disabled="isLoading || !isReady || isLoadingTorch"
          @click="snapshot"
          type="button"
          class="disabled:opacity-70"
        >
          <div
            class="rounded-full border-2 w-16 aspect-square flex items-center justify-center"
          >
            <div class="w-8 bg-primary rounded-full aspect-square"></div>
          </div>
        </button>
      </div>
      <div
        class="absolute bottom-8 right-5 -translate-y-1/3 z-10 landscape:right-8 landscape:left-auto landscape:bottom-1/4 landscape:translate-y-1/2"
      >
        <select
          :disabled="isLoading || !isReady"
          v-if="devices.length"
          name="cameras"
          id="cameras"
          v-model="selectedCamera"
          @change="switchCamera()"
          class="border-gray-300 max-w-28 truncate rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm disabled:opacity-70"
        >
          <option v-for="value of devices" :value="value.deviceId">
            {{ value.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';
  import Camera from 'simple-vue-camera';
  import { XIcon } from '@heroicons/vue/outline';
  import { createToaster } from '@meforma/vue-toaster';
  import { LightningBoltIcon } from '@heroicons/vue/outline';
  import _ from 'lodash';

  const camera = ref();
  const isLoading = ref(false);
  const selectedCamera = ref();
  const devices = ref([]);
  const torchSupported = ref(false);
  const zoomSupported = ref(false);
  const isLoadingTorch = ref(false);
  const torchState = ref(false); // New ref to store torch state

  const zoomMin = ref(null);
  const zoomMax = ref(null);
  const zoomValue = ref(null);

  const toaster = createToaster({
    duration: 7200,

    type: 'error',

    position: 'top',
  });

  const isReady = ref(false);

  const props = defineProps({
    open: {
      type: Boolean,

      required: true,

      default: () => false,
    },

    index: {
      type: Number,

      required: false,

      default: () => undefined,
    },
  });

  // Use camera reference to call functions
  const snapshot = async () => {
    try {
      isLoading.value = true;

      const blob = await camera.value?.snapshot();

      const timestamp = new Date().toISOString();

      // Create a File object from the blob

      const file = new File([blob], `snapshot_${timestamp}.jpg`, {
        type: blob.type,
      });

      // Emit the File object

      emit('on-snapshot', { file, index: props.index });

      emit('close-modal');
    } catch (e) {
      toaster.show(e);
    } finally {
      isLoading.value = false;
    }
  };

  const switchCamera = () => {
    camera.value?.changeCamera(selectedCamera.value);
  };

  watch(camera, async (newCamera) => {
    if (newCamera) {
      devices.value = await camera.value.devices(['videoinput']);
    }
  });

  const onStarted = async () => {
    isReady.value = true;

    try {
      selectedCamera.value = camera.value.currentDeviceID();
      torchSupported.value = isTorchSupported();
      zoomSupported.value = isZoomSupported();

      // Load and apply the torch state from local storage
      const savedTorchState = JSON.parse(localStorage.getItem('torchState'));

      if (savedTorchState && torchSupported.value) {
        await toggleTorch(true); // This will apply the saved state
      }
    } catch (e) {
      toaster.show(e);
    }
  };

  const onError = (e) => {
    toaster.show(e);
  };

  const emit = defineEmits(['close-modal', 'on-snapshot']);

  function closeModal() {
    emit('close-modal');
  }

  function isTorchSupported() {
    // Access the video element directly using the camera ref
    const stream = camera.value?.stream;

    if (stream) {
      // Get the first video track from the stream
      const track = stream.getVideoTracks()[0];

      if (track) {
        const settings = track.getSettings();
        // Check if 'torch' is a property in the track settings
        return 'torch' in settings;
      }
    }
    return false;
  }

  // Check if zoom is supported by the camera
  function isZoomSupported() {
    const stream = camera.value?.stream;

    if (stream) {
      const track = stream.getVideoTracks()[0];

      if (track) {
        const capabilities = track.getCapabilities();

        // Check for zoom support and set min/max zoom values
        if ('zoom' in capabilities) {
          zoomMin.value = capabilities.zoom.min;
          zoomMax.value = capabilities.zoom.max;
          zoomValue.value = capabilities.zoom.min;
          return true;
        }
      }
    }
    return false;
  }

  // Set the desired zoom level (clamped within min/max bounds)
  async function setZoomLevel(desiredZoomValue) {
    try {
      const stream = camera.value?.stream;

      if (stream) {
        const track = stream.getVideoTracks()[0];
        if (track) {
          // Clamp zoom level within bounds
          const newZoomValue = Math.max(
            zoomMin.value,
            Math.min(zoomMax.value, desiredZoomValue)
          );

          zoomValue.value = newZoomValue;

          // Apply the zoom constraints
          track.applyConstraints({
            advanced: [{ zoom: zoomValue.value }],
          });
        }
      }
    } catch (error) {
      toaster.show(error);
    }
  }

  // Variables for touch-based pinch-to-zoom
  let initialDistance = null;
  let initialZoomLevel = null;

  // Calculate the distance between two touch points
  const getDistance = (touches) => {
    const [touch1, touch2] = touches;
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.hypot(dx, dy); // Euclidean distance
  };

  // Handle touch start event
  const onTouchStart = (event) => {
    if (event.touches.length === 2) {
      initialDistance = getDistance(event.touches); // Record initial distance
      initialZoomLevel = zoomValue.value; // Record current zoom level
    }
  };

  // Handle touch move event (with zoom level scaling)
  const onTouchMove = (event) => {
    if (
      event.touches.length === 2 &&
      initialDistance &&
      initialZoomLevel !== null
    ) {
      const currentDistance = getDistance(event.touches);
      const scaleChange = currentDistance / initialDistance; // Calculate scale change
      const newZoomLevel = initialZoomLevel * scaleChange; // Compute new zoom level

      setZoomLevel(newZoomLevel); // Apply smooth zoom
    }
  };

  const throttledOnTouchMove = _.throttle(onTouchMove, 50); // Throttles to 50ms intervals

  // Handle touch end event (reset zoom variables)
  const onTouchEnd = (event) => {
    initialDistance = null;
    initialZoomLevel = null;
  };

  // Save torch state to localStorage
  function saveTorchState(state) {
    localStorage.setItem('torchState', JSON.stringify(state));
  }

  async function toggleTorch(val) {
    const stream = camera.value?.stream;

    if (stream) {
      // Get the first video track from the stream
      const track = stream.getVideoTracks()[0];

      if (track) {
        isLoadingTorch.value = true;

        const newTorchState = val ? val : !torchState.value;

        // Toggle the torch state
        try {
          await track.applyConstraints({
            advanced: [{ torch: newTorchState }],
          });
          torchState.value = newTorchState;

          saveTorchState(newTorchState); // Save new state
        } catch (e) {
          console.error('Error toggling torch:', e);
        } finally {
          isLoadingTorch.value = false;
        }
      }
    }
  }
</script>
