<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 z-20 overflow-y-auto" @close="cancel">
      <div class="flex justify-center min-h-screen text-center sm:block">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
          >
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ title }}
              </DialogTitle>
              <div class="mt-2">
                <div>
                  <h4 class="sr-only">Status</h4>
                  <p class="text-sm font-medium text-gray-900">
                    {{ status }}
                  </p>
                  <div class="mt-6" aria-hidden="true">
                    <div class="overflow-hidden bg-gray-200 rounded-full">
                      <div
                        class="h-2 transition-all rounded-full bg-primary"
                        :style="progress"
                      ></div>
                    </div>
                    <div
                      class="hidden grid-cols-3 mt-6 text-sm font-medium text-gray-600 sm:grid"
                    >
                      <div
                        :class="{
                          'text-primary-dark':
                            stepsActive.findIndex(
                              (step) => step === 'STEP1'
                            ) !== -1,
                        }"
                      >
                        {{ firstStepBody }}
                      </div>
                      <div
                        class="text-center"
                        :class="{
                          'text-primary-dark':
                            stepsActive.findIndex(
                              (step) => step === 'STEP2'
                            ) !== -1,
                        }"
                      >
                        {{ secondStepBody }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mt-5 space-y-4 sm:mt-4 sm:flex sm:flex-row-reverse gap-x-4 sm:space-y-0"
              v-if="!isDownloading"
            >
              <template v-if="isDoneDownloading">
                <button
                  type="button"
                  class="w-full btn btn--primary sm:w-auto"
                  @click="cancel()"
                >
                  Sluiten
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="w-full btn btn--primary sm:w-auto"
                  @click="confirm()"
                >
                  {{ confirmButtonText }}
                </button>
                <button
                  type="button"
                  class="w-full btn btn--secondary sm:w-auto"
                  @click="cancel()"
                >
                  {{ cancelButtonText }}
                </button>
              </template>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { ref } from 'vue';
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';

  const props = defineProps({
    open: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    title: {
      type: String,
      required: true,
      default: () => '',
    },
    status: {
      type: String,
      required: true,
      default: () => '',
    },
    cancelButtonText: {
      type: String,
      required: true,
      default: () => '',
    },
    confirmButtonText: {
      type: String,
      required: true,
      default: () => '',
    },
    progress: {
      type: String,
      required: true,
      default: () => '',
    },
    isDownloading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    isDoneDownloading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    stepsActive: {
      type: Array,
      required: false,
      default: () => [],
    },
    firstStepBody: {
      type: String,
      required: true,
      default: () => '',
    },
    secondStepBody: {
      type: String,
      required: true,
      default: () => '',
    },
  });

  const isActive = ref(true);

  const internalOpen = ref(false);

  const emit = defineEmits(['cancel', 'confirm']);

  function cancel() {
    if (!props.isDownloading) {
      //make sure to set open prop to false in parent component
      emit('cancel');
    }
  }

  function confirm() {
    //make sure to set open prop to false in parent component
    emit('confirm');
  }
</script>
