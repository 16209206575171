<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 z-20 overflow-y-auto" @close="cancel">
      <div class="flex justify-center min-h-screen text-center sm:block">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6"
          >
            <template v-if="generatedLogbookLink">
              <div>
                <div
                  class="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full"
                >
                  <CheckIcon
                    class="w-6 h-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    Logboek succesvol gegenereerd
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Het logboek is met succes gegenereerd, klik op de download
                      knop om het logboek te bekijken.
                    </p>
                  </div>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Logboek downloaden
                </DialogTitle>
                <div class="mt-2">
                  <div v-if="loading">
                    <div
                      class="absolute inset-0 z-20 bg-white/90 backdrop-blur-[2px] p-4 flex items-center justify-center"
                    >
                      <div
                        class="flex flex-col items-center justify-center gap-4"
                      >
                        <img
                          src="../assets/images/logbookloading.gif"
                          alt="Logboek Genereren..."
                          class="w-24"
                        />
                        <span class="text-sm text-center">
                          Het logboek wordt nu gegenereerd. <br /><br />
                          Een ogenblik geduld aub...
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4 class="sr-only">Logboek downloaden</h4>
                    <div class="px-2 py-3 rounded-md bg-yellow-50">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <ExclamationIcon
                            class="w-5 h-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div class="ml-3">
                          <div class="text-sm text-gray-500">
                            <p class="m-0">
                              Let op! Bij een groot project kan het genereren
                              van een logboek even duren +- 1 min.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="pt-4">
                    <template
                      v-if="
                        project &&
                        project.pdfDownloadURL &&
                        project.pdfDownloadURL != ''
                      "
                    >
                      <div class="mb-2 border-b border-gray-200">
                        <p class="text-sm font-medium text-gray-500">
                          Dit logboek is reeds op
                          <strong class="text-black">{{
                            project.pdfGeneratedDate
                          }}</strong>
                          gegenereerd met de sortering
                          <strong class="text-black">
                            <template v-if="project.pdfSorting === 'date'">
                              datum.
                            </template>
                            <template v-if="project.pdfSorting === 'floor'">
                              etage.
                            </template>
                            <template v-if="project.pdfSorting === 'placement'">
                              positie.
                            </template>
                            <template v-if="project.pdfSorting === 'status'">
                              spot status.
                            </template>
                          </strong>
                          Klik op 'Download' om deze versie te downloaden. Is er
                          een spot gewijzigd klik dan op 'Genereer logboek'.
                        </p>
                        <a
                          :href="project.pdfDownloadURL"
                          target="_blank"
                          class="my-4 btn btn--primary"
                          >Download logboek</a
                        >
                      </div>
                    </template>

                    <div class="z-20 space-y-4 text-left">
                      <RadioGroup
                        v-model="selectedLogbookVariant"
                        :disabled="loading"
                      >
                        <RadioGroupLabel
                          class="text-sm font-medium text-gray-900"
                        >
                          Selecteer een variant
                        </RadioGroupLabel>

                        <div class="flex mt-4 gap-x-3 gap-y-6 sm:gap-x-4">
                          <RadioGroupOption
                            as="template"
                            v-for="logbookVariant in logbookVariants"
                            :key="logbookVariant.id"
                            :value="logbookVariant"
                            v-slot="{ checked, active }"
                          >
                            <div
                              :class="[
                                checked
                                  ? 'border-transparent'
                                  : 'border-gray-300',
                                active
                                  ? 'border-green-500 ring-2 ring-green-500'
                                  : '',
                                'relative bg-white border rounded-sm shadow-sm p-2 flex cursor-pointer focus:outline-none flex-1',
                              ]"
                            >
                              <span class="relative flex flex-1">
                                <span class="flex flex-col">
                                  <RadioGroupLabel
                                    as="span"
                                    class="block text-sm font-medium text-gray-900"
                                  >
                                    <img
                                      :src="logbookVariant.image"
                                      :alt="logbookVariant.title"
                                    />
                                  </RadioGroupLabel>
                                </span>
                                <div
                                  :class="[
                                    !checked ? 'invisible' : '',
                                    'absolute flex items-center justify-center w-8 h-8 bg-green-500 rounded-full bottom-2 right-2',
                                  ]"
                                >
                                  <CheckIcon
                                    class="w-6 h-6 text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                              </span>

                              <span
                                :class="[
                                  active ? 'border' : 'border-2',
                                  checked
                                    ? 'border-green-500'
                                    : 'border-transparent',
                                  'absolute -inset-px rounded-sm pointer-events-none',
                                ]"
                                aria-hidden="true"
                              />
                            </div>
                          </RadioGroupOption>
                        </div>
                      </RadioGroup>

                      <div>
                        <label
                          for="sort"
                          class="block text-sm font-medium text-gray-900"
                          >Selecteer de sortering</label
                        >
                        <select
                          id="sort"
                          name="sort"
                          class="block w-full py-2 pl-3 pr-10 mt-4 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          v-model="selectedLogbookSortingOption"
                          :disabled="loading"
                        >
                          {{
                            selectedLogbookSortingOption
                          }}
                          <option
                            v-for="option in logbookSortingOptions"
                            :key="option.id"
                            :value="option"
                          >
                            {{ option.title }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div
              class="mt-8 space-y-4 sm:flex sm:flex-row-reverse gap-x-4 sm:space-y-0"
            >
              <button
                type="button"
                class="w-full btn btn--primary sm:w-auto disabled:opacity-80 disabled:hover:bg-primary"
                :disabled="loading"
                v-if="!generatedLogbookLink"
                @click="confirm()"
              >
                Genereer logboek
              </button>
              <a
                :href="generatedLogbookLink"
                target="_blank"
                v-if="generatedLogbookLink"
                class="inline-flex justify-center w-full md:w-fit btn btn--primary"
              >
                Download logboek
              </a>
              <button
                type="button"
                class="w-full btn btn--secondary sm:w-auto disabled:opacity-80"
                :disabled="loading"
                @click="cancel()"
              >
                Annuleren
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { ref } from 'vue';
  import { CheckIcon, ExclamationIcon } from '@heroicons/vue/solid';
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  } from '@headlessui/vue';

  const props = defineProps({
    open: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    project: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    generatedLogbookLink: {
      type: String,
      required: false,
      default: () => null,
    },
  });

  const emit = defineEmits(['cancel', 'confirm']);

  const logbookVariants = [
    {
      id: 1,
      title: 'Donker',
      theme: 'dark',
      image: require('../assets/images/dark.jpg'),
    },
    {
      id: 2,
      title: 'Wit',
      theme: 'light',
      image: require('../assets/images/light.jpg'),
    },
  ];

  const logbookSortingOptions = [
    {
      id: 1,
      title: 'Datum',
      value: 'date',
    },
    {
      id: 2,
      title: 'Etage',
      value: 'floor',
    },
    {
      id: 3,
      title: 'Positie',
      value: 'placement',
    },
    {
      id: 4,
      title: 'Spot status',
      value: 'status',
    },
    {
      id: 5,
      title: 'Status & Etage',
      value: 'status-floor',
    },
  ];

  const selectedLogbookSortingOption = ref(logbookSortingOptions[0]);
  const selectedLogbookVariant = ref(logbookVariants[0]);

  function cancel() {
    //make sure to set open prop to false in parent component
    emit('cancel');
  }

  function confirm() {
    //make sure to set open prop to false in parent component
    emit('confirm', {
      uuid: props.project.id,
      theme: selectedLogbookVariant.value.theme,
      sort: selectedLogbookSortingOption.value.value,
    });
  }
</script>
