<template>
  <div class="flex flex-col flex-1 gap-4">
    <div class="justify-end w-full gap-3 space-y-3 sm:space-y-0 sm:flex">
      <MarkerLog
        :marker="activeMarker"
        v-if="role === 'TECHNICIAN' || role === 'OWNER'"
      />
      <button
        v-if="role === 'TECHNICIAN' || role === 'OWNER'"
        @click="createMarker()"
        class="flex items-center w-full btn btn--primary sm:w-auto"
      >
        <LocationMarkerIcon
          class="w-6 h-6 mr-2 transition-all text-secondary"
          aria-hidden="true"
        />
        Spot toevoegen
      </button>
    </div>
    <div
      class="flex flex-1 min-h-0 p-2 overflow-auto bg-white rounded-lg shadow-md"
    >
      <div class="flex-1">
        <LMap
          ref="map"
          class="z-30 flex-1 bg-[url('@/assets/images/leaflet/tile.png')] bg-repeat"
          id="map"
          :zoom="zoom"
          :center="[0, 0]"
          :min-zoom="-2"
          :max-zoom="2"
          :zoom-delta="0.5"
          :zoom-animation="true"
          :marker-zoom-animation="true"
          @update:center="centerUpdated"
          @dragstart="toggleCrossHair(true)"
          @dragend="toggleCrossHair(false)"
          :crs="CRSPixel"
          :bounds="route.query.markerId ? [] : bounds"
          :options="{
            zoomControl: false,
          }"
        >
          <div
            class="relative w-full h-full transition-opacity map-crosshair pointer-events-none d-block bg-center bg-no-repeat bg-[length:150px] md:bg-[length:200px] z-[500] bg-[url('@/assets/images/leaflet/floorplan-crosshair.png')]"
            :class="showCrossHair ? 'opacity-100' : 'opacity-0'"
          ></div>
          <LImageOverlay :url="floorPlan" :bounds="bounds" />
          <LControl position="topleft">
            <button
              v-if="fullscreen"
              @click="toggleFullScreen"
              class="flex items-center gap-2 text-sm font-body"
            >
              <ChevronLeftIcon class="w-6 h-6" /> Terug
            </button>
          </LControl>
          <LControl position="topright">
            <div class="space-y-4">
              <template v-if="fullscreen">
                <div
                  v-if="role === 'TECHNICIAN' || role === 'OWNER'"
                  class="gap-3 space-y-3 sm:space-y-0 sm:flex"
                >
                  <MarkerLog :marker="activeMarker" />
                </div>
              </template>

              <div class="flex justify-end">
                <div
                  class="flex flex-col bg-white divide-y divide-gray-200 rounded-md shadow-lg w-11"
                >
                  <button
                    class="flex justify-center p-3 group disabled:cursor-not-allowed"
                    @click="
                      map.leafletObject.setZoom(map.leafletObject.getZoom() + 1)
                    "
                    title="Zoom in"
                    :disabled="map.leafletObject.getZoom() == map.maxZoom"
                  >
                    <svg
                      class="w-4 transition-colors fill-gray-500 group-disabled:fill-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                      />
                    </svg>
                  </button>
                  <button
                    class="flex justify-center p-3 group disabled:cursor-not-allowed"
                    @click="
                      map.leafletObject.setZoom(map.leafletObject.getZoom() - 1)
                    "
                    title="Zoom uit"
                    :disabled="map.leafletObject.getZoom() == map.minZoom"
                  >
                    <svg
                      class="w-4 fill-gray-500 group-disabled:fill-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"
                      />
                    </svg>
                  </button>
                  <button
                    class="flex justify-center p-3"
                    @click="toggleFullScreen"
                    title="Volledig scherm"
                  >
                    <template v-if="fullscreen">
                      <svg
                        class="w-4 fill-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M128 320H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32v-96C160 334.3 145.7 320 128 320zM416 320h-96c-17.69 0-32 14.31-32 32v96c0 17.69 14.31 32 32 32s32-14.31 32-32v-64h64c17.69 0 32-14.31 32-32S433.7 320 416 320zM320 192h96c17.69 0 32-14.31 32-32s-14.31-32-32-32h-64V64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96C288 177.7 302.3 192 320 192zM128 32C110.3 32 96 46.31 96 64v64H32C14.31 128 0 142.3 0 160s14.31 32 32 32h96c17.69 0 32-14.31 32-32V64C160 46.31 145.7 32 128 32z"
                        />
                      </svg>
                    </template>
                    <template v-else>
                      <svg
                        class="w-4 fill-gray-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M128 32H32C14.31 32 0 46.31 0 64v96c0 17.69 14.31 32 32 32s32-14.31 32-32V96h64c17.69 0 32-14.31 32-32S145.7 32 128 32zM416 32h-96c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32V64C448 46.31 433.7 32 416 32zM128 416H64v-64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96c0 17.69 14.31 32 32 32h96c17.69 0 32-14.31 32-32S145.7 416 128 416zM416 320c-17.69 0-32 14.31-32 32v64h-64c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c17.69 0 32-14.31 32-32v-96C448 334.3 433.7 320 416 320z"
                        />
                      </svg>
                    </template>
                  </button>
                  <button
                    class="flex justify-center p-3"
                    @click="resetMapCenter"
                    title="Centreer kaart"
                  >
                    <svg
                      class="w-4 fill-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M224 256C224 238.3 238.3 224 256 224C273.7 224 288 238.3 288 256C288 273.7 273.7 288 256 288C238.3 288 224 273.7 224 256zM256 0C273.7 0 288 14.33 288 32V42.35C381.7 56.27 455.7 130.3 469.6 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H469.6C455.7 381.7 381.7 455.7 288 469.6V480C288 497.7 273.7 512 256 512C238.3 512 224 497.7 224 480V469.6C130.3 455.7 56.27 381.7 42.35 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H42.35C56.27 130.3 130.3 56.27 224 42.35V32C224 14.33 238.3 0 256 0V0zM224 404.6V384C224 366.3 238.3 352 256 352C273.7 352 288 366.3 288 384V404.6C346.3 392.1 392.1 346.3 404.6 288H384C366.3 288 352 273.7 352 256C352 238.3 366.3 224 384 224H404.6C392.1 165.7 346.3 119.9 288 107.4V128C288 145.7 273.7 160 256 160C238.3 160 224 145.7 224 128V107.4C165.7 119.9 119.9 165.7 107.4 224H128C145.7 224 160 238.3 160 256C160 273.7 145.7 288 128 288H107.4C119.9 346.3 165.7 392.1 224 404.6z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </LControl>

          <LMarker
            v-for="(marker, index) in markers"
            :key="marker.id"
            :ref="
              (el) => {
                markerRefs[marker.id] = el;
              }
            "
            :draggable="moveableMarker == marker.id ? true : false"
            :lat-lng="[marker.latitude, marker.longitude]"
            @click="setActiveMarker(marker)"
            @dragend="saveMarkerLocation(marker, $event)"
          >
            <LIcon
              :icon-size="[25, 41]"
              :icon-anchor="[12.5, 41]"
              :icon-url="selectImage(marker)"
              :shadow-url="
                require(`/src/assets/images/leaflet/marker-shadow.png`)
              "
              :shadow-anchor="[12.5, 41]"
              :popup-anchor="[0, -36]"
            />
            <LPopup>
              <div
                class="flex gap-3 text-xs bg-gray-100 border-b border-gray-200"
              >
                <div
                  class="w-2"
                  :class="MarkerStatuses[marker.statusIndex].color"
                ></div>
                <div class="flex gap-3 py-2">
                  <div class="flex gap-3">
                    <span v-if="marker.deletePending" class="text-red-500">
                      Delete pending
                    </span>
                    <span v-else>
                      {{ marker.status }}
                    </span>
                  </div>
                  <div>
                    <strong>{{ marker.qrCode }}</strong>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-4 m-3 bg-white">
                <div class="col-span-5 md:col-span-3">
                  <img
                    v-if="marker.imageDownloadURL"
                    :src="marker.imageDownloadURL"
                    class="aspect-[16/9] w-full object-cover rounded-md border-gray-200 cursor-pointer bg-gray-200 h-full min-h-[3.5rem]"
                    @click="showMarkerImageModal(marker)"
                  />
                  <div
                    v-else
                    class="aspect-[16/9] w-full border border-gray-200 border-dashed rounded-md h-14 flex items-center justify-center"
                  >
                    <PhotographIcon class="w-8 h-8 text-gray-200" />
                  </div>
                </div>
                <div class="col-span-7 space-y-1 md:col-span-9">
                  <div class="flex flex-col justify-between md:flex-row">
                    <div><strong>Gemaakt op</strong></div>
                    <div class="text-gray-400">
                      {{ marker.createdDate }}
                    </div>
                  </div>
                  <div class="flex flex-col justify-between md:flex-row">
                    <div>
                      <strong>Laatst bewerkt op</strong>
                    </div>
                    <div class="text-gray-400">
                      {{ marker.editedDate }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col justify-end gap-3 m-3 md:items-center md:flex-row"
              >
                <button
                  @click="duplicateMarker(marker)"
                  class="flex items-center gap-2 p-1 text-xs btn bg-[#102b42] text-white w-full md:w-auto md:justify-center"
                  v-if="role === 'TECHNICIAN' || role === 'OWNER'"
                  :disabled="duplicationLoading"
                >
                  <div
                    class="flex items-center justify-center"
                    v-if="duplicationLoading"
                  >
                    <svg
                      class="w-5 h-5 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                  <template v-else>
                    <DocumentDuplicateIcon class="w-5 h-5 text-white" />
                    <span class="md:hidden">Dupliceren</span>
                  </template>
                </button>
                <button
                  @click="enableMoveMarker(marker)"
                  class="flex items-center gap-2 p-1 text-xs btn bg-[#102b42] text-white w-full md:w-auto md:justify-center"
                  v-if="role === 'TECHNICIAN' || role === 'OWNER'"
                >
                  <ArrowsExpandIcon class="w-5 h-5 text-white" />
                  <span>Verplaatsen</span>
                </button>
                <button
                  @click="openEditMarker(marker)"
                  class="flex items-center gap-2 p-1 text-xs btn btn--primary"
                  v-if="
                    (role === 'TECHNICIAN' && !fullscreen) ||
                    (role === 'OWNER' && !fullscreen)
                  "
                >
                  <PencilAltIcon class="w-5 h-5 text-secondary" />
                  <span>Bewerken</span>
                </button>
                <button
                  @click="removeMarker(marker)"
                  class="flex items-center gap-2 p-1 text-xs text-white bg-red-600 btn"
                  v-if="
                    (role === 'TECHNICIAN' && !fullscreen) ||
                    (role === 'OWNER' && !fullscreen)
                  "
                >
                  <TrashIcon class="w-5 h-5 text-white" />
                  <span>Verwijderen</span>
                </button>
              </div>
            </LPopup>
          </LMarker>
        </LMap>
      </div>
    </div>

    <TransitionRoot as="template" :show="markerImageModalOpen">
      <Dialog
        as="div"
        class="fixed inset-0 z-20 overflow-y-auto"
        @close="markerImageModalOpen = false"
      >
        <div class="flex justify-center min-h-screen text-center sm:block">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
            />
          </TransitionChild>

          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="relative inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-sm sm:p-6 md:max-w-4xl"
            >
              <div class="mt-5 md:mt-0 md:col-span-2">
                <img
                  v-if="activeMarker.imageDownloadURL"
                  :src="activeMarker.imageDownloadURL"
                  alt=""
                  class="mx-auto aspect-[16/12]"
                />
                <p class="text-center" v-else>
                  Er is nog geen foto toegevoegd aan deze marker
                </p>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="w-full btn btn--primary"
                  @click="markerImageModalOpen = false"
                >
                  Sluiten
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <EditMarker
      :markerEditModalOpen="open"
      :marker="editMarker"
      :projectID="projectID"
      :floorID="floorID"
      @closeEditModal="closeEditMarker()"
    />
  </div>
</template>
<script setup>
  import { ref, watch } from 'vue';
  import {
    LMap,
    LImageOverlay,
    LMarker,
    LPopup,
    LControl,
    LIcon,
  } from '@vue-leaflet/vue-leaflet';
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import { CRS } from 'leaflet';
  import {
    LocationMarkerIcon,
    PencilAltIcon,
    ChevronLeftIcon,
    TrashIcon,
    PhotographIcon,
    ArrowsExpandIcon,
    DocumentDuplicateIcon,
  } from '@heroicons/vue/outline';
  import { db } from '@/firebase/firebase.js';
  import {
    collection,
    addDoc,
    updateDoc,
    doc,
    setDoc,
    increment,
    query,
    orderBy,
    limit,
    getDocs,
  } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import moment from 'moment';
  import { v4 as uuidv4 } from 'uuid';
  import { MarkerStatuses } from './MarkerStatus';
  import EditMarker from '@/components/Marker/EditMarker.vue';
  import MarkerLog from '@/components/Marker/MarkerLog.vue';
  import { useRoute } from 'vue-router';
  import { useMarkersStore } from '@/stores/markers-store';
  import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
  import { storeToRefs } from 'pinia';
  import 'leaflet/dist/leaflet.css';

  const { localImages } = storeToRefs(useMarkersStore());
  const { fetchImages } = useMarkersStore();
  const connectionStatusStore = useConnectionStatusStore();

  //#region Map functionality
  const props = defineProps({
    markers: {
      type: Object,
      required: false,
      default: () => {},
    },
    floorPlan: {
      type: String,
      required: true,
      default: () => '',
    },
    projectID: {
      type: String,
      required: true,
      default: () => '',
    },
    floorID: {
      type: String,
      required: true,
      default: () => '',
    },
  });

  const emit = defineEmits(['removeMarker']);

  defineExpose({
    openEditMarker,
    openPopupMarker,
  });

  const map = ref();
  const CRSPixel = L.Util.extend(L.CRS.Simple, {
    transformation: new L.Transformation(1, 0, 1, 0),
  });
  const route = useRoute();
  const showCrossHair = ref(false);
  const zoom = ref(0);
  const width = ref(0);
  const height = ref(0);
  const markerPos = ref({ lat: 0, lng: 0 });
  const img = new Image();
  const bounds = ref([
    [0, 0],
    [height.value, width.value],
  ]);
  const loading = ref(false);
  const duplicationLoading = ref(false);
  const user = ref();
  const role = ref();
  const activeMarker = ref();
  const moveableMarker = ref();
  const markerImageModalOpen = ref(false);
  const markerRefs = ref({});
  const fullscreen = ref(false);

  const markerOfsetsMap = ref({
    '-2': 60,
    '-1': 50,
    0: 40,
    1: 20,
    2: 10,
  });

  function selectImage(marker) {
    // Retrieve the status object based on the marker's statusIndex
    const status = MarkerStatuses[marker.statusIndex];
    let iconKey = 'markerIcon'; // Default key

    if (!marker.category) {
      marker.category = 0;
    }

    // Checks if the marker is considered "movable" and returns the category-specific selected icon
    if (moveableMarker.value == marker.id) {
      switch (marker.category) {
        case 0: // Doorvoeringen/sparingen/Spots
          return (result = require(`/src/assets/images/leaflet/marker-selected-gap.png`));
        case 1: // Staalconstructie bekleden
          return (result = require(`/src/assets/images/leaflet/marker-selected-steel.png`));
        case 2: // Bouwkundig timmerwerk
          return (result = require(`/src/assets/images/leaflet/marker-selected-carpentry.png`));
        case 3: // Akoestisch / luchtdicht
          return (result = require(`/src/assets/images/leaflet/marker-selected-acoustically-airtight.png`));
      }
    } else {
      // Checks if the marker has a category and returns the category-specific selected icon
      if (marker.category !== undefined && marker.category !== null) {
        //Determine which icon to use based on the category
        switch (marker.category) {
          case 0: // Doorvoeringen/sparingen/Spots
            iconKey +=
              status.id === 0
                ? 'GapNieuweSpot'
                : status.id === 1
                ? 'GapInBehandeling'
                : status.id === 2
                ? 'GapGereed'
                : status.id === 3
                ? 'GapOpname'
                : status.id === 4
                ? 'GapNacontrole'
                : status.id === 5
                ? 'GapVerplaatst'
                : status.id === 6
                ? 'GapGedupliceerd'
                : '';
            break;
          case 1: // Staalconstructie bekleden
            iconKey +=
              status.id === 0
                ? 'SteelConstructionNieuweSpot'
                : status.id === 1
                ? 'SteelConstructionInBehandeling'
                : status.id === 2
                ? 'SteelConstructionGereed'
                : status.id === 3
                ? 'SteelConstructionOpname'
                : status.id === 4
                ? 'SteelConstructionNacontrole'
                : status.id === 5
                ? 'SteelConstructionVerplaatst'
                : status.id === 6
                ? 'SteelConstructionGedupliceerd'
                : '';
            break;
          case 2: // Bouwkundig timmerwerk
            iconKey +=
              status.id === 0
                ? 'CarpentryNieuweSpot'
                : status.id === 1
                ? 'CarpentryInBehandeling'
                : status.id === 2
                ? 'CarpentryGereed'
                : status.id === 3
                ? 'CarpentryOpname'
                : status.id === 4
                ? 'CarpentryNacontrole'
                : status.id === 5
                ? 'CarpentryVerplaatst'
                : status.id === 6
                ? 'CarpentryGedupliceerd'
                : '';
            break;
          case 3: // Akoestisch / luchtdicht
            iconKey +=
              status.id === 0
                ? 'AcousticallyAirtightNieuweSpot'
                : status.id === 1
                ? 'AcousticallyAirtightInBehandeling'
                : status.id === 2
                ? 'AcousticallyAirtightGereed'
                : status.id === 3
                ? 'AcousticallyAirtightOpname'
                : status.id === 4
                ? 'AcousticallyAirtightNacontrole'
                : status.id === 5
                ? 'AcousticallyAirtightVerplaatst'
                : status.id === 6
                ? 'AcousticallyAirtightGedupliceerd'
                : '';
            break;
        }
      }
    }
    // Dynamically import the image based on the constructed iconKey
    var result = require(`/src/assets/images/leaflet/${status[iconKey]}`);
    return result;
  }

  // watch for prop floorplan changes and get image width and height
  watch(
    () => props.floorPlan,
    () => {
      img.src = props.floorPlan;
      img.onload = function () {
        width.value = img.naturalWidth;
        height.value = img.naturalHeight;
        bounds.value = [
          [0, 0],
          [height.value, width.value],
        ];

        for (const marker of props.markers) {
          if (
            marker.isLegacy &&
            String(marker.isLegacy).toLowerCase() == 'true'
          ) {
            marker.latitude = img.naturalHeight * marker.latitude;
            marker.longitude = img.naturalWidth * marker.longitude;
            marker.isLegacy = false;
          }
        }
      };
    }
  );

  watch(
    () => props.markers,
    () => {
      if (img.naturalHeight !== 0) {
        for (const marker of props.markers) {
          if (
            marker.isLegacy &&
            String(marker.isLegacy).toLowerCase() == 'true'
          ) {
            marker.latitude = img.naturalHeight * marker.latitude;
            marker.longitude = img.naturalWidth * marker.longitude;
            marker.isLegacy = false;
          }
        }
      }
      // disable move marker
      moveableMarker.value = null;
    }
  );

  watch(
    () => route.query.markerId,
    () => {
      // Todo cleanup code below
      const limitedInterval = setInterval(() => {
        if (
          markerRefs.value[route.query.markerId] &&
          map.value &&
          props.markers
        ) {
          const marker = props.markers.filter(
            (marker) => marker.id === route.query.markerId
          )[0];

          openPopupMarker(marker);
          clearInterval(limitedInterval);
        }
      }, 100);
    },
    { immediate: true }
  );

  getAuth().onAuthStateChanged((u) => {
    user.value = u;

    if (user.value) {
      u.getIdTokenResult().then(function ({ claims }) {
        if (claims.role === 'OWNER') {
          role.value = 'OWNER';
        } else if (claims.role === 'CUSTOMER') {
          role.value = 'CUSTOMER';
        } else if (claims.role === 'TECHNICIAN') {
          role.value = 'TECHNICIAN';
        }
      });
    }
  });

  // when dragging map show crosshair in center
  function toggleCrossHair(show) {
    showCrossHair.value = show;
  }

  function centerUpdated(center) {
    markerPos.value = {
      lat: Math.floor(center.lat),
      lng: Math.floor(center.lng),
    };
  }

  function createMarker() {
    const generatedID = uuidv4();
    loading.value = true;

    const newMarker = {
      qrCode: '',
      room: '',
      placement: '',
      position: '',
      fireResistantRequirement: '',
      status: 'Open',
      statusIndex: 0,
      latitude: '0',
      longitude: '0',
      description: '',
      createdByName: user.value.displayName,
      createdByUid: user.value.uid,
      createdDate: moment().format('DD-MM-YYYY HH:mm'),
      editedBy: '',
      editedByUid: '',
      editedDate: '',
      remarks: '',
      floorID: props.floorID,
      projectID: props.projectID,
      _imagesCount: 0,
      timeStampAdded: new Date(),
      timeStampLastWorkedOn: '',
      category: 0,
    };

    newMarker.latitude = markerPos.value.lat;
    newMarker.longitude = markerPos.value.lng;

    const markersCollection = collection(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID,
      'markers'
    );

    setDoc(doc(markersCollection, generatedID), newMarker)
      .then(() => {})
      .catch((error) => {
        formError.value = 'Error';
      });

    const historyCollection = collection(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID,
      'markers',
      generatedID,
      'history'
    );

    const newHistoryEntry = {
      status: 'Open',
      statusIndex: 0,
      editedBy: user.value.displayName,
      editedByUid: user.value.uid,
      editedDate: moment().format('DD-MM-YYYY HH:mm'),
      timeStampAdded: new Date(),
    };

    addDoc(historyCollection, newHistoryEntry)
      .then(() => {})
      .catch((error) => {
        formError.value = 'Error';
      });

    const projectsRef = doc(db, 'projects', props.projectID);

    const project = {
      amountOfMarkers: increment(1),
      lastWorkedOnDate: moment().format('DD-MM-YYYY HH:mm'),
      timeStampLastWorkedOn: new Date(),
    };
    updateDoc(projectsRef, project)
      .then(() => {})
      .catch((error) => {
        formError.value = 'Error';
      });

    const floorRef = doc(
      db,
      'projects',
      props.projectID,
      'floors',
      props.floorID
    );

    const floor = {
      amountOfMarkers: increment(1),
    };
    updateDoc(floorRef, floor)
      .then(() => {})
      .catch((error) => {
        formError.value = 'Error';
      });

    loading.value = false;
    newMarker.id = generatedID;
    openEditMarker(newMarker);
  }

  async function setActiveMarker(marker) {
    // set data
    activeMarker.value = marker;
    if (!marker.imageDownloadURL) {
      const imagesCollection = collection(
        db,
        'projects',
        route.params.projectid,
        'floors',
        route.params.floorid,
        'markers',
        marker.id,
        'images'
      );

      const q = query(
        imagesCollection,
        orderBy('positionNumber', 'desc'),
        orderBy('timeStampAdded', 'desc'),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((image) => {
        if (!connectionStatusStore.online) {
          if (
            localImages.value.find(
              (localImage) => localImage.id === image.id
            ) !== undefined
          ) {
            marker.imageDownloadURL = localImages.value.find(
              (localImage) => localImage.id === image.id
            ).base64;
          }
        } else {
          marker.imageDownloadURL = image.data().imageDownloadURL;
        }
      });
    }

    map.value.leafletObject.flyTo(
      markerRefs.value[marker.id].leafletObject.getLatLng(),
      map.value.leafletObject.getZoom(),
      { animate: true, duration: 0.5 }
    );
  }

  function resetMapCenter() {
    map.value.leafletObject.setView(
      [height.value / 2, width.value / 2],
      map.value.minZoom
    );
  }

  //#endregion

  //#region Modals functionality

  const open = ref(false);
  const editMarker = ref({});
  const formError = ref('');

  function openEditMarker(marker) {
    editMarker.value = marker;
    open.value = true;
  }

  function openPopupMarker(marker) {
    setActiveMarker(marker);

    //open popup
    markerRefs.value[marker.id].leafletObject.openPopup();
  }

  function enableMoveMarker(marker) {
    // close all popups
    map.value.leafletObject.closePopup();

    moveableMarker.value = marker.id;
  }

  async function duplicateMarker(marker) {
    try {
      const generatedID = uuidv4();
      duplicationLoading.value = true;

      // Marker object to be uploaded
      var duplicateMarker = {
        ...marker,
      };

      delete duplicateMarker.imageDownloadURL;

      //Time stamp references should be reset/set to duplicate time stamp
      duplicateMarker.timeStampAdded = new Date();
      duplicateMarker.createdDate = moment().format('DD-MM-YYYY HH:mm');
      duplicateMarker.editedDate = '';
      duplicateMarker.timeStampLastWorkedOn = '';

      //Setting custom data for the duplicate
      var tempLongitude = duplicateMarker.longitude;

      duplicateMarker.longitude =
        parseInt(duplicateMarker.longitude) +
        markerOfsetsMap.value[map.value.leafletObject.getZoom()];

      //If for some reason longitude is NaN we default to using a harcoded offset
      if (isNaN(duplicateMarker.longitude)) {
        duplicateMarker.longitude = parseInt(tempLongitude) + 20;
      }

      duplicateMarker.qrCode = '';
      duplicateMarker.id = generatedID;

      duplicateMarker.status = 'Gedupliceerd van ' + marker.qrCode;
      duplicateMarker.statusIndex = 6;

      const markersCollection = collection(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID,
        'markers'
      );

      //Saving the new marker
      connectionStatusStore.online
        ? await setDoc(doc(markersCollection, generatedID), duplicateMarker)
        : setDoc(doc(markersCollection, generatedID), duplicateMarker);

      const oldMaterialsCollection = collection(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID,
        'markers',
        marker.id,
        'materials'
      );

      const newMaterialsCollection = collection(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID,
        'markers',
        generatedID,
        'materials'
      );

      //Gets materials docs, uploads them to the new marker
      const materialsResult = await getDocs(oldMaterialsCollection);

      for (var material of materialsResult.docs) {
        //Saving of materials
        connectionStatusStore.online
          ? await setDoc(
              doc(newMaterialsCollection, material.id),
              material.data()
            )
          : setDoc(doc(newMaterialsCollection, material.id), material.data());
      }

      const oldComponentsCollection = collection(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID,
        'markers',
        marker.id,
        'components'
      );

      const newComponentsCollection = collection(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID,
        'markers',
        generatedID,
        'components'
      );

      //Gets components docs, uploads them to the new marker
      const componentsResult = await getDocs(oldComponentsCollection);

      for (var component of componentsResult.docs) {
        //Saving of materials
        connectionStatusStore.online
          ? await addDoc(newComponentsCollection, component.data())
          : addDoc(newComponentsCollection, component.data());
      }

      const newHistoryCollection = collection(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID,
        'markers',
        generatedID,
        'history'
      );

      //Adds history entry for the duplication
      const newHistoryEntry = {
        status: 'Gedupliceerd van ' + marker.qrCode,
        statusIndex: 6,
        editedBy: user.value.displayName,
        editedByUid: user.value.uid,
        editedDate: moment().format('DD-MM-YYYY HH:mm'),
        timeStampAdded: new Date(),
      };

      connectionStatusStore.online
        ? await addDoc(newHistoryCollection, newHistoryEntry)
        : addDoc(newHistoryCollection, newHistoryEntry);

      //Updating the amount of markers and last worked on date on the project
      const projectsRef = doc(db, 'projects', props.projectID);

      const project = {
        amountOfMarkers: increment(1),
        lastWorkedOnDate: moment().format('DD-MM-YYYY HH:mm'),
        timeStampLastWorkedOn: new Date(),
      };

      connectionStatusStore.online
        ? await updateDoc(projectsRef, project)
        : updateDoc(projectsRef, project);

      //Updating the amount of markers on the floor
      const floorRef = doc(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID
      );

      const floor = {
        amountOfMarkers: increment(1),
      };

      connectionStatusStore.online
        ? await updateDoc(floorRef, floor)
        : updateDoc(floorRef, floor);
    } catch (e) {
      console.log(e);
      formError.value = 'Er is iets misgegaan';
    } finally {
      duplicationLoading.value = false;
      openPopupMarker(duplicateMarker);
    }
  }

  function removeMarker(marker) {
    emit('removeMarker', marker.id);
  }

  function saveMarkerLocation(marker, e) {
    var updateMarker = {
      longitude: Math.floor(e.target._latlng.lng),
      latitude: Math.floor(e.target._latlng.lat),
    };

    if (marker.hasOwnProperty('isLegacy')) {
      updateMarker.isLegacy = false;
    }

    updateDoc(
      doc(
        db,
        'projects',
        props.projectID,
        'floors',
        props.floorID,
        'markers',
        marker.id
      ),
      updateMarker
    )
      .then(() => {})
      .catch((error) => {
        formError.value = 'Error';
      });

    //Updating the last worked on date on the project
    const projectsRef = doc(db, 'projects', props.projectID);

    const project = {
      lastWorkedOnDate: moment().format('DD-MM-YYYY HH:mm'),
      timeStampLastWorkedOn: new Date(),
    };

    updateDoc(projectsRef, project);
  }

  function showMarkerImageModal(marker) {
    activeMarker.value = marker;
    markerImageModalOpen.value = true;
  }

  function closeEditMarker() {
    editMarker.value = {};
    open.value = false;
  }

  function toggleFullScreen() {
    const doc = document;
    const docEl = doc.getElementById('map');

    // request fullscreen for different browsers
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      } else if (docEl.msRequestFullscreen) {
        doc = window.top.document.body;
        docEl.msRequestFullscreen();
      }
    }
  }

  //listen for fullscreen change event and set variable
  [
    'fullscreenchange',
    'webkitfullscreenchange',
    'mozfullscreenchange',
    'msfullscreenchange',
  ].forEach((eventType) =>
    document.addEventListener(
      eventType,
      () => {
        fullscreen.value = !fullscreen.value;
      },
      false
    )
  );

  //#endregion
</script>
<style>
  .leaflet-container {
    @apply bg-transparent;
  }
  .leaflet-overlay-pane img {
    @apply rounded-lg shadow-md;
  }
  .leaflet-popup-content {
    @apply m-0 !w-72 md:!w-96;
  }
  .leaflet-popup-close-button {
    @apply !top-1;
  }
  .leaflet-popup-content-wrapper {
    @apply p-0 pb-px overflow-hidden rounded-md;
  }
  .leaflet-control-attribution {
    @apply hidden;
  }
</style>
