<template>
  <div>
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton
        class="flex items-center gap-2 md:ml-6 disabled:cursor-not-allowed group"
        :disabled="
          !connectionStatusStore.online || projectsPendingUpload.length === 0
        "
      >
        <span class="relative">
          <div
            v-if="
              !connectionStatusStore.online ||
              projectsPendingUpload.length === 0
            "
            class="w-7 h-0.5 bg-red-500 transform rotate-45 origin-center top-1/2 -translate-y-1/2 absolute -left-px z-10"
          ></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 text-gray-700 group-disabled:text-red-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <title>Uploaden</title>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>

          <span
            v-if="projectsPendingUpload.length > 0"
            class="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-[11px] font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 rounded-full"
            :class="
              !connectionStatusStore.online ? 'bg-red-500 ' : 'bg-green-500'
            "
          >
            {{ totalAmountOfImages }}
            <span
              class="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping z-[-1]"
              :class="
                !connectionStatusStore.online ? 'bg-red-500 ' : 'bg-green-500'
              "
            ></span>
          </span>
        </span>

        <span
          class="hidden text-sm text-gray-700 sm:flex group-disabled:text-gray-400"
          >Uploaden</span
        >
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="absolute right-0 z-50 max-w-md mt-3 sm:w-screen sm:max-w-md"
          v-slot="{ close }"
        >
          <div
            class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="relative bg-white">
              <div class="flex justify-between">
                <h3 class="p-3 mb-0">
                  <span class="text-sm font-normal">{{ title }}</span>
                </h3>
                <button
                  class="self-start p-2"
                  @click="close"
                  title="Sluiten"
                  aria-label="Sluiten"
                >
                  <XIcon class="w-5 h-5 text-gray-500" />
                </button>
              </div>

              <div class="overflow-y-auto divide-y divide-gray-300 max-h-52">
                <div
                  v-for="project in projectsPendingUpload"
                  class="flex items-center gap-3 px-3 py-2 text-xs sm:text-sm odd:bg-white even:bg-gray-50"
                >
                  <div>
                    <div class="py-2 truncate">
                      {{
                        projects
                          .find((comparer) => comparer.id === project.projectID)
                          .data().projectName
                      }}
                    </div>
                  </div>
                  <div class="flex gap-2 ml-auto">
                    <span>{{ project.imagesCount }}</span>
                    <template v-if="project.imagesCount === 1">
                      afbeelding
                    </template>
                    <template v-else> afbeeldingen </template>
                  </div>
                  <button
                    @click="confirm(project.projectID)"
                    class="flex items-center gap-2 p-1 text-xs btn btn--primary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5 text-secondary"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <title>Uploaden</title>
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                      />
                    </svg>
                    <span>Uploaden</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
  import { XIcon } from '@heroicons/vue/outline';
  import { db } from '@/firebase/firebase.js';
  import { collection, getDocsFromCache } from 'firebase/firestore';
  import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
  import { useProjectsStore } from '@/stores/projects-store';
  import { storeToRefs } from 'pinia';

  //import method for refreshing the pending projects
  const { fetchPendingUploadProjects } = useProjectsStore();

  //Create Ref to the projectsPendingUpload array in the store
  const { projectsPendingUpload, totalAmountOfImages } = storeToRefs(
    useProjectsStore()
  );

  //Fetch the newest data from the idb and the Ref will automatically update
  fetchPendingUploadProjects();
  retrieveCachedProjects();

  const connectionStatusStore = useConnectionStatusStore();
  const projects = ref();

  watch(
    () => connectionStatusStore.online,
    (isOnline) => {
      if (isOnline) {
        fetchPendingUploadProjects();
      }
    }
  );

  const props = defineProps({
    title: {
      type: String,
      required: true,
      default: '',
    },
  });

  async function retrieveCachedProjects() {
    const projectsCollection = collection(db, 'projects');
    const querySnapshot = await getDocsFromCache(projectsCollection);

    projects.value = querySnapshot.docs;
  }

  const emit = defineEmits(['projectUpload']);

  function confirm(projectID) {
    emit('projectUpload', projectID);
  }
</script>
