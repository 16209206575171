<template>
  <div>
    <form @submit.prevent='searchQRCode'>
      <label for='search' class='sr-only'>Zoek naar QR/LIN-code</label>
      <div
        class='flex mt-1 transition-all rounded-md shadow-sm'
        :class="{
          'w-[calc(100vw-89px)] sm:w-auto': globalState.searchBarExpanded,
        }"
      >
        <div
          class='relative flex items-stretch flex-grow shadow-sm focus-within:z-10'
        >
          <input
            id='search'
            name='search'
            v-model='search'
            class='block w-full px-4 py-2 leading-5 placeholder-gray-300 bg-white border border-gray-300 rounded-l-md sm:text-sm'
            :class="
              formError
                ? 'text-red-900 placeholder-red-400 border-red-400 focus:ring-red-500 focus:border-red-500'
                : 'focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary'
            "
            placeholder='Zoek naar LIN/QR-code'
            @focus='
              (formError = false),
                (globalState.searchBarExpanded = !globalState.searchBarExpanded)
            '
            @blur='
              globalState.searchBarExpanded = !globalState.searchBarExpanded
            '
            type='search'
          />
          <div
            class='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'
            v-if='formError'
          >
            <svg
              class='w-5 h-5 text-red-500'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
            >
              <path
                fill-rule='evenodd'
                d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z'
                clip-rule='evenodd'
              />
            </svg>
          </div>
        </div>
        <button
          @click='QRScanModalOpen = true'
          type='button'
          title='Scan QR/Lin-code'
          aria-label='Scan QR/Lin-code'
          class='relative inline-flex items-center px-2 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary'
        >
          <QrcodeIcon class='w-6 h-6 text-gray-400' aria-hidden='true' />
        </button>
        <button
          type='submit'
          title='Zoeken'
          aria-label='Zoeken'
          class='relative inline-flex items-center px-2 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary disabled:cursor-not-allowed'
          :disabled='!search.length'
        >
          <SearchIcon class='w-6 h-6 text-gray-400' aria-hidden='true' />
        </button>
      </div>
    </form>

    <QRScanModal
      :open='QRScanModalOpen'
      @scan-complete='setQRCode'
      @close-modal='QRScanModalOpen = false'
    />
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import { QrcodeIcon, SearchIcon } from '@heroicons/vue/outline';
  import QRScanModal from '@/components/QR/QRScanModal.vue';

  import { useRouter } from 'vue-router';
  import { db } from '@/firebase/firebase.js';
  import { collection, collectionGroup, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

  import { useUiStateComposable } from '@/composables/uistate-composable';
  import { getAuth } from 'firebase/auth';

  const { globalState } = useUiStateComposable();

  const search = ref('');
  const formError = ref(false);
  const router = useRouter();
  const project = ref(null);
  const QRScanModalOpen = ref(false);

  const user = ref();
  const role = ref();

  getAuth().onAuthStateChanged((u) => {
    user.value = u;
    if (user.value) {

      u.getIdTokenResult().then(function({ claims }) {
        if (claims.role === 'OWNER') {
          role.value = 'OWNER';
        } else if (claims.role === 'CUSTOMER') {
          role.value = 'CUSTOMER';
        } else if (claims.role === 'TECHNICIAN') {
          role.value = 'TECHNICIAN';
        }
      });
    }
  });

  function setQRCode(QRCode) {
    search.value = QRCode;

    QRScanModalOpen.value = false;

    searchQRCode();
  }

  async function searchQRCode() {
    //if customer
    if (role.value === 'CUSTOMER')
    {
      let projects = [];
      const markersArray = [];
      const userRef = doc(db, 'users', user.value.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const projectsCollection = collection(db, 'projects');
        const customerQuery = query(
          projectsCollection,
          where('customerID', '==', userSnap.data().customerID)
        );

        const querySnapshot = await getDocs(customerQuery);
        querySnapshot.forEach((doc) => {
          projects.push(doc);
        });

        const promises = [];

        querySnapshot.forEach((project) => {
          promises.push(retrieveAllFloorData(project));
        });

        await Promise.allSettled(promises).then((projects) => {
          for (var project of projects) {
            for (var floor of project.value.floors) {
              for (var marker of floor.markers) {
                markersArray.push(marker);
              }
            }
          }
        });


        let matchingMarker = undefined;

        for (let i = 0; i < markersArray.length; i++) {
          if (markersArray[i].data().qrCode === search.value) {
            matchingMarker = markersArray[i];
            break;
          }
        }

        if (matchingMarker !== undefined) {
          router.push({
            name: 'markers',
            params: {
              projectid: matchingMarker.data().projectID,
              floorid: matchingMarker.data().floorID
            },
            query: {
              qrCode: search.value,
              markerId: matchingMarker.id
            }
          });
          formError.value = false;
        } else {
          formError.value = true;
        }

      } else {
        formError.value = true;
      }
    }
    else {
      const projects = query(
        collectionGroup(db, 'markers'),
        where('qrCode', '==', search.value)
      );

      const querySnapshot = await getDocs(projects);

      project.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      // if QRcode is found open route to project page
      if (project.value.length) {
        router.push({
          name: 'markers',
          params: {
            projectid: project.value[0].projectID,
            floorid: project.value[0].floorID
          },
          query: {
            qrCode: search.value,
            markerId: project.value[0].id
          }
        });

        formError.value = false;
      } else {
        formError.value = true;
      }
    }

    async function retrieveAllFloorData(project) {
      var projectData = project.data();

      projectData.floors = [];
      const promises = [];

      //retrieve floors
      let floorsCollection = collection(
        db,
        'projects',
        project.id,
        'floors'
      );
      const querySnapshotFloors = await getDocs(floorsCollection);

      querySnapshotFloors.forEach((floor) => {
        promises.push(retrieveAllMarkerData(floor, project, floor));
      });


      await Promise.allSettled(promises).then((markers) => {
        for (var marker of markers) {
          projectData.floors.push(marker.value);
        }
      });
      return projectData;
    }

    async function retrieveAllMarkerData(floor, project) {
      var floorData = floor.data();

      floorData.markers = [];

      //retrieve markers
      let markersCollection = collection(
        db,
        'projects',
        project.id,
        'floors',
        floor.id,
        'markers'
      );

      const querySnapshotMarkers = await getDocs(markersCollection);

      querySnapshotMarkers.forEach((marker) => {
        floorData.markers.push(marker);
      });

      return floorData;
    }
  }
</script>
