// @ts-check
import { defineStore } from 'pinia';

export const useConnectionStatusStore = defineStore({
  id: 'Connection Status',
  state: () => {
    return {
      online: navigator.onLine,
    };
  },
  getters: {
    async getOnlineStatus(state) {
      return state.online;
    },
  },
  actions: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.online = type === 'online';
    },
  },
});
