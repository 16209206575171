<template>
  <nav class="flex px-4 py-6 sm:px-6 lg:px-8" aria-label="Breadcrumb">
    <ol
      role="list"
      class="inline-flex flex-wrap items-center text-xs sm:space-x-2 sm:text-sm"
    >
      <li>
        <div>
          <router-link to="/projects" class="text-gray-400 hover:text-gray-500">
            <HomeIcon
              class="flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5"
              aria-hidden="true"
            />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <li v-for="(breadcrumb, idx) in breadcrumbs" :key="idx">
        <div class="flex items-center">
          <ChevronRightIcon
            class="flex-shrink-0 w-3 h-3 text-gray-400 sm:w-5 sm:h-5"
            aria-hidden="true"
          />

          <div
            v-if="route.path === breadcrumb.link"
            class="font-medium text-gray-800 sm:ml-2 first-letter:uppercase"
          >
            {{ breadcrumb.text }}
          </div>

          <router-link
            v-else
            :to="breadcrumb.link"
            custom
            v-slot="{ navigate, href, isActive }"
          >
            <a
              :href="href"
              class="font-medium text-gray-500 sm:ml-2 hover:text-gray-700 first-letter:uppercase"
              @click="navigate"
              :aria-current="isActive ? 'page' : undefined"
            >
              {{ breadcrumb.text }}
            </a>
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import { ChevronRightIcon, HomeIcon } from '@heroicons/vue/solid';

  const route = useRoute();

  defineProps({
    breadcrumbs: {
      type: Array,
      required: false,
    },
  });
</script>
