<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Projecten',
          link: '/projects',
        },
        {
          text: 'Project toevoegen',
          link: '/project/create',
        },
      ]"
    />

    <div class="pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="mx-auto md:grid xl:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h1>Project toevoegen</h1>
            <p class="mt-1">Hier kun je een nieuw project toevoegen</p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form
            action="#"
            @submit.prevent="submit"
            :class="{ 'pointer-events-none': loading }"
          >
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                <div
                  class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6"
                >
                  <div class="sm:col-span-3">
                    {{ formError.code }}
                    <label
                      for="projectName"
                      class="block text-sm font-medium text-gray-700"
                      >Projectnaam</label
                    >
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="projectName"
                        id="projectName"
                        v-model="formData.projectName"
                        required
                        class="block w-full pr-10 border rounded-md shadow-sm appearance-none sm:text-sm focus:outline-none"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="projectName-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="sm:col-span-3">
                    <label
                      for="projectNumber"
                      class="block text-sm font-medium text-gray-700"
                      >Projectnummer</label
                    >
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="projectNumber"
                        id="projectNumber"
                        v-model="formData.projectNumber"
                        required
                        class="block w-full pr-10 border rounded-md shadow-sm appearance-none sm:text-sm focus:outline-none"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="projectNumber-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="sm:col-span-6">
                    <label
                      for="customer"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Klant
                    </label>
                    <div class="mt-1">
                      <select
                        v-model="formData.customer"
                        id="customer"
                        name="customer"
                        autocomplete="customer"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                      >
                        <option value="" disabled hidden>
                          Selecteer een klant
                        </option>
                        <option
                          v-for="(
                            customer, index
                          ) in formData.availableCustomers"
                          :key="index"
                          :value="customer"
                        >
                          {{ customer.data().customerName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  <h3>Project adres</h3>
                </div>
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div class="sm:col-span-6">
                    <label
                      for="address"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Adres
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="address"
                        id="address"
                        v-model="formData.address"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="address-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="zipCode"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Postcode
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="zipCode"
                        id="zipCode"
                        v-model="formData.zipCode"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="zipCode-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="city"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Plaats
                    </label>
                    <div class="mt-1">
                      <input
                        type="text"
                        name="city"
                        id="city"
                        v-model="formData.city"
                        class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="city-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h3>Media</h3>
                  <label
                    for="cover-photo"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Afbeelding
                  </label>
                  <div
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="drop"
                    class="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md"
                  >
                    <div class="space-y-1 text-center">
                      <svg
                        class="w-12 h-12 mx-auto text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p class="pl-1 m-0">
                        {{ formData.imagePlaceHolderName }}
                      </p>
                      <div
                        class="flex justify-center py-2 text-sm text-gray-600"
                      >
                        <label
                          for="image"
                          class="relative font-medium text-primary bg-white rounded-md cursor-pointer hover:text-primary-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-dark"
                        >
                          <span>Een afbeelding uploaden</span>
                          <input
                            @change="onFileChange"
                            id="image"
                            name="image"
                            type="file"
                            class="sr-only"
                          />
                        </label>
                        <p class="pl-1 m-0">of slepen en neerzetten</p>
                      </div>
                      <p class="text-xs text-gray-500">
                        Zorg dat de afbeelding een 16:9 verhouding heeft met een
                        minimale afmeting van 1600x900 pixels
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="formError" class="p-4 rounded-md bg-red-50">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="mb-0 text-sm font-medium text-red-800">
                        {{ formError }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
                <div class="flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="router.back()"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Annuleren
                  </button>
                  <Button type="submit" :loading="loading">
                    <template #text>Toevoegen</template>
                    <template #loading-text>Verwerken...</template>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { db, storage } from '@/firebase/firebase.js';
  import { collection, addDoc, getDocs, query, where, orderBy } from 'firebase/firestore';
  import {
    uploadBytes,
    getDownloadURL,
    ref as firebaseRef,
  } from 'firebase/storage';
  import { v4 as uuidv4 } from 'uuid';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import Button from '../../components/Button.vue';
  import Compress from 'compress.js';

  const router = useRouter();
  const route = useRoute();
  const loading = ref(false);
  const compress = ref(new Compress());

  const formData = ref({
    projectName: '',
    projectNumber: '',
    availableCustomers: [],
    customer: '',
    customerID: '',
    customerContactName: '',
    customerName: '',
    address: '',
    zipCode: '',
    city: '',
    imageStorageID: '',
    imageDownloadURL: '',
    amountOfMarkers: 0,
    imagePlaceHolderName: 'Geen afbeelding gekozen',
  });

  const imageData = ref('');
  const formError = ref('');

  function submit() {
    loading.value = true;
    if (imageData.value) {
      createProjectWithImage();
    } else {
      createProject();
    }
  }

  getCustomers();

  async function getCustomers() {
    const customersCollection = collection(db, 'customers');
    const customerQuery = query(
      customersCollection,
      orderBy('customerName')
    );
    const querySnapshot = await getDocs(customerQuery);
    querySnapshot.forEach((doc) => {
      formData.value.availableCustomers.push(doc);
    });
  }

  function createProject() {
    if (formData.value.customer !== '') {
      formData.value.customerID = formData.value.customer.id;
      formData.value.customerContactName =
        formData.value.customer.data().customerContactName;
        formData.value.customerName = formData.value.customer.data().customerName;
    }

    const projectsCollection = collection(db, 'projects');

    addDoc(projectsCollection, {
      projectName: formData.value.projectName,
      projectNumber: formData.value.projectNumber,
      customerID: formData.value.customerID,
      customerContactName: formData.value.customerContactName,
      customerName: formData.value.customerName,
      address: formData.value.address,
      zipCode: formData.value.zipCode,
      city: formData.value.city,
      imageStorageID: formData.value.imageStorageID,
      imageDownloadURL: formData.value.imageDownloadURL,
      amountOfMarkers: formData.value.amountOfMarkers,
    })
      .then((result) => {
        router.back();
      })
      .catch((error) => {
        switch (error.code) {
          case 'unauthenticated': {
            formError.value =
              'U bent niet gemachtigd om de opgegeven bewerking uit te voeren';
            break;
          }
          case 'permission-denied': {
            formError.value =
              'U bent niet gemachtigd om de opgegeven bewerking uit te voeren';
            break;
          }
        }
      })
      .finally(() => {
        loading.value = false;
      });
  }

  function createProjectWithImage() {
    const uuid = uuidv4();
    const storageRef = firebaseRef(storage, 'projects/' + uuid);

    uploadBytes(storageRef, imageData.value).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        formData.value.imageDownloadURL = downloadURL;
        formData.value.imageStorageID = uuid;
        createProject();
      });
    });
  }

  function onFileChange(e) {
    const files = [...(e.target.files || e.dataTransfer.files)];
    if (!files.length) return;
    compress.value
      .compress(files, {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1600, // the max width of the output image, defaults to 1920px
        maxHeight: 1200, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: true, // Enables rotation, defaults to false
      })
      .then((results) => {
        // returns an array of compressed images
        const img1 = results[0];
        const base64str = img1.data;
        const imgExt = img1.ext;
        const file = Compress.convertBase64ToFile(base64str, imgExt);
        formData.value.imagePlaceHolderName = img1.alt;
        imageData.value = file;
      });
  }

  function dragleave(e) {
    e.currentTarget.classList.add('border-gray-300');
    e.currentTarget.classList.remove('border-blue-300');
  }

  function dragover(e) {
    e.preventDefault();
    if (!e.currentTarget.classList.contains('border-blue-300')) {
      e.currentTarget.classList.remove('border-gray-300');
      e.currentTarget.classList.add('border-blue-300');
    }
  }

  function drop(e) {
    e.preventDefault();
    onFileChange(e);
    // Clean up
    e.currentTarget.classList.add('border-gray-300');
    e.currentTarget.classList.remove('border-blue-300');
  }
</script>
