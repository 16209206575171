import { initializeApp } from 'firebase/app';
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require('firebase/app-check');
import {
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  enableMultiTabIndexedDbPersistence,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { createToaster } from '@meforma/vue-toaster';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { markRaw } from 'vue';

//#region Firebase Config and initialization

//Firebase config
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_CONFIG_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_CONFIG_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_CONFIG_APPID,
};

//Initalizing Firebase and all modules
const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.

if (process.env.VUE_APP_ENV === 'prod') {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      process.env.VUE_APP_FIREBASE_CONFIG_RECAPTCHA_SITEKEY
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}

export const functions = getFunctions(app, 'europe-west3');

//Used for local functions testing
//Connects to local functions (local functions needs to be started within the linisol-cloud folder - the README.md explains how to start this server)
if (process.env.VUE_APP_ENV === 'dev') {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

export const db = markRaw(initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
}));

const auth = getAuth();
export const storage = getStorage();

enableMultiTabIndexedDbPersistence(db).catch((err) => {
  if (err.code == 'failed-precondition') {
  } else if (err.code == 'unimplemented') {
  }
});

//#endregion

//#region Authentication

//Current user
export async function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = getAuth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

//#endregion

//#region Utility
function showToaster(message, type, duration) {
  const toaster = createToaster({ duration: duration, type: type });
  toaster.show(message);
}

//#endregion
