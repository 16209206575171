// @ts-check
import { defineStore } from 'pinia';
import idb from '@/api/idb';

export const useMarkersStore = defineStore({
  id: 'Markers',
  state: () => {
    return {
      localImages: [],
    };
  },
  getters: {
    async getImages(state) {
      return state.localImages;
    },
  },
  actions: {
    async setImages() {
      this.localImages = await idb.getMarkerImages();
    },
    async setImagesByProjectID(projectID) {
      this.localImages = await idb.getMarkerImagesByProjectID(projectID);
    },
    async fetchPendingImagesByProjectID(projectID) {
      return await idb.getPendingMarkerImagesByProjectID(projectID);
    },
    async fetchPendingImages() {
      return await idb.getPendingMarkerImages();
    },
    async saveImage(image) {
      await idb.saveMarkerImage(image);
      return true;
    },
    async updateImageUploaded(id, dataLocation) {
      await idb.updateUploaded(id, dataLocation);
      return true;
    },
    async updateImageDeletePending(image, markerID, floorID, projectID) {
      await idb.updateMarkerDeletePending(image, markerID, floorID, projectID);
      return true;
    },
    async deleteImage(id, dataLocation) {
      return await idb.deleteImage(id, dataLocation);
    },
    async deleteImages(idArray) {
      return await idb.deleteImages(idArray);
    },
  },
});
