<template>
  <div class="flex flex-col flex-1 w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Projecten',
          link: '/projects',
        },
        {
          text: `Etages van ${
            project != undefined
              ? project.projectName + ' ' + project.projectNumber
              : '...'
          }`,
          link: `/project/${route.params.projectid}/floors`,
        },
        {
          text: `Spot van ${
            Object.keys(floor).length != 0 ? floor.floorName : '...'
          }`,
          link: route.path,
        },
      ]"
    />
    <div
      class="relative z-0 flex flex-col flex-1 flex-grow lg:overflow-hidden lg:flex-row"
    >
      <main
        class="relative flex-1 focus:outline-none lg:order-last h-[300px] lg:h-auto"
      >
        <!-- Start main area-->
        <div
          class="flex flex-col w-full h-screen px-4 py-4 sm:py-6 lg:absolute sm:px-6 lg:px-8 lg:h-full"
        >
          <div class="flex flex-1">
            <Map
              v-show="backgroundFound"
              ref="map"
              :markers="markers"
              :projectID="route.params.projectid"
              :floorID="route.params.floorid"
              :floor-plan="floor.imageDownloadURL"
              @remove-marker="removeMarker"
            />
            <div
              v-show="!backgroundFound"
              class="flex justify-center w-full h-full px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md"
            >
              <div class="space-y-1 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-16 h-16 mx-auto text-yellow-200"
                  fill="none"
                  viewBox="0 0 48 48"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>

                <div class="flex justify-center py-2 text-sm text-gray-600">
                  <p v-if="connectionStatusStore.online">
                    Deze etage is offline aangemaakt en de interactieve map zal
                    pas worden weergegeven zodra de achtergrond is geüpload door
                    de monteur.
                  </p>
                  <p v-else>
                    Deze afbeelding is niet gedownload en zal pas worden
                    weergegeven zodra je weer online bent.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End main area -->
      </main>
      <aside
        class="relative flex-shrink-0 border-r border-gray-200 lg:flex lg:flex-col lg:flex-1 xl:flex-none w-full xl:w-[640px] h-auto sm:h-[40vh] lg:h-full"
      >
        <!-- Start first column  -->
        <div
          class="flex flex-col w-full h-full gap-4 px-0 py-6 md:absolute sm:px-6 lg:px-8"
        >
          <!-- <div class="flex-shrink-0">
            <SearchWidget />
          </div> -->
          <div class="flex flex-1 sm:overflow-y-auto">
            <TanstackTable
              :columns="[
                { accessorKey: 'id', header: 'ID', show: false },
                {
                  accessorKey: 'editedDate',
                  header: 'Laatst bewerkt',
                },
                {
                  header: 'statusHidden',
                  show: false,
                  accessorFn: (row) => getStatusName(row.statusIndex),
                  accessorKey: 'id',
                },
                {
                  accessorKey: 'statusIndex',
                  header: 'Status',
                  cell: (cellProps) =>
                    h(Status, { statusId: cellProps.getValue() }),
                },
                {
                  accessorKey: 'qrCode',
                  header: 'QR',
                },

                {
                  accessorKey: 'timeStampLastWorkedOn',
                  header: 'Laatst bewerkt',
                  cell: (info) =>
                    info.getValue()
                      ? moment(info.getValue().toDate()).format(
                          'DD-MM-YYYY HH:mm'
                        )
                      : undefined,
                  sortingFn: 'datetime',
                  sortUndefined: -1,
                },
                
                
                {
                  accessorKey: 'actions',
                  header: 'Acties',
                  enableSorting: false,
                  cell: () => 'actions',
                },
              ]"
              :initial-sort="[{ id: 'timeStampLastWorkedOn', desc: true }]"
              :data="markers"
              :actions="actions"
              :default-page-size="20"
              @view="viewMarker"
              @edit="editMarker"
              @remove="removeMarker"
            />
          </div>
        </div>
        <!-- End first column -->
      </aside>
    </div>
    <ConfirmationModal
      :open="confirmModalData.open"
      :title="confirmModalData.title"
      :body="confirmModalData.body"
      :loading="confirmModalData.loading"
      :cancelButtonText="confirmModalData.cancelButtonText"
      :confirmButtonText="confirmModalData.confirmButtonText"
      @cancel="onCancelDelete"
      @confirm="onConfirmDelete"
    />
  </div>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import Map from '@/components/Map/Map.vue';
  import Status from '@/components/Status.vue';
  import BreadCrumbs from '@/components/BreadCrumbs.vue';
  import {
    onSnapshot,
    collection,
    doc,
    getDoc,
    orderBy,
    where,
    query,
    getDocs,
    deleteDoc,
    increment,
    updateDoc,
    setDoc,
  } from 'firebase/firestore';
  import { ref, onUnmounted, h } from 'vue';
  import { ref as firebaseRef, deleteObject } from 'firebase/storage';
  import { db, storage } from '@/firebase/firebase.js';
  import ConfirmationModal from '../../components/ConfirmationModal.vue';
  import { useFloorsStore } from '@/stores/floors-store';
  import { useMarkersStore } from '@/stores/markers-store';
  import { useConnectionStatusStore } from '@/stores/connectionStatus-store';
  import { storeToRefs } from 'pinia';
  import { getAuth } from 'firebase/auth';
  import { useDynamicDataStore } from '@/stores/dynamic-data-store';
  import TanstackTable from '../../components/TanstackTable.vue';
  import moment from 'moment';
  import { MarkerStatuses } from '@/components/Map/MarkerStatus';

  const { localImages } = storeToRefs(useFloorsStore());
  const { fetchImages } = useFloorsStore();

  fetchImages();

  const { fetchDynamicData } = useDynamicDataStore();

  fetchDynamicData();

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: 'Spot verwijderen',
    body: 'Weet je zeker dat je deze spot wilt verwijderen? Alle gegevens van deze spot zullen permanent van onze servers worden verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
    loading: false,
  });

  const route = useRoute();
  const markers = getReactiveMarkers();
  const project = ref(null);
  const map = ref();
  const activeDeleteUuid = ref();
  const connectionStatusStore = useConnectionStatusStore();
  const floor = getReactiveFloor();

  const user = ref();
  const role = ref();
  const actions = ref([]);
  const backgroundFound = ref(true);

  getAuth().onAuthStateChanged((u) => {
    user.value = u;

    if (user.value) {
      u.getIdTokenResult().then(function ({ claims }) {
        if (claims.role === 'OWNER') {
          role.value = 'OWNER';
          actions.value = [
            { id: 'view', name: 'Bekijken' },
            { id: 'edit', name: 'Bewerken' },
            { id: 'remove', name: 'Verwijderen' },
          ];
        } else if (claims.role === 'CUSTOMER') {
          role.value = 'CUSTOMER';
          actions.value = [{ id: 'view', name: 'Bekijken' }];
        } else if (claims.role === 'TECHNICIAN') {
          role.value = 'TECHNICIAN';
          actions.value = [
            { id: 'view', name: 'Bekijken' },
            { id: 'edit', name: 'Bewerken' },
            { id: 'remove', name: 'Verwijderen' },
          ];
        }
      });
    }
  });

  getProject();

  function editMarker(uuid) {
    const marker = markers.value.filter((marker) => marker.id === uuid)[0];
    map.value.openEditMarker(marker);
  }

  function viewMarker(uuid) {
    const marker = markers.value.filter((marker) => marker.id === uuid)[0];
    map.value.openPopupMarker(marker);
  }

  async function getProject() {
    const projectRef = doc(db, 'projects', route.params.projectid);
    const projectSnap = await getDoc(projectRef);

    projectSnap.exists ? (project.value = projectSnap.data()) : null;
  }

  function getReactiveFloor() {
    const floor = ref({});
    const unsubscribe = onSnapshot(
      doc(
        db,
        'projects',
        route.params.projectid,
        'floors',
        route.params.floorid
      ),
      (doc) => {
        floor.value = doc.data();
        if (
          isNullOrWhitespace(floor.value.imageDownloadURL) ||
          !connectionStatusStore.online
        ) {
          if (
            localImages.value.find(
              (localImage) => localImage.id === route.params.floorid
            ) !== undefined
          ) {
            floor.value.imageDownloadURL = localImages.value.find(
              (localImage) => localImage.id === route.params.floorid
            ).base64;
            backgroundFound.value = true;
          } else {
            backgroundFound.value = false;
          }
        } else {
          backgroundFound.value = true;
        }
      }
    );
    onUnmounted(unsubscribe);
    return floor;
  }

  function getReactiveMarkers() {
    const markersCollection = collection(
      db,
      'projects',
      route.params.projectid,
      'floors',
      route.params.floorid,
      'markers'
    );
    const q = query(markersCollection, orderBy('timeStampAdded', 'desc'));

    const markers = ref([]);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      var temp = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const result = temp.filter((el) => !el.isSoftDeleted);
      markers.value = result;
    });
    onUnmounted(unsubscribe);

    return markers;
  }

  function removeMarker(uuid) {
    confirmModalData.value.loading = false;
    activeDeleteUuid.value = uuid;
    confirmModalData.value.open = true;
  }

  function onCancelDelete() {
    confirmModalData.value.open = false;
  }

  async function onConfirmDelete() {
    confirmModalData.value.loading = true;

    //Soft deletes the marker
    const markerRef = doc(
      db,
      'projects',
      route.params.projectid,
      'floors',
      route.params.floorid,
      'markers',
      activeDeleteUuid.value
    );

    connectionStatusStore.online
      ? await updateDoc(markerRef, {
          isSoftDeleted: true,
          deletedByName: user.value.displayName,
          deletedByUid: user.value.uid,
          deletedDate: moment().format('DD-MM-YYYY HH:mm'),
          timeStampDeleted: new Date(),
        })
      : updateDoc(markerRef, {
          isSoftDeleted: true,
          deletedByName: user.value.displayName,
          deletedByUid: user.value.uid,
          deletedDate: moment().format('DD-MM-YYYY HH:mm'),
          timeStampDeleted: new Date(),
        });

    //Increments the amount of markers on the floor by -1
    const floorsRef = doc(
      db,
      'projects',
      route.params.projectid,
      'floors',
      route.params.floorid
    );

    const floor = {
      amountOfMarkers: increment(-1),
    };

    //Increments the amount of markers on the project by -1
    connectionStatusStore.online
      ? await updateDoc(floorsRef, floor)
      : updateDoc(floorsRef, floor);

    const projectsRef = doc(db, 'projects', route.params.projectid);

    const project = {
      amountOfMarkers: increment(-1),
    };

    connectionStatusStore.online
      ? await updateDoc(projectsRef, project)
      : updateDoc(projectsRef, project);

    confirmModalData.value.open = false;
  }

  async function imageDeleteOffline(image, markerID) {
    await useMarkersStore().updateImageDeletePending(
      image,
      markerID,
      route.params.floorid,
      route.params.projectid
    );
  }

  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  function getStatusName(id) {
    return MarkerStatuses.find((element) => element.id === id).name;
  }
</script>
