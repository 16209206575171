<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Klanten',
          link: route.path,
        },
      ]"
    />
    <div class="px-4 pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Klanten</h1>
          <p class="mt-2 text-sm text-gray-700">
            Een overzicht van alle klanten
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            @click="router.push({ name: 'customerCreate' })"
            class="btn btn--primary"
          >
            Nieuwe klant
          </button>
        </div>
      </div>
      <div class="flex flex-col mt-8">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <TanstackTable
                :columns="[
                  { accessorKey: 'id', header: 'ID', show: false },
                  {
                    accessorKey: 'customerName',
                    header: 'Naam',
                  },
                  {
                    accessorKey: 'customerAddress',
                    header: 'Adres',
                  },
                  {
                    accessorKey: 'customerPlace',
                    header: 'Plaats',
                  },
                  {
                    accessorKey: 'customerContactName',
                    header: 'Contactpersoon',
                  },
                  {
                    accessorKey: 'customerContactEmailAddress',
                    header: 'E-mail',
                  },
                  {
                    accessorKey: 'customerContactPhoneNumber',
                    header: 'Telefoonnummer',
                  },

                  {
                    accessorKey: 'actions',
                    header: 'Acties',
                    enableSorting: false,
                    cell: () => 'actions',
                  },
                ]"
                 :initial-sort="[{ id: 'customerName', desc: false }]"
                :data="customers"
                :actions="[
                  { id: 'edit', name: 'Bewerken' },
                  { id: 'remove', name: 'Verwijderen' },
                ]"
                @edit="editCustomer"
                @remove="deleteCustomer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :open="confirmModalData.open"
      :title="confirmModalData.title"
      :body="confirmModalData.body"
      :loading="confirmModalData.loading"
      :cancelButtonText="confirmModalData.cancelButtonText"
      :confirmButtonText="confirmModalData.confirmButtonText"
      @cancel="onCancelDelete"
      @confirm="onConfirmDelete"
    />
  </div>
</template>

<script setup>
  import { ref, onUnmounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { db } from '@/firebase/firebase.js';
  import {
    doc,
    onSnapshot,
    collection,
    deleteDoc,
    getDocs,
    updateDoc,
    query,
    where,
  } from 'firebase/firestore';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import TanstackTable from '../../components/TanstackTable.vue';
  import ConfirmationModal from '../../components/ConfirmationModal.vue';

  //confirmModal
  const confirmModalData = ref({
    open: false,
    title: 'Klant verwijderen',
    body: 'Weet je zeker dat je deze klant wilt verwijderen? Alle gegevens van deze klant worden voor altijd permanent van onze servers verwijderd. Deze actie kan niet ongedaan worden gemaakt.',
    cancelButtonText: 'Annuleren',
    confirmButtonText: 'Bevestigen',
    loading: false,
  });

  const customers = getReactiveCustomers();
  const router = useRouter();
  const route = useRoute();
  const activeDeleteUuid = ref();

  function getReactiveCustomers() {
    const customersCollection = collection(db, 'customers');
    const customers = ref([]);
    const unsubscribe = onSnapshot(customersCollection, (querySnapshot) => {
      customers.value = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    });
    onUnmounted(unsubscribe);
    return customers;
  }

  function editCustomer(uuid) {
    router.push({
      name: 'customerEdit',
      params: {
        id: uuid,
      },
    });
  }

  async function deleteCustomer(uuid) {
    confirmModalData.value.loading = false;
    activeDeleteUuid.value = uuid;
    confirmModalData.value.open = true;
  }

  function onCancelDelete() {
    confirmModalData.value.open = false;
  }

  async function onConfirmDelete() {
    confirmModalData.value.loading = true;
    //delete the material
    const customerRef = doc(db, 'customers', activeDeleteUuid.value);

    deleteDoc(customerRef)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });

    const projectsCollection = collection(db, 'projects');

    const q = query(
      projectsCollection,
      where('customerID', '==', activeDeleteUuid.value)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((project) => {
      const projectRef = doc(db, 'projects', project.id);

      updateDoc(projectRef, {
        customerID: '',
        customerContactName: '',
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });
    confirmModalData.value.open = false;
  }
</script>
