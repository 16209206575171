<template>
  <div
    v-if="selectedStatus"
    :class="[, 'cursor-default select-none relative ']"
  >
    <div class="flex items-center">
      <span
        :class="[
          selectedStatus.color,
          'flex-shrink-0 inline-block h-3 w-3 rounded-md',
        ]"
        aria-hidden="true"
      />
      <span :class="[, 'ml-3 block truncate']">
        {{ selectedStatus.name }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import { MarkerStatuses } from '@/components/Map/MarkerStatus';
  import { computed, defineProps } from 'vue';
  const props = defineProps({
    statusId: {
      type: Number,
      required: true,
    },
  });

  //Check if value of status is 'Goedgekeurd' this changes the validation
  const selectedStatus = computed(() => {
    return MarkerStatuses.find((element) => element.id === props.statusId);
  });
</script>
