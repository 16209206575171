<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 z-20 overflow-y-auto"
      @close="closeModal"
    >
      <div class="flex justify-center min-h-screen text-center sm:block">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          />
        </TransitionChild>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl sm:rounded-lg sm:my-8 sm:align-middle sm:max-w-sm sm:p-6 md:max-w-4xl"
          >
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Scan QR code
                </DialogTitle>
                <div class="mt-2">
                  {{ displayError }}

                  <QrCodeScanner :open="open" @result="onScanSucces" />
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button class="w-full btn btn--primary" @click="closeModal">
                Sluiten
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { ref } from 'vue';
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import QrCodeScanner from '@/components/QR/QrCodeScanner.vue';
  const displayError = ref('');
  const confirmationSound = require('@/assets/audio/confirm.mp3');

  const props = defineProps({
    open: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  });

  const emit = defineEmits(['close-modal', 'scan-complete']);

  function onScanSucces(result) {
    if (result) {
      //play confirmation sound
      new Audio(confirmationSound).play();
      // remove URL from QR code
      // `URL:http://www.linisol.eu/025745` -> LIN025745
      if (isURL(result) && result.includes('linisol.eu')) {
        result = 'LIN' + result.split('/').pop();
      } else {
        result = result.split('/').pop().replace(';;', '').toUpperCase();
      }

      // emit scan with result
      emit('scan-complete', result);
    }
  }

  function closeModal() {
    emit('close-modal');
  }

  function isURL(str) {
    var pattern = new RegExp(
      '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
      'i'
    ); // fragment locator
    return pattern.test(str);
  }
</script>

<style scoped></style>
