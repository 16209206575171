<template>
  <div class="w-full mx-auto max-w-screen-2xl">
    <BreadCrumbs
      :breadcrumbs="[
        {
          text: 'Gebruikers',
          link: '/users',
        },
        {
          text: 'Gebruiker bewerken',
          link: route.path,
        },
      ]"
    />

    <div class="pt-6 pb-12 sm:px-6 lg:px-8">
      <div class="mx-auto md:grid xl:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h1>Wachtwoord wijzigen</h1>
            <p class="mt-1">
              Hier kun je het wachtwoord wijzigen van "{{ user.userFullName }}"
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form
            action="#"
            @submit.prevent="submit"
            :class="{ 'pointer-events-none': loading }"
          >
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                <div>
                  <h3>Wachtwoord</h3>
                </div>
                <div
                  class="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6"
                >
                  <div class="col-span-full">
                    {{ formError.code }}
                    <label
                      for="userPassword"
                      class="block text-sm font-medium text-gray-700"
                      >Nieuw wachtwoord</label
                    >
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="password"
                        name="userPassword"
                        id="userPassword"
                        v-model="formData.userPassword"
                        required
                        class="block w-full pr-10 border rounded-md shadow-sm appearance-none sm:text-sm focus:outline-none"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="userPassword-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-full">
                    <label
                      for="userPasswordCheck"
                      class="block text-sm font-medium text-gray-700"
                      >Nieuw wachtwoord bevestigen</label
                    >
                    <div class="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="password"
                        name="userPasswordCheck"
                        id="userPasswordCheck"
                        v-model="formData.userPasswordCheck"
                        required
                        class="block w-full pr-10 border rounded-md shadow-sm appearance-none sm:text-sm focus:outline-none"
                        :class="
                          formError
                            ? 'text-red-900 placeholder-red-300 border-red-300 focus:ring-red-500 focus:border-red-500'
                            : 'placeholder-gray-400 border-gray-300 focus:ring-primary focus:border-primary'
                        "
                        placeholder=""
                        :aria-invalid="formError"
                        aria-describedby="userPasswordCheck-error"
                      />
                      <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                        v-if="formError"
                      >
                        <svg
                          class="w-5 h-5 text-red-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="formError" class="p-4 rounded-md bg-red-50">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        class="w-5 h-5 text-red-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <h3 class="mb-0 text-sm font-medium text-red-800">
                        {{ formError }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 text-right bg-gray-50 sm:px-6">
                <div class="flex justify-end space-x-3">
                  <button
                    type="button"
                    @click="router.back()"
                    class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Annuleren
                  </button>
                  <Button type="submit" :loading="loading">
                    <template #text>Opslaan</template>
                    <template #loading-text>Verwerken...</template>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { doc, getDoc } from 'firebase/firestore';
  import { db, functions } from '@/firebase/firebase.js';
  import { getAuth } from 'firebase/auth';
  import { httpsCallable } from 'firebase/functions';
  import BreadCrumbs from '../../components/BreadCrumbs.vue';
  import Button from '../../components/Button.vue';

  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  const formError = ref('');
  const user = ref('');
  const userRef = doc(db, 'users', getUserID());
  const userID = ref(getUserID());

  var formData = ref({
    userPassword: '',
    userPasswordCheck: '',
  });

  getUser();

  async function getUser() {
    const userSnap = await getDoc(userRef);

    userSnap.exists ? (user.value = userSnap.data()) : null;
  }

  function getUserID() {
    return route.params.id;
  }

  function submit() {
    if (formData.value.userPassword === formData.value.userPasswordCheck) {
      loading.value = true;

      const auth = getAuth();
      var addMessage = httpsCallable(functions, 'updateUser');

      var data = {
        userID: userID.value,
        userPassword: formData.value.userPassword,
      };

      addMessage(data)
        .then((result) => {
          if (result.data.success) {
            router.back();
          }
        })
        .catch((error) => {
          console.log(error);
          switch (error.message) {
            case 'auth/invalid-password':
              formError.value =
                'De opgegeven waarde voor uw wachtwoord is ongeldig. Het moet een tekenreeks zijn met ten minste zes tekens.';
              break;
            case 'permission-denied':
              formError.value =
                'U bent niet gemachtigd om de opgegeven bewerking uit te voeren.';
              break;
            case 'auth/insufficient-permission':
              formError.value =
                'U bent niet gemachtigd om de opgegeven bewerking uit te voeren.';
              break;
            default:
              formError.value =
                'Er is een onbekende fout opgetreden tijdens het veranderen van dit wachtwoord.';
          }
        })
        .finally(() => {
          loading.value = false;
        });
    } else {
      formError.value = 'De opgegeven wachtwoorden komen niet overeen.';
    }
  }
</script>
