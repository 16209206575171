<template>
  <div class="relative p-3 border border-gray-300 border-dashed">
    <div class="absolute z-20 left-6 top-6" v-if="!loading && !isError">
      <button
        @click="toggleTorch(null)"
        :disabled="torchNotSupported"
        class="relative p-3 rounded-md bg-primary"
      >
        <div
          class="w-5 h-0.5 bg-red-500 transform rotate-45 origin-center absolute left-0 top-0 z-10 translate-y-[22px] translate-x-[12px]"
          v-if="torchNotSupported"
        ></div>
        <LightningBoltIcon
          class="flex-shrink-0 w-5 h-5"
          :class="torchNotSupported ? 'text-red-500' : 'text-black'"
        />
      </button>
    </div>
    <div class="absolute z-20 right-6 top-6" v-if="!loading && !isError">
      <select
        name="cameras"
        id="cameras"
        v-model="selectedCamera"
        @change="switchCamera"
        class="text-base"
      >
        <option v-for="value of cameraOptions" :value="value">
          {{ value.label }}
        </option>
      </select>
    </div>
    <div id="qr-code-full-region"></div>
    <!-- <div v-if="isError && errorMessage">
      {{ errorMessage }}
    </div> -->
    <div class="p-4 rounded-md bg-yellow-50" v-if="isError && errorMessage">
      <div class="flex flex-col items-center">
        <div class="flex-shrink-0">
          <!-- Heroicon name: solid/exclamation -->
          <svg
            class="w-5 h-5 text-yellow-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="mt-2 text-sm text-yellow-700">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-center h-full gap-3"
      v-if="loading && !isError"
    >
      <svg
        class="w-5 h-5 mr-3 -ml-1 text-primary animate-spin min-h-[250px]"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <span class="text-gray-500">Laden</span>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits, onMounted, watch } from 'vue';
  import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
  import { LightningBoltIcon } from '@heroicons/vue/outline';

  const loading = ref(true);
  const isError = ref(false);
  const errorMessage = ref();
  const torchNotSupported = ref(true);
  const userToggledTorch = ref(false);
  const cameraOptions = ref([]);
  const selectedCamera = ref();

  const props = defineProps({
    qrbox: {
      type: Number,
      required: false,
      default: () => 250,
    },
    fps: {
      type: Number,
      required: false,
      default: () => 10,
    },
    open: {
      type: Boolean,
      required: true,
    },
  });

  const emit = defineEmits(['result']);

  watch(
    () => props.open,
    (newValue) => {
      if (html5QrCode.isScanning) {
        setTimeout(() => {
          html5QrCode.stop();
          userToggledTorch.value = false;
        }, 200);
      }
    }
  );

  const config = {
    fps: props.fps,
    qrbox: props.qrbox,
  };

  let html5QrCode = null;

  onMounted(() => {
    html5QrCode = new Html5Qrcode('qr-code-full-region', {
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    });

    var retrievedId = JSON.parse(localStorage.getItem('cameraId'));

    Html5Qrcode.getCameras()
      .then((results) => {
        cameraOptions.value = results;
        if (retrievedId) {
          selectedCamera.value = retrievedId;
        } else {
          selectedCamera.value = results[0];
        }

        html5QrCode
          .start(
            retrievedId ? retrievedId.id : { facingMode: 'environment' },
            config,
            onScanSuccess
          )
          .then(() => {
            html5QrCode.applyVideoConstraints({
              focusMode: 'continuous',
            });

            if (isTorchSupported(html5QrCode)) {
              torchNotSupported.value = false;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            loading.value = false;
          });
      })
      .catch((error) => {
        if (error.message === 'Permission denied') {
          isError.value = true;
          errorMessage.value =
            'Het lijkt erop dat de camera permissie niet is ingeschakeld. Om gebruik te kunnen maken van de camera, ga naar de instellingen van je apparaat en geef toestemming aan de desbetreffende app om de camera te gebruiken.';
        }

        console.log(error.message);
      });
  });

  async function toggleTorch() {
    try {
      let constraints = {
        torch: !userToggledTorch.value,
        advanced: [{ torch: !userToggledTorch.value }],
      };

      await html5QrCode.applyVideoConstraints(constraints);

      userToggledTorch.value = !userToggledTorch.value;

      let settings = html5QrCode.getRunningTrackSettings();

      if (settings.torch === true) {
        // Torch was indeed enabled, succeess.
      } else {
        // Failure.
        // Failed to set torch, why?
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function switchCamera() {
    localStorage.setItem('cameraId', JSON.stringify(selectedCamera.value));
    await html5QrCode.stop();
    html5QrCode
      .start(selectedCamera.value.id, config, onScanSuccess)
      .then(() => {
        html5QrCode.applyVideoConstraints({
          focusMode: 'continuous',
        });
        loading.value = false;

        if (isTorchSupported(html5QrCode)) {
          torchNotSupported.value = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function isTorchSupported(html5Qrcode) {
    let settings = html5Qrcode.getRunningTrackSettings();
    return 'torch' in settings;
  }

  function onScanSuccess(decodedText) {
    emit('result', decodedText);
  }
</script>
