export const MarkerStatuses = [
  {
    id: 0,
    name: 'Nieuwe spot',
    color: 'bg-gray-400',
    markerIconGapNieuweSpot: 'marker-new-spot-gap.png',
    markerIconSteelConstructionNieuweSpot: 'marker-new-spot-steel.png',
    markerIconCarpentryNieuweSpot: 'marker-new-spot-carpentry.png',
    markerIconAcousticallyAirtightNieuweSpot: 'marker-new-spot-acoustically-airtight.png'
  },
  {
    id: 1,
    name: 'In behandeling',
    color: 'bg-yellow-400',
    markerIconGapInBehandeling: 'marker-in-progress-gap.png',
    markerIconSteelConstructionInBehandeling: 'marker-in-progress-steel.png',
    markerIconCarpentryInBehandeling: 'marker-in-progress-carpentry.png',
    markerIconAcousticallyAirtightInBehandeling: 'marker-in-progress-acoustically-airtight.png'
  },
  {
    id: 2,
    name: 'Gereed',
    color: 'bg-green-400',
    markerIconGapGereed: 'marker-done-gap.png',
    markerIconSteelConstructionGereed: 'marker-done-steel.png',
    markerIconCarpentryGereed: 'marker-done-carpentry.png',
    markerIconAcousticallyAirtightGereed: 'marker-done-acoustically-airtight.png'
  },
  {
    id: 3,
    name: 'Opname',
    color: 'bg-blue-400',
    markerIconGapOpname: 'marker-intake-gap.png',
    markerIconSteelConstructionOpname: 'marker-intake-steel.png',
    markerIconCarpentryOpname: 'marker-intake-carpentry.png',
    markerIconAcousticallyAirtightOpname: 'marker-intake-acoustically-airtight.png'
  },
  {
    id: 4,
    name: 'Nacontrole',
    color: 'bg-red-400',
    markerIconGapNacontrole: 'marker-checkup-gap.png',
    markerIconSteelConstructionNacontrole: 'marker-checkup-steel.png',
    markerIconCarpentryNacontrole: 'marker-checkup-carpentry.png',
    markerIconAcousticallyAirtightNacontrole: 'marker-checkup-acoustically-airtight.png'
  },
  {
    id: 5,
    name: 'Verplaatst',
    color: 'bg-purple-400',
    markerIconGapVerplaatst: 'marker-moved-gap.png',
    markerIconSteelConstructionVerplaatst: 'marker-moved-steel.png',
    markerIconCarpentryVerplaatst: 'marker-moved-carpentry.png',
    markerIconAcousticallyAirtightVerplaatst: 'marker-moved-acoustically-airtight.png'
  },
  {
    id: 6,
    name: 'Gedupliceerd',
    color: 'bg-purple-400',
    markerIconGapGedupliceerd: 'marker-moved-gap.png',
    markerIconSteelConstructionGedupliceerd: 'marker-moved-steel.png',
    markerIconCarpentryGedupliceerd: 'marker-moved-carpentry.png',
    markerIconAcousticallyAirtightGedupliceerd: 'marker-moved-acoustically-airtight.png'
  }
];
